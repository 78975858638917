import React from "react";
import { useLocation } from "react-router-dom";

import Auth from "./../Auth";
import LogIn from "../Auth/Login";
import ForgetPassword from "../Auth/ForgetPassword";
import TokenForgetPassword from "../Auth/TokenForgetPassword";

import store from "./../../assets/store.png";
import apple from "./../../assets/apple.png";
import logo from "./../../assets/2024LogoCleanAppLtd.svg";
import "./style.css";

const HomePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stage = queryParams.get("form") || "signup";
  return (
    <div className="hmpg  ">
      <div className="container-fluid py-5" style={{ minHeight: "100vh" }}>
        <div className="row d-flex align-items-center justify-content-center homePage_logo_section_main">
          <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center homePage_logo_section ">
            <div>
              <div className="d-flex justify-content-center">
                <div className="logo_img d-flex justify-content-center ">
                  <div>
                    <img className="logo_img-adj" src={logo} alt="img" />
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <p className="py-4">
                  <b className="get_app_hp">
                    Get Your Daily Cleaning Services In An Easy And Cost
                    Efficient Way
                  </b>
                </p>
                {/* <div className="d-flex justify-content-center">
                  <button className="btn_link">
                    <div className="d-flex">
                      <div>
                        <img className="apple_image" src={apple} alt="img" />
                      </div>
                      <div>
                        <span className="btn_download_appStore">
                          Download on the
                          <br />
                          <b>App Store</b>
                        </span>
                      </div>
                    </div>
                  </button>
                  <button className="btn_link">
                    <div className="d-flex">
                      <div>
                        <img
                          className="apple_image mx-2"
                          src={store}
                          alt="img"
                        />
                      </div>
                      <div>
                        <span className="btn_download_appStore">
                          Get it on
                          <br />
                          <b>Google Play</b>
                        </span>
                      </div>
                    </div>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className=" col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center align-items-center text-center">
            {stage === "signup" && <Auth />}
            {stage === "login" && <LogIn />}
            {stage === "forgetpassword" && <ForgetPassword />}
            {stage === "forget-password-token" && <TokenForgetPassword />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
