import React, { useState } from "react";
import cleaner from "../../../../assets/icons/Path-161.png";
import locationImg from "../../../../assets/icons/Path-181.png";
import { useNavigate } from "react-router-dom";
import { cleaningProductsRoute } from "../../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLocation,
  selectTotalCleaners,
  setTotalCleaners,
} from "../createBookingSlice";
import "./style.css";
import { errorToast } from "../../../../toastNotification/toast";

const SelectCleaners = ({ setCurrentBookingStage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const [currentTotalCleaners, setCurrentTotalCleaners] = useState(
    useSelector(selectTotalCleaners)
  );
  const handleTotalCleaners = async () => {
    if (!currentTotalCleaners || currentTotalCleaners === 0) {
      errorToast("Please Select a cleaner!");
      return;
    }
    navigate(cleaningProductsRoute);
    dispatch(setTotalCleaners(currentTotalCleaners));
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9    additional  ">
      <span className="location">
        <img className="Icon_location" src={locationImg} alt="here is an img" />
        &nbsp;
        {location.address_1}
      </span>
      <div
        className="select-cleaners-card "
        style={{ backgroundColor: "white" }}
      >
        <div className="container-fluid ">
          <div className=" ">
            <div className="row">
              <div className="col-12 mb-3 enter_address_heading">
                <h3 className="enter_address heading_style  fs-5">
                  How many cleaning specialists do you want?
                </h3>
              </div>

              <div className="">
                <div className="row d-flex justify-content-start select_user_box ">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center ">
                    <div className="radio radio_cleaners_input py-1 ">
                      <input
                        className="radio_button"
                        type="radio"
                        name="totalCleaners"
                        id="option1"
                        value="1"
                        checked={currentTotalCleaners === "1"}
                        onChange={() => setCurrentTotalCleaners("1")}
                      />
                      <label htmlFor="option1">
                        <img
                          className="icon_cleaner mx-1"
                          src={cleaner}
                          alt="here is am img"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center ">
                    <div className="radio radio_cleaners_input py-1">
                      <input
                        className="radio_button"
                        type="radio"
                        name="totalCleaners"
                        id="option2"
                        value="2"
                        checked={currentTotalCleaners === "2"}
                        onChange={() => setCurrentTotalCleaners("2")}
                      />
                      <label htmlFor="option2">
                        <img
                          className="icon_cleaner mx-1"
                          src={cleaner}
                          alt="here is am img"
                        />
                        <img
                          className="icon_cleaner mx-1"
                          src={cleaner}
                          alt="here is an img"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="row d-flex justify-content-start ">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center   ">
                    <div className="radio radio_cleaners_input py-1">
                      <input
                        type="radio"
                        name="totalCleaners"
                        id="option3"
                        value="3"
                        checked={currentTotalCleaners === "3"}
                        onChange={() => setCurrentTotalCleaners("3")}
                      />
                      <label htmlFor="option3">
                        <img
                          className="icon_cleaner mx-1"
                          src={cleaner}
                          alt="here is am img"
                        />
                        <img
                          className="icon_cleaner "
                          src={cleaner}
                          alt="here is am img"
                        />
                        <img
                          className="icon_cleaner mx-1"
                          src={cleaner}
                          alt="here is am img"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center">
                    <div className="radio radio_cleaners_input py-1 ">
                      <input
                        type="radio"
                        name="totalCleaners"
                        id="option4"
                        value="4"
                        checked={currentTotalCleaners === "4"}
                        onChange={() => setCurrentTotalCleaners("4")}
                      />
                      <label htmlFor="option4">
                        <img
                          className="icon_cleaner mx-1"
                          src={cleaner}
                          alt="here is am img"
                        />
                        <img
                          className="icon_cleaner "
                          src={cleaner}
                          alt="here is am img"
                        />
                        <img
                          className="icon_cleaner mx-1"
                          src={cleaner}
                          alt="here is am img"
                        />
                        <img
                          className="icon_cleaner "
                          src={cleaner}
                          alt="here is am img"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-5 d-flex justify-content-center">
                <div className="col-xs-0 col-sm-0 col-md-0 col-lg-8 col-xl-8"></div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4  login_btn_next_1 ">
                  <button
                    className="login_btn_next px-4  mt-5 mb-3"
                    onClick={handleTotalCleaners}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCleaners;
