import React, { useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  useGetUserBookingQuery,
  useLazyDeleteBookingQuery,
} from "../bookingApiSlice";
import { formatDate, formatTime } from "../../../constants/utils";
import { errorToast, successToast } from "../../../toastNotification/toast";
import RecurringDialog from "../../../components/RecurringDialog";
import { bookingOptionsRoute } from "../../../constants/constants";

import userImage from "../../../assets/profile_user.png";
import location from "../../../assets/icons/Path-181.png";
import calendar from "../../../assets/icons/calendar.png";
import clock from "../../../assets/icons/clock.png";
import "./style.css";

const BookingSuccess = () => {
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const { data, isLoading, refetch } = useGetUserBookingQuery({
    jobStatus: "pending",
    role: "customer",
  });
  const [deleteBooking] = useLazyDeleteBookingQuery();
  const handleDeleteBookingClick = async (bookingId) => {
    try {
      const response = await deleteBooking(bookingId).unwrap();
      successToast("Booking has been Cancelled!");
      refetch();
    } catch (error) {
      errorToast(error.message);
    }
  };
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="box_main_booking_success my-4">
      <div className="container-fluid d-flex justify-content-center ">
        <div className="main_booking_success">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div className="d-flex justify-content-end ">
              <Link
                className="btn_unpaid_booking_success mt-4 mb-3"
                to={bookingOptionsRoute}
              >
                Unpaid Bookings
              </Link>
            </div>

            <div
              className="your_services_main_summary_box  "
              ref={todayBookingsRef}
              onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
              onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              {isLoading ? (
                <div className="container-fluid">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="col-12 d-flex justify-content-center align-items-center"
                      style={{ height: "100vh" }}
                    >
                      <Spinner />
                    </div>
                  </div>
                </div>
              ) : data && data.length > 0 ? (
                data.map((booking) => (
                  <BookingCard
                    booking={booking}
                    key={booking._id}
                    onHandleDeleteBooking={handleDeleteBookingClick}
                  />
                ))
              ) : (
                <p>No bookings available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSuccess;

const BookingCard = ({ booking, onHandleDeleteBooking }) => {
  return (
    <div className="container summary_box_booking_success mt-4 ">
      <div className="row d-flex">
        <div className=" col-12 col-md-3 col-lg-2 img_box ">
          <img
            className="profile_img_success"
            src={
              booking.cleaner?.image?.url
                ? booking.cleaner.image.url
                : userImage
            }
            alt="here is am img"
          />
        </div>

        <div className="col-12 col-md-8 col-lg-3  cleaner_name_service  ">
          <div>
            <div className="text_ttile">
              <span className="cleaner_name">{booking.cleaner.name}</span>
            </div>

            <div className="text_ttile">
              <span className="cleaning_title">
                <b>{booking.service.title}</b>
              </span>
            </div>
            <br />
          </div>
        </div>

        <div className=" col-12 col-md-12 col-lg-5 d-flex justify-content-center details_locations_bsuccess ">
          <div>
            <div className="py-2 location_calendar_box">
              <span className="location_detail04">
                <img
                  className="location_icon"
                  src={location}
                  alt="here is location-img"
                />{" "}
                &nbsp; {booking.location.address_1}
              </span>
              <br />
            </div>
            <span className="location_detail04">
              <img
                className="location_icon_1"
                src={calendar}
                alt="here is location-img"
              />{" "}
              &nbsp; {formatDate(booking.startTime)}
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <img
                className="location_icon_1"
                src={clock}
                alt="here is location-img"
              />{" "}
              &nbsp; {formatTime(booking.startTime)}
            </span>
          </div>
        </div>

        <div className=" col-12  col-md-12 col-lg-2">
          <div className="d-flex justify-content-center img_price_booking_success">
            &#163;{booking.totalPrice}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex">
          <div className="col-0 col-md-0 col-lg-2 col-xl-2"></div>

          <div className="col-12 col-md-12 col-lg-5 col-xl-5  pt-4">
            <div>
              <span>Included tasks</span>
              <div className="d-flex flex-wrap justify-content-between  pt-2 service_icons_main">
                {booking.service.tasks.map((task, i) => (
                  <div className="icon_box icon_box_2 " key={i}>
                    <div className="d-flex justify-content-center">
                      <img
                        className="service_icons"
                        src={task.image.url}
                        alt={task.image.public_id}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p className="service_icons_title">{task.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-3 col-xl-3">
            <div className=" not_needed">
              <p className="product_not_needed_booking_success ">
                {booking.cleaningProducts
                  ? "Cleaning Products are Included"
                  : "Cleaning Products are not needed"}
              </p>
            </div>
            {booking.recurring?.isRecurring && (
              <div className=" d-flex justify-content-center">
                <RecurringDialog recurring={booking.recurring} />
              </div>
            )}
          </div>

          <div className="col-12 col-md-12 col-lg-2 col-xl-2 pt-4">
            <div className="d-flex justify-content-center">
              <div>
                <div className="d-flex justify-content-center working_hours">
                  <p>Minimum Hours</p>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="time">{booking.service.hours} Hours</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-center mt-4">
        <div className="col-12 d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            <button
              className="continue_to_payment_booking_success"
              onClick={() => {
                onHandleDeleteBooking(booking._id);
              }}
            >
              Cancel Booking
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
