import React from "react";
import { Spinner } from "react-bootstrap";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  useGetBookingQuery,
  useLazyDeleteBookingQuery,
  useLazyGetCheckoutSessionQuery,
} from "../bookingApiSlice";
import { errorToast, successToast } from "../../../toastNotification/toast";
import { formatDate, formatTime } from "../../../constants/utils";
import { homeRoute } from "../../../constants/constants";
import RecurringDialog from "../../../components/RecurringDialog";

import location from "../../../assets/icons/Path-181.png";
import calendar from "../../../assets/icons/calendar.png";
import clock from "../../../assets/icons/clock.png";
import arrow from "../../../assets/icons/arrow.png";
import userImage from "../../../assets/profile_user.png";
import "./style.css";

const BookingSummary = () => {
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const { data, refetch, isLoading } = useGetBookingQuery(bookingId);

  const [getCheckoutSession] = useLazyGetCheckoutSessionQuery();
  const [deleteBooking] = useLazyDeleteBookingQuery();

  const handleDeleteBookingClick = async (bookingId) => {
    try {
      const response = await deleteBooking(bookingId).unwrap();
      successToast("A Booking has been Cancelled!");
      navigate(homeRoute);
    } catch (error) {
      errorToast(error.message);
    }
  };
  const handleBookingPaymentClick = async (bookingId) => {
    try {
      const response = await getCheckoutSession(bookingId);
      window.location.href = response.data;
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div>
      <div className="container-fluid d-flex justify-content-center ">
        <div className="main_summary_box my-4">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="your_services_main_booking_summary ">
              <div className="row d-flex justify-content-center ">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <h2 className="mx-4 my-4   heading_booking_summary">
                    <b>Booking Summary</b>
                  </h2>
                </div>
              </div>
              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
              ) : data?.booking ? (
                <Bookings
                  booking={data.booking}
                  onHandleBookingPayment={handleBookingPaymentClick}
                  onHandleDeleteBooking={handleDeleteBookingClick}
                />
              ) : (
                <p>No bookings found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSummary;

const Bookings = ({
  booking,
  onHandleBookingPayment,
  onHandleDeleteBooking,
}) => {
  return (
    <div>
      <div className="container summary_box01 ">
        <div className="row d-flex">
          <div className=" col-12 col-md-3 col-lg-2 img_box ">
            <div>
              <img
                className="profile_img"
                src={
                  booking.cleaner?.image?.url
                    ? booking.cleaner.image.url
                    : userImage
                }
                alt="here is am img"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-7  cleaner_name_service  ">
            <div>
              <div className="cleaner_name_booking">
                <span className="cleaner_name">{booking.cleaner.name}</span>
              </div>

              <div className="cleaning_title">
                <b>{booking.service.title}</b>
              </div>
            </div>
          </div>
          <div className=" col-12  col-md-3 col-lg-3 d-flex justify-content-center align-items-center price_style">
            <div className="d-flex justify-content-center align-items-center ">
              <span className="booking_price_summary">
                {" "}
                &#163;{booking.totalPrice}{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row d-flex">
            <div className="col-0 col-md-0 col-lg-1 col-xl-1"></div>

            <div className="col-12 col-md-12 col-lg-8 col-xl-8  pt-4">
              <div>
                <span>Included tasks</span>
                <div className="d-flex flex-wrap pt-4 service_icons_main">
                  {booking.service.tasks.map((task, i) => (
                    <div className="icon_box icon_box_2 " key={i}>
                      <div className="d-flex justify-content-start">
                        <img
                          className="service_icons"
                          src={task.image.url}
                          alt={task.image.public_id}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <p className="service_icons_title">
                          {task.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="py-2 location_calendar_box">
                  <span className="location_detail">
                    <img
                      className="location_icon"
                      src={location}
                      alt="here is location-img"
                    />{" "}
                    &nbsp;
                    <span className="location_summary">
                      {" "}
                      {booking.location.address_1}
                    </span>
                  </span>
                  <br />
                </div>
                <span className="location_detail">
                  <img
                    className="location_icon_1"
                    src={calendar}
                    alt="here is location-img"
                  />{" "}
                  &nbsp;{" "}
                  <span className="date_time_format">
                    {" "}
                    {formatDate(booking.startTime)}{" "}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <img
                    className="location_icon_1"
                    src={clock}
                    alt="here is location-img"
                  />{" "}
                  &nbsp;{" "}
                  <span className="date_time_format">
                    {" "}
                    {formatTime(booking.startTime)}{" "}
                  </span>
                </span>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-3 col-xl-3">
              <div className="d-flex justify-content-center">
                <div>
                  <div className="d-flex justify-content-center working_hours">
                    <p>Minimum Hours</p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="time">{booking.service.hours} Hours</p>
                  </div>
                </div>
              </div>
              <div className=" not_needed">
                <span className="product_not_needed_booking_summary ">
                  {booking.cleaningProducts
                    ? "Cleaning Products are Included"
                    : "Cleaning Products are not needed"}
                </span>
              </div>
              {booking.recurring?.isRecurring && (
                <RecurringDialog recurring={booking.recurring} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row d-flex">
        <div className="col-12 d-flex justify-content-center">
          <div className="btn_payment_cancel_summary">
            <div className="d-flex justify-content-center pt-3 pb-2">
              <button
                className="cancel_button"
                onClick={() => {
                  onHandleDeleteBooking(booking._id);
                }}
              >
                Cancel
              </button>
            </div>
            <div className="d-flex justify-content-center">
              {" "}
              <button
                className="continue_to_payment"
                onClick={() => {
                  onHandleBookingPayment(booking._id);
                }}
              >
                Continue to payment&nbsp; &nbsp;
                <img className="arrow" src={arrow} alt="here is an arrow" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
