import React from "react";
import fb from "../../assets/icons/24.png";
import logo from "../../assets/2024LogoCleanAppLtd.svg";
import insta from "../../assets/icons/Path-37.png";
import twitter from "../../assets/icons/logo.png";
import linkd from "../../assets/icons/Path-39.png";
import "./style.css";

const AboutUs = () => {
  return (
    <div className="services_main d-flex flex-column min-vh-100">
      <div></div>
      <div className="container-fluid about_us flex-grow-1">
        <div className="container">
          <div className="row d-flex">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
              <h1 className="logo_heading">CleanApp Ltd.</h1>
              <div className="social_media_links_box">
                <a
                  href="https://www.facebook.com/cleanapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="social_media_logo" src={fb} alt="img" />
                </a>
                <a
                  href="https://www.instagram.com/cleanapp.ltd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="social_media_logo" src={insta} alt="img" />
                </a>
                <a
                  href="https://twitter.com/cleanappltd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="social_media_logo" src={twitter} alt="img" />
                </a>
                <a
                  href="https://www.linkedin.com/company/cleanapp-ltd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="social_media_logo " src={linkd} alt="img" />
                </a>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  app_logo_box">
              <img className="app_logo" src={logo} alt="here is am img" />
            </div>
          </div>
          <div className="row  description">
            <div className="col-12">
              <p className="description_aboutUs_details">
                We provide instant cleaning services all over London through a
                simple and easy to use booking app. Choose from basic cleaning
                to deep cleaning to daily chores such as ironing and laundry.
                Whatever you need- we’ve got you covered. All our cleaners are
                experienced and are legally registered to work here in the UK.
                We conduct a thorough background check including DBS (criminal
                record). All our cleaners have a minimum of 1 year cleaning
                experience. We are cash free so all payments will be secure
                through the app/ website. </p><p className="pt-4 description_aboutUs_details">  Feel free to contact us 24/7 via
                telephone/ WhatsApp.</p>
              
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default AboutUs;
