import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import SearchBar from "../components/SearchBar";
import { isLogin } from "../constants/utils";
const Layout = () => {
  return (
    <div className="d-flex flex-column min-vh-100 position-relative bg_change">
      {isLogin() ? <SearchBar /> : <NavBar />}
      <Outlet />
    </div>
  );
};

export default Layout;
