import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { authStage } from "../../../../constants/constants";
import {
  useRemoveEmailPhoneMutation,
  useUpdateEmailPhoneMutation,
} from "../emailPhoneApiSlice";
import { useSendOtpMutation } from "../../../Auth/authApiSlice";
import { successToast, errorToast } from "../../../../toastNotification/toast";
import { setUser, selectPhone } from "../../../Auth/authSlice";
import {
  passwordSchema,
  phoneSchema,
  otpSchema,
} from "../../../../validation/SignUpSchema";

import "./style.css";

const RemovePhoneNumber = () => {
  const dispatch = useDispatch();
  const userPhone = useSelector(selectPhone);
  const initialValues = {
    password: "",
    phone: userPhone ? userPhone : "",
    otp: "",
  };

  const [stage, setStage] = useState(
    userPhone ? authStage.password : authStage.contact
  );
  const [removePhone, { isLoading }] = useRemoveEmailPhoneMutation();
  const [updatePhone, { isLoading: isLoadingPhone }] =
    useUpdateEmailPhoneMutation();
  const [sendOtp, { isLoading: isLoadingSendOtp }] = useSendOtpMutation();
  const handleUpdatePhone = async () => {
    setStage(authStage.contact);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      let response;
      switch (stage) {
        case authStage.password:
          response = await removePhone({
            toRemove: "phone",
            password: values.password,
          }).unwrap();
          dispatch(setUser(response.user));
          setStage(authStage.contact);
          successToast("Phone number has been successfully removed!");
          resetForm();
          break;

        case authStage.contact:
          response = await sendOtp({
            phone: values.phone,
          }).unwrap();

          successToast(response.message);
          setStage(authStage.otp);
          break;

        case authStage.otp:
          response = await updatePhone({
            phone: values.phone,
            otp: values.otp,
          }).unwrap();
          successToast("Phone Number Successfully Changed!");
          setStage(authStage.contact);
          break;

        default:
          break;
      }
      if (response.user) {
        dispatch(setUser(response.user));
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <div>
              <div>
                <p className="remove_heading">
                  <b className="remove_number_heading">
                    {stage === authStage.password
                      ? "Remove Phone Number"
                      : "New Phone Number"}
                  </b>
                </p>
              </div>
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={
                  stage === authStage.password
                    ? passwordSchema
                    : stage === authStage.contact
                    ? phoneSchema
                    : stage === authStage.otp
                    ? otpSchema
                    : undefined
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {stage === authStage.password && (
                      <div className=" mt-3 d-flex justify-content-center">
                        <Form.Group
                          controlId="formBasicPassword"
                          className="mb-5 signup_label_input"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            className="remove_email"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.password && !!errors.password}
                            disabled={isLoading}
                          />
                          {touched.password && errors.password && (
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                    )}
                    {stage === authStage.contact && (
                      <div className=" mt-3 d-flex justify-content-center">
                        <Form.Group controlId="formBasicPhone">
                          <Form.Label>Enter Phone Number <span className="prefix_number_settings">(E.g. +447123123123)</span></Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            value={values.phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.phone && !!errors.phone}
                            disabled={isLoading.isLoadingSendOtp}
                            className="remove_email"
                          />
                          {touched.phone && errors.phone && (
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                    )}

                    {stage === authStage.otp && (
                      <div className=" mt-3 d-flex justify-content-center">
                        <Form.Group controlId="formBasicOTP">
                          <Form.Label>Enter OTP sent to your number</Form.Label>
                          <Form.Control
                            type="text"
                            name="otp"
                            minLength={6}
                            value={values.otp}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.otp && !!errors.otp}
                            disabled={isLoading}
                            className="remove_email"
                          />
                          {touched.otp && errors.otp && (
                            <Form.Control.Feedback type="invalid">
                              {errors.otp}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                    )}
                    {stage === authStage.password && (
                      <div className=" mt-5  d-flex justify-content-center">
                        <p className="change_email" onClick={handleUpdatePhone}>
                          Change Phone Number Instead
                        </p>
                      </div>
                    )}
                    <div className=" btn_confirm_div d-flex justify-content-center">
                      <button
                        className="btn_confirm"
                        type="submit"
                        disabled={isLoading.isLoadingSendOtp}
                      >
                        {stage === authStage.password
                          ? "Confirm"
                          : stage === authStage.contact
                          ? "Send OTP"
                          : stage === authStage.otp
                          ? "Verify"
                          : ""}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemovePhoneNumber;
