import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import {
  useGetBookingSummaryQuery,
  useUpdateBookingMutation,
} from "../bookingApiSlice";
import { formatDate, formatTime } from "../../../constants/utils";
import { errorToast } from "../../../toastNotification/toast";
import { bookingSummaryRoute } from "../../../constants/constants";
import OptionSelect from "../../../components/OptionsSelect";

import arrow from "../../../assets/icons/arrow.png";
import userImage from "../../../assets/profile_user.png";
import location from "../../../assets/icons/Path-181.png";
import calendar from "../../../assets/icons/calendar.png";
import clock from "../../../assets/icons/clock.png";
import "./style.css";

const BookingOptions = () => {
  const activityRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const navigate = useNavigate();
  const { data, isLoading } = useGetBookingSummaryQuery();
  const [recurringOption, setRecurringOption] = useState("once");
  const [updateBooking, { isLoading: isLoadingBooking }] =
    useUpdateBookingMutation();
  const [recurring, setRecurring] = useState({
    isRecurring: false,
  });
  const handleContinueClick = async (bookingId) => {
    try {
      if (
        recurringOption === "weekly" &&
        recurring.isRecurring &&
        recurring.weekly.length === 0
      ) {
        errorToast("Please Select Week Days!");
        return;
      } else if (
        recurringOption === "monthly" &&
        recurring.isRecurring &&
        recurring.monthly.length === 0
      ) {
        errorToast("Please Select Month Dates!");
        return;
      }
      const response = await updateBooking({
        bookingId,
        body: { recurring },
      }).unwrap();
      if (response.status === "success") {
        navigate(`${bookingSummaryRoute}/${bookingId}`);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div>
      <div>
        <div className=" container-fluid main_boking_options my-3 py-3 px-4"        
      ref={activityRef}
      onMouseDown={(e) => handleMouseDown(e, activityRef)}
      onMouseMove={(e) => handleMouseMove(e, activityRef)}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      
        >
          {isLoading ? (
            <div className="container-fluid">
        <div className="row d-flex justify-content-center">
        <div className="col-12 d-flex justify-content-center align-items-center"
        style = {{height : "100vh"}}
        >
        <Spinner />
        </div>
        </div>
        </div>
          ) : data && data.length > 0 ? (
            data.map((booking) => (
              <div className="row section_main_booking_option d-flex mt-4 " key={booking._id}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 booking_upcoming_section_1 ">
                  <div>
                    <div className="px-2">
                      <div className="container-fluid  mt-4">
                        <div className="row d-flex px-2 pt-2">
                          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 d-flex justify-content-center align-items-center">
                            <img
                              className="display_profile01"
                              src={
                                booking.cleaner?.image?.url
                                  ? booking.cleaner?.image.url
                                  : userImage
                              }
                              alt="here is an img"
                            />
                          </div>
                          <div className=" for_testing col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 d-flex justuify-content-start align-items-center ">
                            <div>
                              <h5 className="card_title01">
                                <b> {booking.cleaner.name} </b>
                              </h5>
                              <p
                                className="card_title01"
                                style={{ color: "#A080E1" }}
                              >
                                {booking.service.title}
                              </p>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 d-flex justify-content-center align-items-center ">
                            <div className=" ">
                              <p className="price_tag01">
                                <b>
                                &pound;
                                  {booking.totalPrice}
                                 
                                </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="container-fluid">
                          <div className="row d-flex mt-4">
                            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                              <p className="tasks_heading01">Included tasks</p>
                              <div className="d-flex justify-content-start pt-2">
                                {booking.service.tasks.map((task, i) => (
                                  <div key={i}>
                                    <div className="d-flex justify-content-center">
                                      <img
                                        className="service_images01"
                                        src={task.image.url}
                                        alt={task.image.public_id}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      <p className="icon_title01">
                                        {task.description}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 hours_display_responsive d-flex justify-content-end">
                              <div>
                                <p className="min_hours01">Minimum hours</p>
                                <div className="d-flex justify-content-center">
                                  <p className="hours_detail01">
                                    {booking.service.hours} Hours
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center">
                          <p className="cleaning_ptoducts_need">
                            {booking.cleaningProducts
                              ? "Cleaning Products are Included"
                              : "Cleaning Products are not needed"}
                          </p>
                        </div>

                        <div className="row d-flex justify-content-center">
                          <div className="d-flex justify-content-center">
                            <span className="location_detail">
                              <img
                                className="location_icon"
                                src={location}
                                alt="here is location-img"
                              />&nbsp;
                              <span className="anything02">
                                {booking.location.address_1}
                              </span>
                            </span>
                          </div>
                        </div>

                        <div className="row d-flex justify-content-center date_time_display">
                          <div className="d-flex justify-content-center">
                            <span className="location_detail d-flex justify-content-between">
                              <div>
                                <img
                                  className="location_icon_1 "
                                  src={clock}
                                  alt="here is location-img"
                                />
                                <span className=" time_booking ">
                                  {formatTime(booking.startTime)} 
                                  {formatTime(booking.endTime)}
                                </span>
                              </div>

                              <div>
                                <img
                                  className="location_icon_2"
                                  src={calendar}
                                  alt="here is location-img"
                                />
                                <span className="time_booking">
                                  {formatDate(booking.startTime)}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 ">
                  <div className="section_sub_main03">
                    <OptionSelect
                      setRecurring={setRecurring}
                      setRecurringOption={setRecurringOption}
                    />
                  </div>

                  <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center pt-4">
                    <button
                      className="continue_to_payment01"
                      onClick={() => {
                        handleContinueClick(booking._id);
                      }}
                    >
                      Continue&nbsp; &nbsp;{" "}
                      <img
                        className="arrow"
                        src={arrow}
                        alt="here is an arrow"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No bookings available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingOptions;
