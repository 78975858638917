import React from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { confirmPasswordSchema } from "../../validation/SignUpSchema";
import { useResetPasswordMutation } from "./authApiSlice";
import { landingPageRoute } from "../../constants/constants";
import { errorToast, successToast } from "../../toastNotification/toast";

const ForgetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get("resettoken");
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const initialValues = {
    password: "",
    confirmPassword: "",
    token: resetToken,
  };
  const handleSubmit = async (values, actions) => {
    try {
      const response = await resetPassword({
        token: resetToken,
        password: values.password,
      }).unwrap();
      if (response.status === "success") {
        actions.resetForm();
        successToast("Password Reset Successfully!");
        navigate(`${landingPageRoute}?form=login`);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div>
      <h3 className="register_heading text-center mt-3">
        <span className="reset_password_heading">Reset Password</span>
      </h3>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={confirmPasswordSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className=" signup_label_input">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="Password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                disabled={isLoading}
                onChange={handleChange}
                isInvalid={touched.password && !!errors.password}
                className="input_signup mt-2"
              />
              {touched.password && errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              className="mb-5 signup_label_input"
            >
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                disabled={isLoading}
                className="input_signup mt-2"
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button
                className="signup_btn_next px-4 mt-4 mb-5"
                type="submit"
                disabled={isLoading}
              >
                Reset Password
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgetPassword;
