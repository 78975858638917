import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { landingPageRoute } from "../../constants/constants";
import { setLogout } from "../Auth/authSlice";
import "./style.css";

const PendingAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(setLogout());
    navigate(landingPageRoute);
  };
  return (
    <div>
      {" "}
      <div className="d-flex flex-column justify-content-center align-items-center error_box_main ">
        <h1 className="oops">Account Pending!</h1>
        <p></p>
        <p className="error_content main_content_error">
          Your Account is Pending. Our team has been alerted, and they're on it.
          Thank You!
        </p>
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default PendingAccount;
