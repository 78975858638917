import React from "react";
import { Spinner } from "react-bootstrap";

import { useGetAllServicesQuery } from "./servicesApiSlice";
import ServiceCard from "../../../components/ServiceCard";

import "./style.css";

const OurServices = () => {
  const { data, isLoading } = useGetAllServicesQuery();

  return (
    <div className="services_main">
      <h1 className="text-center my-3">
        <b className="our_services_heading">Our Services</b>
      </h1>
      <div className="container mb-5">
        <div className="row d-flex justify-content-center">
          {isLoading ? (
            <div className="row d-flex justify-content-center gap-4">
              <div className="col-12 d-flex justify-content-center">
                <Spinner />
              </div>
            </div>
          ) : data ? (
            data.map((service) => (
              <div
                className="col-xs-12 col-sm-6 col-md-4 col-lg-3 my-5"
                key={service._id}
              >
                <ServiceCard service={service} showButton={true} />
              </div>
            ))
          ) : (
            <p>No services to show!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
