import { createBrowserRouter } from "react-router-dom";
import UserLayout from "./layouts/UserLayout";
import LoginRequired from "./layouts/LoginRequired";
import Layout from "./layouts/Layout";
import RestrictLayout from "./layouts/RestrictLayout";
import DefaultSetting from "./layouts/DefaultSetting";
import CreateBookingLayout from "./layouts/CreateBookingLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import CleanerLayout from "./layouts/CleanerLayout";
import SideBar from "./components/SideBar";
import SearchBarLayout from "./layouts/SearchBarLayout";
import CustomerLayout from "./layouts/CustomerLayout";
import ErrorLayout from "./layouts/ErrorLayout.jsx";
import AdminLayout from "./layouts/AdminLayout";
import SettingsLayout from "./layouts/SettingsLayout";
import ApprovalLayout from "./layouts/approvalLayout";

import HomePage from "./pages/HomePage";
import OurServices from "./pages/Services/OurServices";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth";
import RemoveEmail from "./pages/Setting/EmailPhoneActions/RemoveEmail";
import ResetPassword from "./pages/Setting/ResetPassword";
import RemovePhoneNumber from "./pages/Setting/EmailPhoneActions/RemovePhoneNumber";
import DeleteAccount from "./pages/Setting/DeleteAccount";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import BookingSummary from "./pages/Booking/BookingSummary";
import CreateBooking from "./pages/Booking/CreateBooking";
import FindCleaners from "./pages/Booking/CreateBooking/FindCleaners";
import YourProfile from "./pages/YourProfile";
import AllowLocation from "./pages/Booking/CreateBooking/AllowLocation";
import Address from "./pages/Booking/CreateBooking/Address";
import SelectCleaners from "./pages/Booking/CreateBooking/SelectCleaners";
import CleaningProduct from "./pages/Booking/CreateBooking/CleaningProduct";
import UpcomingBookings from "./pages/Booking/UpcomingBookings";
import AdminDashboard from "./pages/AdminDashboard/index.jsx";
import AdminActivities from "./pages/AdminActivities/index.jsx";
import BookingSuccess from "./pages/Booking/BookingSuccess";
import OurServicesMain from "./pages/Services/OurServicesMain";
import BookingOptions from "./pages/Booking/BookingOptions";
import Notifications from "./pages/Notification";
import CleanerHomeBookings from "./pages/CleanerHomeBookings";
import PastBookings from "./pages/PastBookings";
import AdminCleaners from "./pages/adminCleaner/index.jsx";
import NewPassword from "./pages/Setting/ResetPassword/NewPassword";
import AdminNotifications from "./pages/AdminNotifications/index.jsx";
import AdminManageCleaners from "./pages/AdminManageCleaners/index.jsx";
import AdminManageServices from "./pages/AdminManageServices/index.jsx";
import AdminAppointCleaner from "./pages/AdminAppointCleaner/index.jsx";
import CustomerNotification from "./pages/CustomerNotification";
import AdminLogin from "./pages/Auth/AdminLogin";
import PendingAccount from "./pages/PendingAccount";

import {
  loginRoute,
  landingPageRoute,
  signUpRoute,
  servicesRoute,
  createBookingRoute,
  myBookingsRoute,
  aboutUsRoute,
  contactUsRoute,
  profileRoute,
  resetPasswordRoute,
  removeEmailRoute,
  removePhoneRoute,
  deleteAccountRoute,
  enterAddressRoute,
  selectCleanersRoute,
  cleaningProductsRoute,
  findCleanersRoute,
  bookingSummaryRoute,
  homeRoute,
  adminManageServicesRoute,
  bookingOptionsRoute,
  homeCleanerRoute,
  notificationRoute,
  upComingBookingRoute,
  pastBookingsRoute,
  adminDashboardRoute,
  adminActivitiesRoute,
  addNewCleanersRoute,
  adminNotificationsRoute,
  adminManageCleanersRoute,
  adminAppointCleanerRoute,
  customerNotificationRoute,
  NewPasswordRoute,
  adminLoginRoute,
  pendingAccountRoute,
} from "./constants/constants";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        element: <UserLayout />,
        children: [
          {
            element: <RestrictLayout />,
            children: [
              {
                path: loginRoute,
                element: <Login />,
              },

              {
                path: signUpRoute,
                element: <Signup />,
              },
              {
                path: landingPageRoute,
                element: <HomePage />,
              },
              {
                path: servicesRoute,
                element: <OurServices />,
              },
              {
                path: adminLoginRoute,
                element: <AdminLogin />,
              },
            ],
          },

          {
            path: aboutUsRoute,
            element: <AboutUs />,
          },
          {
            path: contactUsRoute,
            element: <ContactUs />,
          },
          {
            path: pendingAccountRoute,
            element: <PendingAccount />,
          },
          {
            element: <ApprovalLayout />,
            children: [
              {
                element: <SideBar />,
                children: [
                  {
                    element: <CreateBookingLayout />,
                    children: [
                      {
                        element: <CreateBooking />,
                        children: [
                          {
                            path: createBookingRoute,
                            element: <AllowLocation />,
                          },
                          {
                            path: enterAddressRoute,
                            element: <Address />,
                          },
                          {
                            path: selectCleanersRoute,
                            element: <SelectCleaners />,
                          },
                          {
                            path: cleaningProductsRoute,
                            element: <CleaningProduct />,
                          },
                          {
                            path: findCleanersRoute,
                            element: <FindCleaners />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    errorElement: <ErrorLayout />,
  },
  {
    element: <SearchBarLayout />,
    children: [
      {
        element: <UserLayout />,
        children: [
          {
            element: <LoginRequired />,
            children: [
              {
                element: <SideBar />,
                children: [
                  {
                    element: <SettingsLayout />,
                    children: [
                      {
                        path: profileRoute,
                        element: <YourProfile />,
                      },
                      {
                        element: <DefaultSetting />,
                        children: [
                          {
                            path: resetPasswordRoute,
                            element: <ResetPassword />,
                          },
                          {
                            path: removeEmailRoute,
                            element: <RemoveEmail />,
                          },
                          {
                            path: removePhoneRoute,
                            element: <RemovePhoneNumber />,
                          },
                          {
                            path: deleteAccountRoute,
                            element: <DeleteAccount />,
                          },
                          {
                            path: `${NewPasswordRoute}/:resetToken`,
                            element: <NewPassword />,
                          },
                        ],
                      },
                    ],
                  },

                  {
                    element: <CleanerLayout />,
                    children: [
                      {
                        path: homeCleanerRoute,
                        element: <CleanerHomeBookings />,
                      },

                      {
                        path: `${upComingBookingRoute}/:bookingId?`,
                        element: <UpcomingBookings />,
                      },
                      {
                        path: `${pastBookingsRoute}/:bookingId?`,
                        element: <PastBookings />,
                      },
                      {
                        path: `${notificationRoute}/:notificationId?`,
                        element: <Notifications />,
                      },
                    ],
                  },
                  {
                    element: <CustomerLayout />,
                    children: [
                      {
                        path: homeRoute,
                        element: <OurServicesMain />,
                      },
                      {
                        path: `${bookingSummaryRoute}/:bookingId`,
                        element: <BookingSummary />,
                      },
                      {
                        path: myBookingsRoute,
                        element: <BookingSuccess />,
                      },
                      {
                        path: bookingOptionsRoute,
                        element: <BookingOptions />,
                      },
                      {
                        path: `${customerNotificationRoute}/:notificationId?`,
                        element: <CustomerNotification />,
                      },
                    ],
                  },
                ],
              },
              {
                element: <AdminLayout />,
                children: [
                  {
                    element: <DashboardLayout />,
                    children: [
                      {
                        path: adminDashboardRoute,
                        element: <AdminDashboard />,
                      },
                      {
                        path: adminActivitiesRoute,
                        element: <AdminActivities />,
                      },
                      {
                        path: addNewCleanersRoute,
                        element: <AdminCleaners />,
                      },
                      {
                        path: `${adminNotificationsRoute}/:notificationId?`,
                        element: <AdminNotifications />,
                      },
                      {
                        path: `${adminManageCleanersRoute}/:cleanerId?`,
                        element: <AdminManageCleaners />,
                      },
                      {
                        path: `${adminManageServicesRoute}/:serviceId?`,
                        element: <AdminManageServices />,
                      },
                      {
                        path: `${adminAppointCleanerRoute}/:bookingId?`,
                        element: <AdminAppointCleaner />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
export default router;
