import { createSlice } from "@reduxjs/toolkit";
import { createBookingFields } from "../../../constants/constants";
import { useState } from "react";

const initialState = {
  location: {
    coordinates:
      typeof localStorage.getItem(
        createBookingFields.coordinates
      ) === "string"
        ? localStorage
            .getItem(createBookingFields.coordinates)
            .split(",")
        : localStorage.getItem(
            createBookingFields.coordinates
          ),
    address_1: localStorage.getItem(
      createBookingFields.address_1
    ),
    address_2: localStorage.getItem(
      createBookingFields.address_2
    ),
    postalCode: localStorage.getItem(
      createBookingFields.postalCode
    ),
  },
  cleaningProducts: localStorage.getItem(
    createBookingFields.cleaningProducts
  ),
  totalCleaners: localStorage.getItem(
    createBookingFields.totalCleaners
  ),
};

export const createBookingSlice = createSlice({
  name: "CreateBooking",
  initialState: initialState,
  reducers: {
    setLocation: (state, action) => {
      const newLocation = {
        ...state.location,
        ...action.payload,
      };

      state.location = newLocation;
      localStorage.setItem(
        createBookingFields.coordinates,
        newLocation.coordinates
      );
      localStorage.setItem(
        createBookingFields.address_1,
        newLocation.address_1
      );
      localStorage.setItem(
        createBookingFields.address_2,
        newLocation.address_2
      );
      localStorage.setItem(
        createBookingFields.postalCode,
        newLocation.postalCode
      );
    },
    setCleaningProducts: (state, action) => {
      state.cleaningProducts = action.payload;
      localStorage.setItem(
        createBookingFields.cleaningProducts,
        state.cleaningProducts
      );
    },
    setTotalCleaners: (state, action) => {
      state.totalCleaners = action.payload;
      localStorage.setItem(
        createBookingFields.totalCleaners,
        state.totalCleaners
      );
    },
    resetCreateBooking: (state) => {
      localStorage.removeItem(
        createBookingFields.coordinates
      );
      localStorage.removeItem(
        createBookingFields.address_1
      );
      localStorage.removeItem(
        createBookingFields.address_2
      );
      localStorage.removeItem(
        createBookingFields.postalCode
      );
      localStorage.removeItem(
        createBookingFields.totalCleaners
      );
      localStorage.removeItem(
        createBookingFields.cleaningProducts
      );
      state.location = {
        coordinates: null,
        address_1: null,
        address_2: null,
        postalCode: null,
      };
    },
  },
});

export const {
  setLocation,
  setCleaningProducts,
  setTotalCleaners,
  resetCreateBooking,
} = createBookingSlice.actions;

export const selectLocation = (state) =>
  state.createBooking.location;

export const selectTotalCleaners = (state) =>
  state.createBooking.totalCleaners;
export const selectCleaningProducts = (state) =>
  state.createBooking.cleaningProducts;

export default createBookingSlice.reducer;
