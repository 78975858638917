import { apiSlice } from "../../../state/api/apiSlice";
import { apiRoutes } from "../../../constants/constants";

export const deleteAccountApiSlice =
  apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      deleteAccount: builder.mutation({
        query: (body) => ({
          url: apiRoutes.deleteAccount,
          method: "DELETE",
          body: { ...body },
        }),
        transformResponse: (response) => ({
          status: response.status,
        }),
        transformErrorResponse: (response) => ({
          status: response.data.status,
          message: response.data.message,
        }),
      }),
    }),
  });

export const { useDeleteAccountMutation } =
  deleteAccountApiSlice;
