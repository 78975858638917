import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Container, Row, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";

import { setLogout } from "../../pages/Auth/authSlice";
import { getUserRole, isLogin } from "../../constants/utils";

import {
  homeCleanerRoute,
  homeRoute,
  landingPageRoute,
  myBookingsRoute,
  profileRoute,
  resetPasswordRoute,
  upComingBookingRoute,
} from "../../constants/constants";

import profile from "../../assets/icons/path-01.png";
import home from "../../assets/icons/path-02.png";
import setting from "../../assets/icons/path-04.png";
import appointment from "../../assets/icons/path-03.png";
import logout from "../../assets/icons/path-44.png";
import "./style.css";

const SideBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState(location.pathname);

  const handleLogout = () => {
    dispatch(setLogout());
  };
  const handleNavItemClick = (key) => {
    setActiveNavItem(key);
  };
  return (
    <>
      {isLogin() ? (
        <div>
          <Container fluid>
            <Row className="d-flex">
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 bg-light bg_light side_nav_main  p-0">
                <Nav
                  defaultActiveKey={profileRoute}
                  className=" flex-column gap-2  "
                >
                  {getUserRole() === "customer" ? (
                    <CustomerLinks
                      handleNavItemClick={handleNavItemClick}
                      activeNavItem={activeNavItem}
                    />
                  ) : getUserRole() === "cleaner" ? (
                    <CleanerLinks
                      handleNavItemClick={handleNavItemClick}
                      activeNavItem={activeNavItem}
                    />
                  ) : (
                    ""
                  )}
                  <Link
                    className="bar_link sidebar-links logout_sidebar_link  "
                    href={landingPageRoute}
                    onClick={() => handleLogout()}
                  >
                    <div className="side_link_box">
                      <img
                        className="logout_icon_img"
                        src={logout}
                        alt="here is am img"
                      />{" "}
                      &nbsp;
                      <span className="nav_links_names"> Logout </span>
                    </div>
                  </Link>
                </Nav>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 layout_customer_bg">
                <Outlet />
              </div>
            </Row>
          </Container>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default SideBar;

const CustomerLinks = ({ handleNavItemClick, activeNavItem }) => {
  return (
    <>
      <Link
        className={`mt-5 bar_link sidebar-links ${
          activeNavItem === profileRoute ? "sidebar-active" : ""
        }`}
        to={profileRoute}
        onClick={() => handleNavItemClick(profileRoute)}
      >
        <div className="side_link_box">
          <img
            className={`profile_icon_img ${
              activeNavItem === profileRoute && "img-active"
            }`}
            src={profile}
            alt="here is am img"
          />{" "}
          &nbsp;
          <span className="nav_links_names"> Profile </span>
        </div>
      </Link>
      <Link
        className={`bar_link sidebar-links ${
          activeNavItem === homeRoute ? "sidebar-active" : ""
        }`}
        to={homeRoute}
        onClick={() => handleNavItemClick(homeRoute)}
      >
        <div className="side_link_box">
          <img
            className={`home_icon_img ${
              activeNavItem === homeRoute && "img-active"
            }`}
            src={home}
            alt="here is am img"
          />{" "}
          &nbsp;
          <span className="nav_links_names">Home</span>
        </div>
      </Link>
      <Link
        className={`bar_link sidebar-links ${
          activeNavItem === myBookingsRoute ? "sidebar-active" : ""
        }`}
        to={myBookingsRoute}
        onClick={() => handleNavItemClick(myBookingsRoute)}
      >
        {" "}
        <div className="side_link_box">
          <img
            className={`appointment_icon_img ${
              activeNavItem === myBookingsRoute && "img-active"
            }`}
            src={appointment}
            alt="here is an img"
          />{" "}
          &nbsp;
          <span className="nav_links_names">Appointments</span>
        </div>
      </Link>
      <Link
        className={` bar_link sidebar-links ${
          activeNavItem === resetPasswordRoute ? "sidebar-active" : ""
        }`}
        to={resetPasswordRoute}
        onClick={() => handleNavItemClick(resetPasswordRoute)}
      >
        <div className="side_link_box">
          <img
            className={`setting_icon_img ${
              activeNavItem === resetPasswordRoute && "img-active"
            }`}
            src={setting}
            alt="here is an img"
          />{" "}
          &nbsp;
          <span className="nav_links_names">Settings</span>
        </div>
      </Link>
    </>
  );
};

const CleanerLinks = ({ handleNavItemClick, activeNavItem }) => {
  return (
    <>
      <Link
        className={`mt-5 bar_link sidebar-links ${
          activeNavItem === profileRoute ? "sidebar-active" : ""
        }`}
        to={profileRoute}
        onClick={() => handleNavItemClick(profileRoute)}
      >
        <div className="side_link_box">
          <img
            className={`profile_icon_img ${
              activeNavItem === profileRoute && "img-active"
            }`}
            src={profile}
            alt="here is am img"
          />{" "}
          &nbsp;
          <span className="nav_links_names"> Profile </span>
        </div>
      </Link>

      <Link
        className={`bar_link sidebar-links ${
          activeNavItem === homeCleanerRoute ? "sidebar-active" : ""
        }`}
        to={homeCleanerRoute}
        onClick={() => handleNavItemClick(homeCleanerRoute)}
      >
        <div className="side_link_box">
          <img
            className={`profile_icon_img ${
              activeNavItem === homeCleanerRoute && "img-active"
            }`}
            src={home}
            alt="here is am img"
          />{" "}
          &nbsp;
          <span className="nav_links_names">Home</span>
        </div>
      </Link>

      <Link
        className={`bar_link sidebar-links ${
          activeNavItem === upComingBookingRoute ? "sidebar-active" : ""
        }`}
        to={upComingBookingRoute}
        onClick={() => handleNavItemClick(upComingBookingRoute)}
      >
        <div className="side_link_box">
          <img
            className={`appointment_icon_img ${
              activeNavItem === upComingBookingRoute && "img-active"
            }`}
            src={appointment}
            alt="here is an img"
          />{" "}
          &nbsp;
          <span className="nav_links_names">Appointments</span>
        </div>
      </Link>
      <Link
        className={`bar_link sidebar-links ${
          activeNavItem === resetPasswordRoute ? "sidebar-active" : ""
        }`}
        to={resetPasswordRoute}
        onClick={() => handleNavItemClick(resetPasswordRoute)}
      >
        <div className="side_link_box">
          <img
            className={`setting_icon_img ${
              activeNavItem === resetPasswordRoute && "img-active"
            }`}
            src={setting}
            alt="here is an img"
          />{" "}
          &nbsp;
          <span className="nav_links_names">Settings</span>
        </div>
      </Link>
    </>
  );
};
