import React, { useState } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { Outlet, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  adminActivitiesRoute,
  adminDashboardRoute,
  adminManageCleanersRoute,
  adminManageServicesRoute,
  landingPageRoute,
} from "../constants/constants";
import { setLogout } from "../pages/Auth/authSlice";

import dashboard from "../assets/icons/dashboard.png";
import cleaners from "../assets/icons/cleaners.png";
import activity from "../assets/icons/activities.png";
import logout from "../assets/icons/path-44.png";
import serviceIcon from "../assets/icons/service-icon.png";
import "./style.css";

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState(location.pathname);

  const handleLogout = () => {
    dispatch(setLogout());
  };
  const handleNavItemClick = (key) => {
    setActiveNavItem(key);
  };

  return (
    <div>
      <Container fluid>
        <Row className="d-flex">
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 bg-light bg_light side_nav_main  p-0">
            <Nav
              defaultActiveKey={adminDashboardRoute}
              className=" flex-column gap-2"
            >
              <Link
                className={`mt-5 bar_link sidebar-links ${
                  activeNavItem === adminDashboardRoute ? "sidebar-active" : ""
                }`}
                to={adminDashboardRoute}
                onClick={() => handleNavItemClick(adminDashboardRoute)}
              >
                <div className="side_link_box">
                  <img
                    className={`profile_icon_img ${
                      activeNavItem === adminDashboardRoute && "img-active"
                    }`}
                    src={dashboard}
                    alt="here is am img"
                  />{" "}
                  &nbsp;
                  <span className="nav_links_names"> Dashboard </span>
                </div>
              </Link>

              <Link
                className={` bar_link sidebar-links ${
                  activeNavItem === adminManageCleanersRoute
                    ? "sidebar-active"
                    : ""
                }`}
                to={adminManageCleanersRoute}
                onClick={() => handleNavItemClick(adminManageCleanersRoute)}
              >
                <div className="side_link_box">
                  <img
                    className={`home_icon_img ${
                      activeNavItem === adminManageCleanersRoute && "img-active"
                    }`}
                    src={cleaners}
                    alt="here is am img"
                  />{" "}
                  &nbsp;
                  <span className="nav_links_names">Cleaners</span>
                </div>
              </Link>
              <Link
                className={`bar_link sidebar-links ${
                  activeNavItem === adminActivitiesRoute ? "sidebar-active" : ""
                }`}
                to={adminActivitiesRoute}
                onClick={() => handleNavItemClick(adminActivitiesRoute)}
              >
                <div className="side_link_box">
                  <img
                    className={`appointment_icon_img ${
                      activeNavItem === adminActivitiesRoute && "img-active"
                    }`}
                    src={activity}
                    alt="here is an img"
                  />{" "}
                  &nbsp;
                  <span className="nav_links_names">Activity</span>
                </div>
              </Link>

              <Link
                className={`bar_link sidebar-links ${
                  activeNavItem === adminManageServicesRoute
                    ? "sidebar-active"
                    : ""
                }`}
                to={adminManageServicesRoute}
                onClick={() => handleNavItemClick(adminManageServicesRoute)}
              >
                <div className="side_link_box">
                  <img
                    className={`appointment_icon_img ${
                      activeNavItem === adminManageServicesRoute && "img-active"
                    }`}
                    src={serviceIcon}
                    alt="here is an img"
                  />{" "}
                  &nbsp;
                  <span className="nav_links_names">Services</span>
                </div>
              </Link>

              <Link
                className=" bar_link sidebar-links logout_sidebar_link"
                to={landingPageRoute}
                onClick={handleLogout}
              >
                <div className="side_link_box">
                  <img
                    className="logout_icon_admin"
                    style={{ width: "35px", height: "35px" }}
                    src={logout}
                    alt="here is an img"
                  />{" "}
                  &nbsp;
                  <span className="nav_links_names">Logout</span>
                </div>
              </Link>
            </Nav>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10  outlet_style">
            <Outlet />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardLayout;
