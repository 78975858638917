import { createSlice } from "@reduxjs/toolkit";
import { auth, authFields } from "../../constants/constants";

const initialState = {
  accessToken: localStorage.getItem(auth.accessToken),
  user: null,
  userEmail: localStorage.getItem(auth.userEmail),
  userPhone: localStorage.getItem(auth.userPhone),
  isValidOtp: localStorage.getItem(authFields.isValidOtp),
  userImage:
    !localStorage.getItem(auth.userImage) ||
    localStorage.getItem(auth.userImage) === "undefined"
      ? false
      : localStorage.getItem(auth.userImage),
  signUpContact: localStorage.getItem(authFields.signUpContact),
  profileStatus: localStorage.getItem(auth.profileStatus),
};
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setLogin: (state, action) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      if (user.email) {
        state.userEmail = user.email;
        localStorage.setItem(auth.userEmail, state.user.email);
      }
      if (user.phone) {
        localStorage.setItem(auth.userPhone, state.user.phone);
      }
      localStorage.setItem(auth.accessToken, accessToken);
      localStorage.setItem(auth.profileStatus, user.approval);
      localStorage.setItem(auth.accessToken, accessToken);
      localStorage.setItem(auth.userRole, state.user.role);
    },
    setSignUp: (state, action) => {
      state.user = action.payload.user;
    },
    setSignUpContact: (state, action) => {
      state.signUpContact = action.payload;
      localStorage.setItem(authFields.signUpContact, action.payload);
    },
    setIsValidOtp: (state, action) => {
      state.isValidOtp = action.payload;
      localStorage.setItem(authFields.isValidOtp, action.payload);
    },
    setRemoveIsValidOtp: (state) => {
      state.isValidOtp = null;
      localStorage.removeItem(authFields.isValidOtp);
    },
    setRemoveSignUpContact: (state) => {
      state.signUpContact = null;
      localStorage.removeItem(authFields.signUpContact);
    },
    setLogout: (state) => {
      state.user = null;
      state.accessToken = null;
      localStorage.clear();
    },

    resetSignUpStage: (state) => {
      state.signUpStage = 0;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      if (state.user.email) {
        state.userEmail = state.user.email;
        localStorage.setItem(auth.userEmail, state.user.email);
      }
      if (state.user.phone) {
        localStorage.setItem(auth.userPhone, state.user.phone);
      }
    },
    setUserImage: (state, action) => {
      state.userImage = action.payload;
      localStorage.setItem(auth.userImage, action.payload);
    },
  },
});

export const {
  setLogin,
  setSignUp,
  setIsValidOtp,
  setLogout,
  setUserImage,
  setUser,
  resetSignUpStage,
  setSignUpContact,
  setRemoveIsValidOtp,
  setRemoveSignUpContact,
} = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectEmail = (state) => state.auth.userEmail;
export const selectPhone = (state) => state.auth.userPhone;
export const selectUserImage = (state) => state.auth.userImage;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectIsValidOtp = (state) => state.auth.isValidOtp;
export const selectSignUpContact = (state) => state.auth.signUpContact;

export default authSlice.reducer;
