import React from "react";

import CustomerCardNotification from "../../components/CustomerCardNotification";
import AdminNotificationsList from "../../components/AdminNotificationList";

import "./style.css";

const AdminNotifications = () => {
  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <AdminNotificationsList />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 py-4">
          <div className="notification_cleaner_card">
            <CustomerCardNotification />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNotifications;
