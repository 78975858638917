import React, { useState, useRef } from "react";

import { useGetUserBookingQuery } from "../Booking/bookingApiSlice";
import UpcomingBookingCard from "../../components/UpcomingBookingCard";
import DisplayNotification from "../../components/DisplayNotification";
import UCBookingCmpt from "../../components/UCBookingCmpt";
import CustomerCard from "../../components/CustomerCard";
import {
  pastBookingsRoute,
  upComingBookingRoute,
} from "../../constants/constants";

import "./style.css";

const CleanerHomeBookings = () => {
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const { data: incomingBookings, isLoading: isIncomingBookingsLoading } =
    useGetUserBookingQuery({
      role: "cleaner",
      jobStatus: ["pending", "onSite"],
      bookingConfirmation: "approved",
    });
  const { data: pastBookings, isLoading: isPastBookingsLoading } =
    useGetUserBookingQuery({
      role: "cleaner",
      jobStatus: "completed",
      bookingConfirmation: "approved",
    });

  return (
    <div>
      <div className=" container-fluid main_cleaner_booking">
        <div className="row section_main_bookins_clenaer d-flex ">
          <div className="col-12 col-md-12 col-lg-4 col-xl-4 booking_upcoming_section_5 ">
            <div>
              <UCBookingCmpt
                seeAllButton={true}
                route={upComingBookingRoute}
                pageHeading={"Upcoming Bookings"}
              />
              <div
                className="customer_booking_hp" 
                ref={todayBookingsRef}
                onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
                onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {!isIncomingBookingsLoading &&
                incomingBookings &&
                incomingBookings.length > 0 ? (
                  <div>
                    {incomingBookings.map((booking) => (
                      <CustomerCard booking={booking} key={booking._id} />
                    ))}
                  </div>
                ) : (
                  <div>No bookings found</div>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-8 col-xl-8 notification_box_lists_cleaner ">
            <div className="section_sub_main02">
              <DisplayNotification height="42vh" seeButton={true} />
            </div>
            <div className="section_sub_main02 mt-2 notification_box_lists_cleaner02 ">
              <UCBookingCmpt
                seeAllButton={true}
                route={pastBookingsRoute}
                pageHeading={"Past Bookings"}
              />
              <hr className="notification_hr" />

              <div
                className="customer_past_booking_hp"
                ref={todayBookingsRef}
                onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
                onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {!isPastBookingsLoading &&
                pastBookings &&
                pastBookings.length > 0 ? (
                  <>
                    {pastBookings.map((booking) => (
                      <UpcomingBookingCard
                        booking={booking}
                        key={booking._id}
                        navigateRoute={pastBookingsRoute}
                      />
                    ))}
                  </>
                ) : (
                  <div>No bookings found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CleanerHomeBookings;
