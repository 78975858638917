import { apiSlice } from "../../state/api/apiSlice";
import { apiRoutes } from "../../constants/constants";

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: (query) => {
        const queryParams = query ? `/${query}` : "";
        return {
          url: `${apiRoutes.getAllNotifications}${queryParams}`,
          method: "GET",
        };
      },
      transformResponse: (response) => ({
        status: response.status,
        notifications: response.data.notifications,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    updateNotification: builder.mutation({
      query: ({ notificationId, body }) => ({
        url: `${apiRoutes.updateNotification}/${notificationId}`,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        notification: response.data.notification,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    getNotification: builder.query({
      query: (query) => {
        const queryParams = query ? `/${query}` : "";
        return {
          url: `${apiRoutes.getNotification}${queryParams}`,
          method: "GET",
        };
      },
      transformResponse: (response) => ({
        status: response.status,
        notification: response.data.notification,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    getUnreadNotificationsCount: builder.query({
      query: (query) => {
        const queryParams = query ? `/${query}` : "";
        return {
          url: `${apiRoutes.getUnreadNotificationsCount}${queryParams}`,
          method: "GET",
        };
      },
      transformResponse: (response) => ({
        status: response.status,
        unreadNotificationsCount: response.data.unreadNotificationsCount,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
  }),
});

export const {
  useGetAllNotificationsQuery,
  useUpdateNotificationMutation,
  useGetNotificationQuery,
  useGetUnreadNotificationsCountQuery,
} = notificationApiSlice;
