import * as yup from "yup";
import { isPhone, isEmail } from "./contactValidation";

const cleanerSchema = yup.object().shape({
  firstName: yup.string().required("First Name Required!"),
  lastName: yup.string().required("Last Name Required!"),
  email: yup.string().required("Required").email("Invalid email address"),
  phone: yup
    .string()
    .required("Required")
    .test("is-valid-contact", "Invalid Phone Number", (value) => {
      return isPhone(value);
    }),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
});

export default cleanerSchema;
