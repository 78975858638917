import React, { useState } from "react";

import "./style.css";

const OptionSelect = ({ setRecurring, setRecurringOption }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedOption, setSelectedOption] = useState("once");
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const selectDays = (days, selectedDay) => {
    let newDates;

    if (days.includes(selectedDay)) {
      newDates = days.filter((day) => day !== selectedDay);
    } else {
      newDates = [...days];
      newDates.push(selectedDay);
    }
    return newDates;
  };

  const handleDateClick = (number) => {
    const monthDates = selectDays(selectedDates, number);
    setSelectedDates(monthDates);
    setRecurring({
      isRecurring: true,
      monthly: monthDates,
    });
  };
  const handleWeekDays = (day) => {
    const weekDays = selectDays(selectedDays, day);
    setSelectedDays(weekDays);
    setRecurring({
      isRecurring: true,
      weekly: weekDays,
    });
  };

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    setRecurringOption(e.target.value);
    if (e.target.value === "once") setRecurring({ isRecurring: false });
    else if (e.target.value === "monthly")
      setRecurring({
        isRecurring: true,
        monthly: selectedDates,
      });
    else if (e.target.value === "weekly")
      setRecurring({
        isRecurring: true,
        weekly: selectedDays,
      });
  };

  return (
    <div>
      <h5 className="booking_options_heading">
        <b>Booking options:</b>
      </h5>
      <form>
        <div className="d-flex justify-content-center">
          <div>
            <div className=" signup_label_input01">
              <select
                className="input_signup01 option_select mb-5  "
                value={selectedOption}
                onChange={handleChange}
              >
                <option className="once" value="once">
                  Once
                </option>
                <option className="option_week" value="weekly">
                  Weekly
                </option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          </div>
        </div>
      </form>

      {selectedOption === "weekly" && (
        <div className="d-flex justify-content-center">
          <div className="section_select_weeks">
            <p className="select_days_week_options">Select day/s of the week</p>
            <div className="d-flex">
              {weekDays.map((day, i) => (
                <div
                  className=" days_week_booking_options p-2"
                  key={day}
                  style={{
                    backgroundColor: selectedDays.includes(i)
                      ? "#D2EBB5"
                      : "transparent",
                    borderRadius: selectedDays.includes(i) ? "50%" : "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleWeekDays(i);
                  }}
                >
                  {day}{" "}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedOption === "monthly" && (
        <div className="d-flex justify-content-center">
          <div>
            <p>Select day/s of the month</p>

            <div className="show_numbers_month">
              {selectedOption === "monthly" && (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(6, 1fr)",
                  }}
                >
                  {Array.from({ length: 30 }, (_, index) => index + 1).map(
                    (number, index) => (
                      <span
                        key={index}
                        className="select_numbers"
                        style={{
                          backgroundColor: selectedDates.includes(number)
                            ? "#D2EBB5"
                            : "transparent",
                        }}
                        onClick={() => handleDateClick(number)}
                      >
                        {number}
                      </span>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionSelect;
