import React from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import loginSchema from "./../../../validation/LoginSchema";
import { isEmail, isPhone } from "../../../validation/contactValidation";
import { useForgetPasswordMutation } from "../../../pages/Auth/authApiSlice";
import { successToast, errorToast } from "../../../toastNotification/toast";

import "./style.css";

const ResetPassword = () => {
  const initialValues = {
    contact: "",
    password: "",
  };
  const [forgetPassword, { isLoading }] = useForgetPasswordMutation();
  const handleSubmit = async (values, actions) => {
    let requestParams;
    if (isEmail(values.contact)) {
      requestParams = { email: values.contact };
    } else if (isPhone(values.contact)) {
      requestParams = { phone: values.contact };
    } else {
      errorToast("Invalid Email or Phone!");
      return;
    }
    try {
      const response = await forgetPassword({
        ...requestParams,
        password: values.password,
      }).unwrap();
      successToast("Reset Link Successfully Sent!");
      actions.resetForm();
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-12 ">
            <div>
              <div>
                <p className="remove_heading">
                  <b>Reset Password</b>
                </p>
              </div>

              <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={loginSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mt-3 d-flex justify-content-center">
                      <div>
                        <Form.Group controlId="formBasicCurrentPassword">
                          <Form.Label>Current Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            className="remove_email"
                            placeholder="Password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.password && !!errors.password}
                            disabled={isLoading}
                          />
                          {touched.password && errors.password && (
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="mt-3 d-flex justify-content-center">
                      <div>
                        <Form.Group controlId="formBasicContact">
                          <Form.Label>Enter email\phone number <br/> <span className="password_number_settings small">(E.g. +447123123123)</span></Form.Label>
                          <Form.Control
                            type="text"
                            name="contact"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.contact}
                            className="remove_email"
                            placeholder="Email/Phone"
                            isInvalid={touched.contact && !!errors.contact}
                            disabled={isLoading}
                          />
                          {touched.contact && errors.contact && (
                            <Form.Control.Feedback type="invalid">
                              {errors.contact}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="btn_confirm_div d-flex justify-content-center">
                      <button
                        className="btn_confirm"
                        type="submit"
                        disabled={isLoading}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
