import { createSlice } from "@reduxjs/toolkit";
import { servicesConst } from "./../../../constants/constants";
const initialState = {
  service:
    JSON.parse(
      localStorage.getItem(servicesConst.selectedService)
    ) || null,
};
export const servicesSlice = createSlice({
  name: "Services",
  initialState: initialState,
  reducers: {
    setSelectedService: (state, action) => {
      state.service = action.payload;
      localStorage.setItem(
        servicesConst.selectedService,
        JSON.stringify(state.service)
      );
    },
    resetSelectedService: (state) => {
      localStorage.removeItem(
        servicesConst.selectedService
      );
      state.service = null;
    },
  },
});

export const { setSelectedService, resetSelectedService } =
  servicesSlice.actions;
export const selectSelectedService = (state) =>
  state.services.service;

export default servicesSlice.reducer;
