import { apiSlice } from "../../../state/api/apiSlice";
import { apiRoutes } from "../../../constants/constants";

export const createBookingApiSlice =
  apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      createBooking: builder.mutation({
        query: (body) => ({
          url: apiRoutes.createBooking,
          method: "POST",
          body: { ...body },
        }),
        transformResponse: (response) => ({
          status: response.status,
          bookings: response.data.bookings,
        }),
        transformErrorResponse: (response) => ({
          status: response.data.status,
          message: response.data.message,
        }),
      }),
    }),
  });

export const { useCreateBookingMutation } =
  createBookingApiSlice;
