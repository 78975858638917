import { Outlet, Navigate } from "react-router-dom";
import {
  adminDashboardRoute,
  homeCleanerRoute,
  homeRoute,
} from "../constants/constants";
import { getUserRole, isLogin } from "../constants/utils";
const RestrictLayout = () => {
  return (
    <>
      {isLogin() ? (
        getUserRole() === "customer" ? (
          <Navigate to={homeRoute} />
        ) : getUserRole() === "cleaner" ? (
          <Navigate to={homeCleanerRoute} />
        ) : (
          <Navigate to={adminDashboardRoute} />
        )
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default RestrictLayout;
