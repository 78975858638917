import React from "react";

import prfImgCleancer from "../../assets/profile_user.png";
import phone from "../../assets/icons/path-23.png";
import mail from "../../assets/icons/mail.png";
import "./style.css";

const AddedSuccessfully = ({ cleaner }) => {
  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 img_successfull_admin d-flex justify-content-end">
          <img
            className="cleaner_img_successfull"
            src={cleaner?.image?.url ? cleaner.image.url : prfImgCleancer}
            alt="here is an img"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 details_cleaners_admin d-flex align-items-center">
          <div>
            <h3 className="cleaner_name_head">
              <b>{cleaner.name}</b>
            </h3>
            <p className="avalibility_cleaner">{cleaner.availability}</p>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex added_details">
          <span className="added_title">Successfully Added!</span>
        </div>
      </div>
      <div className="row d-flex">
        <div className="coxs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 wa_info_box d-flex justify-content-center">
          <div>
            <div>
              <span className="media_detail">
                <img className="wa_icon" src={phone} alt="here is an img" />
                &nbsp;&nbsp;&nbsp; {cleaner.phone}
              </span>
            </div>
            <div className="mt-2">
              <span className="media_detail">
                <img className="wa_icon" src={mail} alt="here is an img" />
                &nbsp;&nbsp;&nbsp; {cleaner.email}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddedSuccessfully;
