import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { errorToast, successToast } from "../../../toastNotification/toast";
import {
  pastBookingsRoute,
  upComingBookingRoute,
} from "../../../constants/constants";
import {
  useGetBookingQuery,
  useGetUserBookingQuery,
  useUpdateArrivedAtLocationMutation,
  useUpdateCompleteBookingMutation,
} from "../bookingApiSlice";
import { formatTime } from "../../../constants/utils";
import UCBookingCmpt from "../../../components/UCBookingCmpt";
import UpcomingBookingCard from "../../../components/UpcomingBookingCard";
import CleanerBookingCard from "../../../components/CleanerBookingCard";

import "./style.css";

const UpcomingBookings = () => {
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const { bookingId } = useParams();
  const { data: incomingBookings, isLoading: isIncomingBookingsLoading } =
    useGetUserBookingQuery({
      role: "cleaner",
      jobStatus: ["pending", "onSite"],
      bookingConfirmation: "approved",
    });

  return (
    <div>
      <div className=" container-fluid main_upcoming_booking_cleaner_box">
        <div className="row upcoming_booking_cleaner_section_main d-flex ">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 padding_style ">
            <div className="upcoming_booking_cleaner_section_sub_main">
              <UCBookingCmpt
                seeAllButton={false}
                route={upComingBookingRoute}
                pageHeading={"Upcoming Bookings"}
              />
              {!isIncomingBookingsLoading ? (
                incomingBookings && incomingBookings.length > 0 ? (
                  <div
                    className="upcoming_booking_list_cleaner_main"
                    ref={todayBookingsRef}
                    onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
                    onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                  >
                    {incomingBookings.map((booking) => (
                      <UpcomingBookingCard
                        booking={booking}
                        key={booking._id}
                        selectedBookingId={bookingId}
                        navigateRoute={upComingBookingRoute}
                      />
                    ))}
                  </div>
                ) : (
                  <p>No booking Found!</p>
                )
              ) : (
                <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-6 col-xl-6 padding_style">
            <div className="upcoming_booking_cleaner_section_sub_main01">
              {bookingId ? (
                <SelectedBookingCard bookingId={bookingId} />
              ) : (
                <p>Please Select a Booking!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingBookings;

const SelectedBookingCard = ({ bookingId }) => {
  const navigate = useNavigate();
  const [jobCompleteMessage, setJobCompleteMessage] = useState(false);

  const [booking, setBooking] = useState(null);
  const [updateArrivedAtLocation, { isLoading: isLoadingArrivedAtLocation }] =
    useUpdateArrivedAtLocationMutation();

  const { data: selectedBooking, isLoading: isSelectedBookingLoading } =
    useGetBookingQuery(bookingId);
  const [updateCompleteBooking, { isLoading: isLoadingCompleteBooking }] =
    useUpdateCompleteBookingMutation();
  const handleSetArrivalTime = async () => {
    try {
      const arrivedAt = new Date();
      const response = await updateArrivedAtLocation({
        bookingId,
        arrivedAt,
      }).unwrap();
      setBooking(response.booking);
    } catch (error) {
      errorToast(error.message);
    }
  };
  const isCheckedTime = (checkTime) => {
    return !!checkTime;
  };

  const handleSetJobCompletedAt = async () => {
    try {
      if (booking.arrivedAt) {
        const jobCompletedAt = new Date();
        const response = await updateCompleteBooking({
          bookingId,
          jobCompletedAt,
        }).unwrap();
        setBooking(response.booking);
      } else {
        errorToast("Check Arrived On Location First!");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };
  const handleDoneClick = () => {
    if (booking.jobStatus === "completed") {
      navigate(`${pastBookingsRoute}/${booking._id}`);
      successToast("Booking Completed!");
      setJobCompleteMessage(true);
    } else errorToast("Complete the Booking Please!");
  };

  useEffect(() => {
    setBooking(selectedBooking?.booking);
    if (selectedBooking?.booking?.jobStatus === "completed") {
      navigate(`${pastBookingsRoute}/${selectedBooking.booking._id}`);
      setJobCompleteMessage(true);
    }
  }, [selectedBooking]);
  return (
    <div>
      {!isSelectedBookingLoading && booking ? (
        <>
          <CleanerBookingCard booking={booking} />

          <div className=" d-flex toogle_time mx-4 pt-4">
            <div className="arrived_location_checkbox">
              <label className="checkbox-container01">
                <input
                  type="checkbox"
                  checked={isCheckedTime(booking.arrivedAt)}
                  disabled={!!booking.arrivedAt}
                  onChange={handleSetArrivalTime}
                />
                <span className="checkmark01"></span>
                Cleaner has arrived at the location
              </label>
            </div>
            {!isLoadingArrivedAtLocation ? (
              booking.arrivedAt && (
                <div>
                  <span className=" show_time01">
                    {formatTime(booking.arrivedAt)}
                  </span>
                </div>
              )
            ) : (
              <div className="row d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center">
                  <Spinner />
                </div>
              </div>
            )}
          </div>

          <div className=" d-flex toogle_time  mx-4">
            <div className="arrived_location_checkbox">
              <label className="checkbox-container01">
                <input
                  type="checkbox"
                  checked={isCheckedTime(booking.jobCompletedAt)}
                  disabled={!!booking.jobCompletedAt}
                  onChange={handleSetJobCompletedAt}
                />
                <span className="checkmark01"></span>
                Job finished
              </label>
            </div>
            {!isLoadingCompleteBooking ? (
              booking.jobCompletedAt && (
                <div>
                  <span className=" show_time01">
                    {formatTime(booking.jobCompletedAt)}
                  </span>
                </div>
              )
            ) : (
              <div className="row d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center">
                  <Spinner />
                </div>
              </div>
            )}
          </div>

          <div className="btn_box_01">
            <div className="d-flex justify-content-center">
              {jobCompleteMessage ? (
                <p>Booking has been completed!</p>
              ) : (
                <button className="btn_ntf_confirm" onClick={handleDoneClick}>
                  Done
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="row d-flex justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      )}
    </div>
  );
};
