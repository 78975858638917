import { Outlet, Navigate } from "react-router-dom";
import { getUserRole } from "../constants/utils";
import { landingPageRoute } from "../constants/constants";

const SettingsLayout = () => {
  return (
    <>
      {getUserRole() === "cleaner" || getUserRole() === "customer" ? (
        <Outlet />
      ) : (
        <Navigate to={landingPageRoute} />
      )}
    </>
  );
};

export default SettingsLayout;
