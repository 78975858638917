import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useSocket } from "../../webSocket/socket";
import NotificationDashboard from "../NotificationsDashboard";

import {
  useGetAllNotificationsQuery,
  useUpdateNotificationMutation,
} from "../../pages/Notification/notificationApiSlice";
import { errorToast } from "../../toastNotification/toast";
import { countUnreadNotifications } from "../../constants/utils";
import { setNotificationCount } from "../../pages/Notification/notificationSlice";

const AdminNotificationsList = () => {
  const dispatch = useDispatch();
  const socket = useSocket();
  const { data, refetch, isLoading } = useGetAllNotificationsQuery("admin");
  const [updateNotification, { isLoading: isUpdateNotificationLoading }] =
    useUpdateNotificationMutation();
  const [notification, setNotification] = useState(null);
  socket.on("bookingNotification", (notification) => {
    setNotification(notification);
  });
  useEffect(() => {
    if (data?.notifications) {
      const unReadNotifications = countUnreadNotifications(data.notifications);
      dispatch(setNotificationCount(unReadNotifications));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [notification, refetch]);

  const handleViewNotification = async (notificationId) => {
    try {
      const response = await updateNotification({
        notificationId,
        body: { isRead: true },
      });
      refetch();
    } catch (error) {
      errorToast(error.message);
    }
  };

  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div className="notification_main">
      <div className="notication_admin mt-4 pb-4">
        <h5 className="mx-4 pt-3">
          <b>
            Notifications ({data?.notifications ? data.notifications.length : 0}
            )
          </b>
        </h5>
        <div className="notification_box_details mx-4">
          <div
            className="notifications_admin_details pt-4 "
            ref={todayBookingsRef}
            onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
            onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {!isLoading &&
              data?.notifications &&
              data.notifications.map((notification) => (
                <NotificationDashboard
                  notification={notification}
                  onHandleViewNotification={handleViewNotification}
                  key={notification._id}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNotificationsList;
