import React, { useEffect, useState } from "react";
import prev from "../../assets/icons/path-274.png";
import fwd from "../../assets/icons/path-275.png";
import parrow from "../../assets/icons/prev_arrow.png";
import farrow from "../../assets/icons/fwd_arrow.png";
import "./style.css";
import { errorToast } from "../../toastNotification/toast";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const CustomCalendar = ({
  setBookingDate,
  bookingDate,
  setSelectedCleaners,
  refetchCleaners,
  isFutureDate,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [activeDay, setActiveDay] = useState(null);
  useEffect(() => {
    setActiveDay(new Date(bookingDate).toDateString());
  }, [bookingDate]);
  const handleDayClick = (day) => {
    if (!isFutureDate(day)) {
      errorToast("Please Select Future Date!");
      return;
    }
    setSelectedCleaners([]);
    setBookingDate(day);
    refetchCleaners();
  };

  const changeMonth = (increment) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + increment);

    setCurrentDate(newDate);
  };

  const changeWeek = (increment) => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + increment * 7);

    setCurrentDate(newDate);
  };

  const renderWeekDays = () => {
    return daysOfWeek.map((day) => (
      <div key={day} className="weekday" style={{ padding: " 0px 18px" }}>
        {day}
      </div>
    ));
  };

  const renderCalendarDays = () => {
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay());

    const days = [];

    for (let i = 0; i < 7; i++) {
      const day = new Date(firstDayOfWeek);
      day.setDate(firstDayOfWeek.getDate() + i);

      const isCurrentMonth = day.getMonth() === currentDate.getMonth();

      days.push(
        <div
          key={day.toISOString()}
          className={`day ${isCurrentMonth ? "" : "disabled"} display_days`}
          onClick={() => handleDayClick(day.toDateString())}
        >
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: "24px",
              height: "24px",
              cursor: "pointer",
              backgroundColor:
                day.toDateString() === activeDay ? "#D2EBB5" : "",
              borderRadius: day.toDateString() === activeDay ? "50%" : "0px",
            }}
          >
            {day.getDate()}
          </span>
        </div>
      );
    }

    return days;
  };

  return (
    <div className="custom_calendar">
      <div className="row d-flex justifycontent-center">
        <div className="col-12 ">
          <div className="header">
            <div className="d-flex justify-content-center">
              <div>
                <button className="arrow" onClick={() => changeMonth(-1)}>
                  <img className="btn_img" src={parrow} alt="img" />
                </button>
              </div>
              <div className="px-4 d-flex justify-content-center align-items-center display_month ">
                <b>
                  {new Intl.DateTimeFormat("en-US", {
                    month: "long",
                    year: "numeric",
                  }).format(currentDate)}
                </b>
              </div>
              <div>
                <button className="arrow" onClick={() => changeMonth(1)}>
                  <img className="btn_img" src={farrow} alt="img" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row d-flex justify-content-center">
        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 d-flex justify-content-center ">
          <div className="weeks_days_calendar">
            <div className="week_buttons ">
              <div className="d-flex justify-content-center ">
                <button
                  className="btn_week_arrows "
                  onClick={() => changeWeek(-1)}
                >
                  <img className="days_arrow" src={prev} alt="img" />
                </button>
                <div className="week_days  d-flex justify-content-center  ">
                  {renderWeekDays()}
                </div>
                <button
                  className="btn_week_arrows"
                  onClick={() => changeWeek(1)}
                >
                  <img src={fwd} alt="img" />
                </button>
              </div>
              <div className="calendar_days d-flex justify-content-center">
                <button className=" d-flex justify-content-center btn_calendar_days">
                  {renderCalendarDays()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div></div>
    </div>
  );
};

export default CustomCalendar;
