import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import AdminServiceCard from "../AdminServiceCard";
import { useGetAllServicesQuery } from "../../pages/Services/OurServices/servicesApiSlice";
import { Spinner } from "react-bootstrap";
const AdminServiceList = (triggerRefetch) => {
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const { data, refetch, isLoading } = useGetAllServicesQuery();
  useEffect(() => {
    refetch();
  }, [triggerRefetch]);
  return (
    <div>
      <div className="row d-flex addCleaner_list  ">
        <span className="pt-3">Services({!data ? 0 : data.length})</span>

        <div
          className="col-12 px-3  add_services_list_admin_dashboard"
          ref={todayBookingsRef}
          onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
          onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {isLoading ? (
            <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
          ) : data ? (
            data.map((service) => (
              <AdminServiceCard
                service={service}
                triggerRefetch={refetch}
                key={service._id}
              />
            ))
          ) : (
            <p>No service Found!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminServiceList;
