import { Outlet } from "react-router-dom";
import SearchBar from "../components/SearchBar";
const SearchBarLayout = () => {
  return (
    <div className="d-flex flex-column min-vh-100 position-relative">
      <SearchBar />
      <Outlet />
    </div>
  );
};

export default SearchBarLayout;
