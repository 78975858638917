import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useAppointCleanerMutation } from "../AdminDashboard/adminDashboardApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";
import AppointCleanerList from "../../components/AppointCleanerList";
import AppointCleanerDetail from "../../components/AppointCleanerDetail";

const AdminAppointCleaner = () => {
  const { bookingId } = useParams();
  const [appointCleaner, { isLoading }] = useAppointCleanerMutation();
  const [bookingConfirmation, setBookingConfirmation] = useState({
    prevCleanerId: "",
    cleanerId: "",
    status: "rejected",
  });
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const handleAppointCleaner = async (cleanerId) => {
    try {
      const response = await appointCleaner({ bookingId, cleanerId }).unwrap();
      if (response.status === "success") {
        setBookingConfirmation({
          ...bookingConfirmation,
          cleanerId,
          status: response.booking.bookingConfirmation,
        });
        setTriggerRefetch((prevState) => !prevState);
        successToast("Cleaner Appointed!");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <AppointCleanerList
            onHandleAppointCleaner={handleAppointCleaner}
            bookingConfirmation={bookingConfirmation}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          <AppointCleanerDetail
            triggerRefetch={triggerRefetch}
            setBookingConfirmation={setBookingConfirmation}
            bookingConfirmation={bookingConfirmation}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminAppointCleaner;
