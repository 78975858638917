import React from "react";

import { useApproveCleanerMutation } from "../../pages/AdminDashboard/adminDashboardApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";

import profileImg from "../../assets/profile_user.png";
import phone from "../../assets/icons/path-23.png";
import email from "../../assets/icons/mail.png";
import "./style.css";

const AdminApproveCleanerCard = ({ cleaner, setCleaner }) => {
  const [approveCleaner, { isLoading }] = useApproveCleanerMutation();
  const handleCleanerApprovalClick = async (cleanerId, approval) => {
    try {
      const response = await approveCleaner({ cleanerId, approval }).unwrap();
      if (response.status === "success") {
        setCleaner(response.user);
        if (response.user.approval === "approved")
          successToast("Cleaner Account Approved!");
        else if (response.user.approval === "rejected")
          successToast("Cleaner Account Rejected!");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row d-flex pt-4">
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex  justify-content-center">
          <img
            className="prof_img"
            src={cleaner?.image?.url ? cleaner.image.url : profileImg}
            alt="here is an img"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 img_box_customer_detail d-flex align-items-center">
          <div>
            <h3 className="customer_name">
              <b>{cleaner.name}</b>
            </h3>
          </div>
        </div>
      </div>
      <p className="contact_info_admin mx-3 pt-4">
        <b>Contact Info</b>
      </p>
      {cleaner.phone && (
        <div className="mx-4">
          <span className="phone_mail">
            <img
              className="phone_number_admin"
              src={phone}
              alt="here is an icon"
            />
            &nbsp;&nbsp; {cleaner.phone}
          </span>
        </div>
      )}
      {cleaner.email && (
        <div className="mx-4">
          <span className="phone_mail">
            <img
              className="phone_number_admin"
              src={email}
              alt="here is an icon"
            />
            &nbsp;&nbsp; {cleaner.email}
          </span>
        </div>
      )}
      {cleaner.approval === "pending" && (
        <div className="approve-cleaner__buttons">
          <div className="d-flex justify-content-center button_box">
            <button
              className="btn_approve_cleaner_account"
              onClick={() =>
                handleCleanerApprovalClick(cleaner._id, "rejected")
              }
              disabled={isLoading}
            >
              <b>Reject Account</b>
            </button>
          </div>
          <div className="d-flex justify-content-center mt-2 button_box2">
            <button
              className="btn_reject_cleaner_account"
              onClick={() =>
                handleCleanerApprovalClick(cleaner._id, "approved")
              }
              disabled={isLoading}
            >
              <b> Approve Account</b>
            </button>
          </div>
        </div>
      )}
      {cleaner.approval === "approved" && (
        <div className="d-flex justify-content-center mt-2 button_box2">
          <button className="btn_reject_cleaner_account" disabled={true}>
            <b> Account Approved!</b>
          </button>
        </div>
      )}
      {cleaner.approval === "rejected" && (
        <div className="d-flex justify-content-center mt-2 button_box2">
          <button className="btn_reject_cleaner_account" disabled={true}>
            <b> Account Rejected!</b>
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminApproveCleanerCard;
