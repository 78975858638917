import React from "react";
import  { useState, useRef } from "react";
import { Spinner } from "react-bootstrap";

import { useGetAllServicesQuery } from "../OurServices/servicesApiSlice";
import ServicesCards02 from "../../../components/ServicesCards02";

import "./style.css";

const OurServicesMain = () => {
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const { data, isLoading } = useGetAllServicesQuery();
  return (
    <div className="main">
      <h1 className="text-center heading_our_services">
        <b>Our Services</b>
      </h1>
      <div
        className="row d-flex justify-content-center main_box "
        style={{ padding: '10px' }}
        ref={todayBookingsRef}
        onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
        onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        
        {isLoading ? (
          <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
        ) : data ? (
          data.map((service) => (
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
              key={service._id}
            >
              <ServicesCards02 service={service} />
            </div>
          ))
        ) : (
          <p>No Services to Show!</p>
        )}
      </div>
    </div>
  );
};

export default OurServicesMain;
