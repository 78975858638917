import React from "react";

import kitchen from "../../assets/icons/Path33.png";
import "./style.css";
import { useDeleteServiceMutation } from "../../pages/Services/OurServices/servicesApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";
import { useNavigate } from "react-router-dom";
import { adminManageServicesRoute } from "../../constants/constants";

const AdminServiceCard = ({ service, triggerRefetch }) => {
  const navigate = useNavigate();
  const [deleteService, { isLoading }] = useDeleteServiceMutation();
  const handleDeleteClick = async (serviceId) => {
    try {
      const response = await deleteService(serviceId).unwrap();
      triggerRefetch();
      successToast("Service Deleted!");
    } catch (error) {
      errorToast(error.message);
    }
  };
  const handleUpdateClick = (serviceId) => {
    navigate(`${adminManageServicesRoute}/${serviceId}`);
  };
  return (
    <div className="service_list_admin_main mt-4 px-2 py-2">
      <div className="row d-flex">
        <div className="col-12">
          <h4 className="service_title_admin">{service.title}</h4>
        </div>
      </div>
      <div className="row d-flex ">
        <div className="col-6 d-flex justify-content-center align-items-center">
          <span className="detail_style">
            <b>{service.hours} Hours</b>
          </span>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <button
            className="btn_update mx-1"
            onClick={() => {
              handleUpdateClick(service._id);
            }}
          >
            Update
          </button>{" "}
          <button
            className=" btn_update delete mx-1"
            onClick={() => {
              handleDeleteClick(service._id);
            }}
          >
            Delete
          </button>
        </div>
      </div>

      <div className="row d-flex ">
        <div className="col-6 d-flex justify-content-center">
          <span className="detail_style01">
            <b> &#163;{service.price}</b>
          </span>
        </div>
      </div>

      <div className="row d-flex">
        <div className="col-12 ">
          <p className="detail_style02">Included Tasks:</p>
          <div className="d-flex flex-wrap justify-content-start px-2">
            {service.tasks.map((task, i) => (
              <div key={i} className="custom_tasks_admin_services">
                <div>
                  <img
                    className="icon_service_img"
                    src={task?.image?.url}
                    alt="No Service Pic"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <span className="service_title"> {task?.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminServiceCard;
