import React from "react";
import { Link } from "react-router-dom";

import mail from "./../../assets/icons/Path-31.png";
import phone from "./../../assets/icons/Path-33.png";
import fb from "./../../assets/icons/24.png";
import insta from "./../../assets/icons/Path-37.png";
import twitter from "./../../assets/icons/logo.png";
import lnkd from "./../../assets/icons/Path-39.png";
import whatsappIcon from "./../../assets/icons/WAlogo.svg";
import apple from "./../../assets/apple.png";
import store from "./../../assets/store.png";

import "./style.css";

const Footer = () => {
  return (
    <div className="footer_main">
      <div className="container-fluid footer">
        <div className="row px-2 d-flex justify-content-center  footer_sub_main">
          <div className="col-12 col-md-6 col-lg-3 d-flex justify-content-center  ">
            <div>
              <ul className="list-unstyled px-2 pt-4">
                <li>
                  <Link
                    className="text-decoration-none text-dark"
                    to="/services"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none text-dark" to="/about">
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3  pt-3 ">
            <div>
              <h5>
                <b className="footer_content">Contact Us</b>
              </h5>
              <p className="contact_link_footer footer_cell">
                <a
                  className="link_ref"
                  href="mailto:info@cleanapp.org.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="img" src={mail} alt="Email icon" />
                  &nbsp;info@cleanapp.org.uk
                </a>
              </p>

              <p className="contact_link_footer footer_cell">
                <span className="link_ref">
                  <img className="img" src={phone} alt="phone icon" />
                  &nbsp;
                  <span className="font_weight_adjustment"> 02045051717 </span>
                </span>
              </p>

              <p className="contact_link_footer footer_cell">
                <a
                  className="link_ref"
                  href="https://wa.me/447763489908"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="img_wa_icon"
                    src={whatsappIcon}
                    alt="WhatsApp icon"
                  />
                  &nbsp;07763489908
                </a>
              </p>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-2  find_us pt-4">
            <span>
              <b>Find us on</b>
            </span>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <div></div>
            {/* <div className="d-flex justify-content-around mt-4">
              <div className="d-flex btn_store">
                <button className="btn_footer_link ">
                  <div className="d-flex">
                    <div>
                      <img
                        className="apple__footer_image"
                        src={apple}
                        alt="img"
                      />
                    </div>
                    <div>
                      <span>
                        Download on the
                        <br />
                        <b>App Store</b>
                      </span>
                    </div>
                  </div>
                </button>
                <button className="btn_footer_link">
                  <div className="d-flex">
                    <div>
                      <img
                        className="apple__footer_image"
                        src={store}
                        alt="img"
                      />
                    </div>
                    <div>
                      <span>
                        Get it on
                        <br />
                        <b>Google Play</b>
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div> */}
            <div className="d-flex justify-content-center px-3 py-2 footer_social_icons ">
              <a
                href="https://www.facebook.com/cleanapp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social_icons mx-3" src={fb} alt="img" />
              </a>
              <a
                href="https://www.instagram.com/cleanapp.ltd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social_icons mx-3" src={insta} alt="img" />
              </a>
              <a
                href="https://twitter.com/cleanappltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="social_icons mx-3" src={twitter} alt="img" />{" "}
              </a>

              <a
                href="https://www.linkedin.com/company/cleanapp-ltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img className="social_icons mx-3" src={lnkd} alt="img" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
