import React from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import loginSchema from "../../validation/LoginSchema";
import { useLoginMutation } from "./authApiSlice";
import { setLogin, setUserImage } from "./authSlice";
import {
  profileRoute,
  authStage,
  findCleanersRoute,
  pendingAccountRoute,
  landingPageRoute,
} from "../../constants/constants";
import { isApproved, isContinueBooking } from "../../constants/utils";
import { errorToast, successToast } from "../../toastNotification/toast.jsx";
import { isEmail, isPhone } from "../../validation/contactValidation";

import "./style.css";

const LogIn = () => {
  const initialValues = {
    contact: "",
    password: "",
    role: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stage = queryParams.get("path") || authStage.role;
  const [login, { isLoading }] = useLoginMutation();
  const handleSubmit = async (values) => {
    let requestParams;
    if (stage === authStage.login) {
      if (isEmail(values.contact)) {
        requestParams = { email: values.contact };
      } else if (isPhone(values.contact)) {
        requestParams = { phone: values.contact };
      } else {
        errorToast("Invalid Email or Phone!");
        return;
      }
    }
    if (!values.role) {
      errorToast("Please Select User Role first!");
      return;
    }
    if (stage === authStage.role) {
      navigate(`${landingPageRoute}?form=login&path=${authStage.login}`);
      return;
    }

    try {
      const response = await login({
        ...requestParams,
        password: values.password,
        role: values.role,
      }).unwrap();
      if (response.user) {
        dispatch(setLogin(response));
        dispatch(setUserImage(response.user?.image?.url));
      }
      successToast("Logged In Successfully!");
      if (isContinueBooking()) {
        navigate(findCleanersRoute);
        return;
      }
      if (!isApproved()) {
        navigate(pendingAccountRoute);
        return;
      }
      navigate(profileRoute);
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="signup_main d-flex align-items-center">
      <div className="col-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center signup_sub_main ">
        <div className=" py-4 px-5 login_box_main">
          <h3 className="register_heading text-center mt-3">
            <span
              onClick={() => navigate(`${landingPageRoute}?form=login`)}
              className="selected-auth-heading"
            >
              Login
            </span>
            &nbsp;/&nbsp;
            <span onClick={() => navigate(`${landingPageRoute}?form=signup`)}>
              SignUp
            </span>
          </h3>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={stage === authStage.login && loginSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                {stage === authStage.role && (
                  <Form.Group
                    controlId="formBasicRole"
                    className="mb-3 signup_label_input"
                  >
                    <Form.Select
                      className="input_signup mb-5 mt-2"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                    >
                      <option value="" disabled hidden>
                        Log in as a...
                      </option>
                      <option value="cleaner" className="placeholders_login">
                        Cleaner
                      </option>
                      <option value="customer" className="placeholders_login">
                        Customer
                      </option>
                    </Form.Select>
                  </Form.Group>
                )}
                {stage === authStage.login && (
                  <div>
                    <Form.Group
                      controlId="formBasicContact"
                      className=" signup_label_input"
                    >
                      <Form.Label>Enter email\phone number (E.g. +447123123123)</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact"
                        value={values.contact}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={touched.contact && !!errors.contact}
                        disabled={isLoading}
                        className="input_signup mt-2"
                      />
                      {touched.contact && errors.contact && (
                        <Form.Control.Feedback type="invalid">
                          {errors.contact}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      controlId="formBasicPassword"
                      className="mb-5 signup_label_input"
                    >
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={touched.password && !!errors.password}
                        disabled={isLoading}
                        className="input_signup mt-2"
                      />
                      {touched.password && errors.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn_fgt_pswd"
                        type="button"
                        onClick={() =>
                          navigate(
                            `${landingPageRoute}?form=forget-password-token`
                          )
                        }
                      >
                        Forget password
                      </button>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  <button
                    className="signup_btn_next px-4 mt-4 mb-5"
                    type="submit"
                    disabled={isLoading}
                  >
                    {stage === authStage.role ? "Next" : "Log in"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
