import React from "react";
import "./style.css";

const RecurringDialog = ({ recurring }) => {
  const convertNumbersToDay = (days) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days.map((day) => daysOfWeek[day]);
  };
  return (
    <div className="d-flex justify-content-center">
      {recurring.isRecurring && (
        <div className="recurring_main_dialogue">
          {recurring.monthly.length === 0 ? (
            <div className=" monthly_box_recuring_main">
              <span className="monthy_recurring_title"> Weekly: &nbsp; </span>
              <span className="weeks_recurring">
                {convertNumbersToDay(recurring.weekly).join(" , ")}
              </span>
            </div>
          ) : (
            <div className=" monthly_box_recuring_main ">
              <span className="monthy_recurring_title"> Monthly: &nbsp; </span>
              <span className="months_recurring">
                {recurring.monthly.join(" , ")}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RecurringDialog;
