import React from "react";
import displayimg from "../../../../assets/profile-img.png";
import "./style.css";
import { useSelector } from "react-redux";
import { selectTotalCleaners } from "../createBookingSlice";
import { errorToast } from "../../../../toastNotification/toast";
import { selectSelectedService } from "../../../Services/OurServices/servicesSlice";

const AvailableCleaners = ({
  cleaner,
  bookingDate,
  setSelectedCleaners,
  selectedCleaners,
}) => {
  const allTimes = [
    "4:00 am",
    "5:00 am",
    "6:00 am",
    "7:00 am",
    "8:00 am",
    "9:00 am",
    "10:00 am",
    "11:00 am",
    "12:00 pm",
    "1:00 pm",
    "2:00 pm",
    "3:00 pm",
    "4:00 pm",
    "5:00 pm",
    "6:00 pm",
    "7:00 pm",
    "8:00 pm",
    "9:00 pm",
    "10:00 pm",
    "11:00 pm",
  ];
  const selectedService = useSelector(selectSelectedService);

  const getStartTime = async (time) => {
    const [hours, minutes, period] = time
      .toLowerCase()
      .replace(/[^0-9:ampm]/g, "")
      .split(/:|(?=[apm])/)
      .map((part) => (isNaN(part) ? part : parseInt(part, 10)));
    const adjustedHours =
      period.toLowerCase() === "p" ? (hours % 12) + 12 : hours % 12;

    const startTimeDate = new Date(bookingDate);
    startTimeDate.setHours(adjustedHours, minutes);
    return startTimeDate;
  };
  const totalCleaners = useSelector(selectTotalCleaners);

  const selectedCleaner = selectedCleaners.find(
    (c) => c.cleanerId === cleaner._id
  );
  const isCleanerSelected = !!selectedCleaner;

  const isSelectedTime = (timeValue) => {
    if (!isCleanerSelected || !selectedCleaner.startTimeDate) {
      return false;
    }

    const cleanerStartTime = new Date(selectedCleaner.startTimeDate);
    const cleanerStartTimeFormatted = cleanerStartTime
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();
    return cleanerStartTimeFormatted === timeValue;
  };

  const isSelectedCleaner = (cleanerId) =>
    selectedCleaners.some((c) => c.cleanerId === cleanerId);

  const handleSelect = (cleanerId) => {
    if (selectedCleaners.length >= totalCleaners) {
      errorToast(`Can't Select More then ${totalCleaners} Cleaner`);
      return;
    }
    if (!isSelectedCleaner(cleanerId)) {
      setSelectedCleaners([
        ...selectedCleaners,
        { cleanerId: cleanerId, startTimeDate: null },
      ]);
    }
  };

  const handleDeselect = (cleanerId) => {
    setSelectedCleaners(
      selectedCleaners.filter((c) => c.cleanerId !== cleanerId)
    );
  };
  const handleBookingTime = async (time, cleanerId) => {
    const startTimeDate = await getStartTime(time);
    if (!isSelectedCleaner(cleanerId)) {
      if (selectedCleaners.length >= totalCleaners) {
        errorToast("Can't Select Cleaner");
        return;
      }
      setSelectedCleaners([
        ...selectedCleaners,
        { cleanerId: cleanerId, startTimeDate },
      ]);
    } else {
      if (isCleanerSelected) {
        setSelectedCleaners((selectedCleaners) => [
          ...selectedCleaners.filter((c) => c.cleanerId !== cleanerId),
          {
            cleanerId: cleanerId,
            startTimeDate,
          },
        ]);
      }
    }
  };

  return (
    <div>
      {selectedService && (
        <div className="available_cleaner_detail_box">
          <div className="row d-flex   ">
            <div className="  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 d-flex align-items-center display_img_box ">
              <img
                className="display_img "
                src={cleaner?.image?.url ? cleaner?.image?.url : displayimg}
                alt="img"
              />
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 d-flex align-items-center ac_cleaner_name_card">
              <h4>
                <b>{cleaner.name}</b>
              </h4>
            </div>
            <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
              <div className="d-flex justify-content-center ac_cleaner_price_card ">
                <p className="price_tag">
                  <b>&pound;{selectedService.price}</b>
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="btn_select mt-1"
                  onClick={() => {
                    isSelectedCleaner(cleaner._id)
                      ? handleDeselect(cleaner._id)
                      : handleSelect(cleaner._id);
                  }}
                >
                  {isCleanerSelected ? "Deselect" : "Select"}
                </button>
              </div>
            </div>
          </div>

          <div className="row d-flex">
            <div className="col-12">
              <p className="avail_cleaner">Available time:</p>
            </div>
          </div>

          <div className="row d-flex justify-content-center  my-2">
            <div className="row d-flex justify-content-between">
              {allTimes.map((time, index) => (
                <TimeButton
                  key={index}
                  time={time}
                  onHandleBookingTime={handleBookingTime}
                  isSelected={isSelectedTime(time)}
                  cleanerId={cleaner._id}
                  bookedOn={cleaner.bookedOn}
                  bookingDate={bookingDate}
                  serviceHours={selectedService.hours}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const TimeButton = ({
  time,
  onHandleBookingTime,
  isSelected,
  cleanerId,
  bookedOn,
  bookingDate,
  serviceHours,
}) => {
  const isTimeDisabled = (bookingDate, timeButtonValue, bookedOn) => {
    let [timeString, period] = timeButtonValue.split(" ");
    let [hours, minutes] = timeString
      .split(":")
      .map((num) => parseInt(num, 10));
    if (period.toLowerCase() === "pm" && hours !== 12) hours += 12;
    if (period.toLowerCase() === "am" && hours === 12) hours = 0;

    const selectedDateTime = new Date(bookingDate);
    selectedDateTime.setHours(hours + 1, minutes, 0, 0);
    return bookedOn.some((booking) => {
      const startTime = new Date(booking.startTime);
      const endTime = new Date(booking.endTime);
      endTime.setHours(endTime.getHours() + 1);

      return selectedDateTime >= startTime && selectedDateTime <= endTime;
    });
  };
  const addHoursAndConvert = (timeString, hoursToAdd) => {
    const match = timeString.match(/(\d+):(\d+) (\w+)/);
    const currentHour = parseInt(match[1], 10);
    const currentMinute = parseInt(match[2], 10);
    const currentConvention = match[3];

    let result = [];

    for (let i = 0; i <= hoursToAdd; i++) {
      let resultHour = (currentHour + i) % 12 || 12;
      let resultConvention = currentConvention;

      if (currentHour + i >= 12 && currentHour !== 12) {
        resultConvention = currentConvention === "am" ? "pm" : "am";
      }

      const resultTime = `${resultHour}:${
        currentMinute < 10 ? "0" : ""
      }${currentMinute} ${resultConvention}`;
      result.push(resultTime);
    }

    return result;
  };
  const checkIfAnyTimeDisabled = (bookingDate, endTimes, bookedOn) => {
    const isBookedOn = endTimes.map((endTime, index) => {
      return isTimeDisabled(bookingDate, endTime, bookedOn);
    });
    return isBookedOn.includes(true);
  };

  return (
    <div className={`col-3 ${isSelected ? "selected_time" : ""}`}>
      <div className="d-flex justify-content-center align-items-center">
        <button
          disabled={isTimeDisabled(bookingDate, time, bookedOn)}
          onClick={() => {
            const endTimes = addHoursAndConvert(time, serviceHours - 1);
            if (checkIfAnyTimeDisabled(bookingDate, endTimes, bookedOn)) {
              errorToast("Booking Already Exist in Selected Service Time");
              return;
            }
            onHandleBookingTime(time, cleanerId);
          }}
          className={`${
            isSelected
              ? "display_time_selected"
              : isTimeDisabled(bookingDate, time, bookedOn)
              ? "display_time_disabled"
              : "display_time"
          }`}
        >
          {time}
        </button>
      </div>
    </div>
  );
};

export default AvailableCleaners;
