import React from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { contactSchema } from "../../validation/SignUpSchema";
import { landingPageRoute } from "../../constants/constants";
import { errorToast, successToast } from "../../toastNotification/toast";
import { useForgetPasswordMutation } from "./authApiSlice";
import { isEmail, isPhone } from "../../validation/contactValidation";

const TokenForgetPassword = () => {
  const navigate = useNavigate();
  const [forgetPassword, { isLoading }] = useForgetPasswordMutation();
  const initialValues = {
    contact: "",
  };

  const handleSubmit = async (values, actions) => {
    let requestParams;
    if (isEmail(values.contact)) {
      requestParams = { email: values.contact };
    } else if (isPhone(values.contact)) {
      requestParams = { phone: values.contact };
    } else {
      errorToast("Invalid Email or Phone!");
      return;
    }
    try {
      const response = await forgetPassword({
        ...requestParams,
      }).unwrap();
      successToast("Reset Link Successfully Sent!");
      actions.resetForm();
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div>
      <h3 className="register_heading text-center mt-3">
        <span className="reset_password_heading">Forget Password</span>
      </h3>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={contactSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group
              controlId="formBasicContact"
              className="mb-3 signup_label_input"
            >
              <Form.Label>Enter email\phone number <span className="password_number_settings small">(E.g. +447123123123)</span></Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={values.contact}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.contact && !!errors.contact}
                disabled={isLoading}
                className="input_signup mt-2"
              />
              {touched.contact && errors.contact && (
                <Form.Control.Feedback type="invalid">
                  {errors.contact}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div className="d-flex justify-content-center">
              <button
                className="signup_btn_next px-4 mt-4 mb-5"
                type="submit"
                disabled={isLoading}
              >
                Send Link
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TokenForgetPassword;
