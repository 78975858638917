import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate , Link } from "react-router-dom";
import { createBookingRoute } from "../../constants/constants";
import { setSelectedService } from "../../pages/Services/OurServices/servicesSlice";
import PopUpTerms from "../../components/PopUpTerms";

import "./style.css";

const ServicesCard02 = ({ service }) => {
  const [isShowPopup, setIsShowPopup] = useState("");
  const handleTermsClick = (selectedPopUp) => {
    setIsShowPopup(selectedPopUp);
  };
  const handlePopupClose = () => {
    setIsShowPopup(false);
  };

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelectClick = async (service) => {
    navigate(createBookingRoute);
    dispatch(setSelectedService(service));
  };

  return (
    <div className="service_card_main_02 mt-3 ">
      <div className="service_card_sub_main pt-2">
        <div className="row d-flex ">
          <div className=" col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
            <h5 className=" ps-4 px-1 mt-1 mb-3 card_heading ">
              {service.title}
            </h5>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3  d-flex justify-content-center ">
            <div>
              <button
                className="btn_select"
                onClick={() => handleSelectClick(service)}
              >
                Select
              </button>
            </div>
          </div>
        </div>

        <hr className="hr" />

        <div className="row d-flex">
          <div className="d-flex justify-content-between ">
            <div className="col-6 col-md-6 col-xl-4 mt-3 d-flex justify-content-center">
              <div>
                <div>
                  <p className=" minimum_hours_service_card text-center">
                    Minimum hour
                  </p>
                  <p className=" text-center mt-1 hours">
                    {service.hours} Hours
                  </p>
                </div>

                <div className="mt-2">
                  <p className=" minimum_hours_service_card text-center">
                    Starts from
                  </p>
                  <p className=" hours text-center">&pound;{service.price}</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-6 col-xl-6 mt-3 ">
              <div>
                <p className="minimum_hours_service_card">included tasks</p>

                <div
                  className="d-flex  justify-content-start scrollable-row_2 "
                  ref={containerRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  {service.tasks.map((task, i) => (
                    <div key={i}>
                      <img
                        className="services_icon mx-1"
                        src={task.image.url}
                        alt={task.image.public_id}
                      />
                      <div className="text-center">
                        <span className="icon_title text-center">
                          {task.description}
                        </span>
                      </div>
                    </div>
                  ))}        
                </div>
              </div>
              <div className="d-flex justify-content-center">
              <Link
              className="terms_conditions03"
              onClick={() => handleTermsClick("isShowTerms")}
            >
              Terms &amp; Conditions
              </Link>
              </div>
            {isShowPopup === "isShowTerms" && (
              <PopUpTerms
                show={isShowPopup}
                isTermAndCondition={true}
                onClose={handlePopupClose}
              />
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard02;
