import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { addNewCleanersRoute } from "../../constants/constants";
import AdminCleanerCard from "../AdminCleanerCard";
import { useGetAllUsersQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";

import "./style.css";

const AdminShowCleaners = ({
  isShowToggleButton,
  isShowAddCleanerButton,
  isShowSelection,
  cleanerValues,
  triggerRefetch,
  setCleanerValues,
  onHandleToggleClick,
}) => {
  const { data, refetch, isLoading } = useGetAllUsersQuery({
    role: "cleaner",
    approval: "approved",
  });
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };
  useEffect(() => {
    refetch();
  }, [cleanerValues, triggerRefetch, refetch]);
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div className="container-fluid add_cleaners_main_admin mt-3 ">
      <div className="row d-flex py-3 ">
        <div className="col-6">
          <span>
            <b>Cleaners</b> ({!isLoading && data ? data.length : 0})
          </span>
          {isShowSelection && (
            <>
              &nbsp;&nbsp;&nbsp;
              <select name="#" id="select_cleaners_all">
                <option value="small" selected>
                  All
                </option>
                <option value="medium">Available</option>
                <option value="large">Unavailable</option>
                <option value="extra">onSite</option>
              </select>
            </>
          )}
        </div>
        {isShowAddCleanerButton && (
          <div className="col-6 d-flex justify-content-end">
            <Link className="btn_add_cleaner" to={addNewCleanersRoute}>
              +add cleaner
            </Link>
          </div>
        )}
      </div>

      <div
        className="today_cleaners_main"
        ref={todayBookingsRef}
        onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
        onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {isLoading ? (
          <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
        ) : data ? (
          data.map((cleaner) => (
            <AdminCleanerCard
              isShowToggleButton={isShowToggleButton}
              cleaner={cleaner}
              cleanerValues={cleanerValues}
              setCleanerValues={setCleanerValues}
              onHandleToggleClick={onHandleToggleClick}
              key={cleaner._id}
            />
          ))
        ) : (
          <p>No Cleaners Found!</p>
        )}
      </div>
    </div>
  );
};

export default AdminShowCleaners;
