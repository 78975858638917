import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { setSelectedService } from "../../pages/Services/OurServices/servicesSlice";
import { createBookingRoute } from "../../constants/constants";
import PopUpTerms from "../PopUpTerms";

import "./style.css";

const ServiceCard = ({ service, showButton }) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const [isShowPopup, setIsShowPopup] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelectClick = async () => {
    navigate(createBookingRoute);
    dispatch(setSelectedService(service));
  };
  const handleTermsClick = (selectedPopUp) => {
    setIsShowPopup(selectedPopUp);
  };
  const handlePopupClose = () => {
    setIsShowPopup(false);
  };

  return (
    <div className="service_card_main ">
      <div className="service_card_sub_main07 box_service_main_card pt-2">
        <h5 className="text-center px-1 mt-1 mb-3 card_heading ">
          {service.title}
        </h5>
        <div className="d-flex justify-content-center ">
          <div className="col-6 col-md-6 col-xl-6 mt-3 d-flex justify-content-center">
            <div>
              <p className=" text-center">Minimum hour</p>
              <p className=" text-center mt-1 hours">{service.hours} Hours</p>
            </div>
          </div>
          <div className="col-6 col-md-6 col-xl-6 mt-3 d-flex justify-content-center">
            <div>
              <p className=" text-center">Starts from</p>
              <p className=" text-center hours">&#163;{service.price}</p>
            </div>
          </div>
        </div>
        <hr className="hr" />

        <div className="col-12 col-md-12 col-xl-12"></div>

        <div className="col-6 col-md-6 col-xl-6 d-flex justify-content-center">
          <p>Included tasks</p>
        </div>

        <div className="col-12 col-md-12 col-xl-12 d-flex flex-wrap justify-content-start pt-3 pb-4 px-2">
          <div
            ref={containerRef}
            className="scrollable-row"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {service.tasks.map((task, i) => (
              <div className="px-1" key={i}>
                <img
                  className="services_icon"
                  src={task.image.url}
                  alt={task.image.public_id}
                />
                <div className="text-center">
                  <span className="icon_title text-center">
                    {task.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {showButton ? (
          <div className="row d-flex pb-3 pt-5">
            <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 d-flex justify-content-center align-items-center">
              <Link
                className="terms_conditions"
                onClick={() => handleTermsClick("isShowTerms")}
              >
                Terms &amp; Conditions
              </Link>
            </div>
            <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 d-flex justify-content-center box_select_button_main ">
              <button
                className="select_button "
                onClick={() => handleTermsClick("isShowSelect")}
              >
                Select
              </button>
            </div>
          </div>
        ) : (
          <div className="row d-flex pb-3 pt-5">
            <div className="col-12 col-md-12 col-xl-12 d-flex justify-content-center align-items-center">
              <Link
                className="terms_conditions"
                onClick={() => handleTermsClick("isShowTerms")}
              >
                Terms &amp; Conditions
              </Link>
            </div>
          </div>
        )}

        {isShowPopup === "isShowTerms" && (
          <PopUpTerms
            show={isShowPopup}
            isTermAndCondition={true}
            onClose={handlePopupClose}
          />
        )}
        {isShowPopup === "isShowSelect" && (
          <PopUpTerms
            show={isShowPopup}
            isTermAndCondition={false}
            onConfirm={handleSelectClick}
            onClose={handlePopupClose}
          />
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
