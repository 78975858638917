import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import {
  resetCreateBooking,
  selectLocation,
  selectCleaningProducts,
  selectTotalCleaners,
} from "../createBookingSlice";
import {
  resetSelectedService,
  selectSelectedService,
} from "../../../Services/OurServices/servicesSlice";

import {
  bookingOptionsRoute,
  loginRoute,
} from "../../../../constants/constants";
import { useGetAllCleanersQuery } from "./findCleanersApiSlice";
import { errorToast, successToast } from "../../../../toastNotification/toast";
import AvailableCleaners from "../AvailableCleaners";
import { useCreateBookingMutation } from "../createBookingApiSlice";
import CustomCalendar from "../../../../components/CustomCalendar";
import { isLogin } from "../../../../constants/utils";

import locationIcon from "../../../../assets/icons/Path-181.png";

import "./style.css";

const MyCalendar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useSelector(selectLocation);
  const [createBooking, { isLoading: isCreateBookingLoading }] =
    useCreateBookingMutation();
  const { data, refetch, isLoading } = useGetAllCleanersQuery({
    distance: 10,
    coordinates: location.coordinates,
    unit: "mi",
  });
  const availableCleaners = data;
  const selectedService = useSelector(selectSelectedService);
  const cleaningProducts = useSelector(selectCleaningProducts);
  const totalCleaners = useSelector(selectTotalCleaners);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const isSameDate = (userSelectedDate) => {
    const userDate = new Date(userSelectedDate);
    userDate.setHours(0, 0, 0, 0);

    const futureDate = new Date();
    futureDate.setHours(0, 0, 0, 0);

    const isSameDate =
      userDate.getFullYear() === futureDate.getFullYear() &&
      userDate.getMonth() === futureDate.getMonth() &&
      userDate.getDate() === futureDate.getDate();
    return isSameDate;
  };
  const isFutureDate = (date) => {
    const futureDate = new Date();
    futureDate.setHours(0, 0, 0, 0);
    const bookingDate = new Date(date);
    bookingDate.setHours(0, 0, 0, 0);
    if (bookingDate < futureDate) {
      return false;
    }
    const hoursCheck = new Date();
    if (isSameDate(date) && hoursCheck.getHours() > 16) {
      return false;
    }
    return true;
  };

  const handleBookClick = async () => {
    if (!isLogin()) {
      navigate(loginRoute);
      return;
    }
    if (selectedCleaners.length === Number(totalCleaners) && totalCleaners) {
      const hasNullStartTimeDate = selectedCleaners.some(
        (cleaner) => cleaner.startTimeDate === null
      );
      if (hasNullStartTimeDate) {
        errorToast("Please Select Booking Time!");
        return;
      }
      try {
        const values = {};
        values.serviceId = selectedService._id;
        values.cleaners = selectedCleaners;
        values.location = location;
        values.cleaningProducts = cleaningProducts;
        values.hours = selectedService.hours;
        values.totalPrice = cleaningProducts
          ? selectedService.price + 5
          : selectedService.price;
        const allBookingsAt18 = values.cleaners.every((cleaner) => {
          const bookingStartTime = new Date(cleaner.startTimeDate);
          return (
            bookingStartTime.getHours() === 20 && selectedService.hours === 2
          );
        });
        if (allBookingsAt18) {
          let discountAmount = (values.totalPrice * 17.35) / 100;
          values.totalPrice -= discountAmount;
          values.totalPrice = Math.round(values.totalPrice);
        }
        const response = await createBooking(values).unwrap();
        successToast("Booking has been created successfully!");
        dispatch(resetCreateBooking());
        dispatch(resetSelectedService());
        navigate(bookingOptionsRoute);
      } catch (error) {
        errorToast(error.message);
      }
    } else if (Number(totalCleaners) > availableCleaners.length) {
      errorToast(
        "Please step back and choose fewer cleaners to match the limited availability."
      );
    } else {
      errorToast(
        `${
          totalCleaners > 1
            ? `Ensure to select the required ${totalCleaners} cleaners.`
            : "Ensure to select the required cleaner."
        }`
      );
      return;
    }
  };
  useEffect(() => {
    const futureDate = new Date(bookingDate);
    if (isSameDate(futureDate) && futureDate.getHours() > 16) {
      setBookingDate(new Date(futureDate.setDate(futureDate.getDate() + 1)));
    }
  }, []);
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 find_box_cleaner_main  ">
      <div
        className=" calendar_compt_box your_services_main_find_cleaner mt-4 "
        style={{ backgroundColor: "white" }}
      >
        <div className="row d-flex">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 booking_appointment_heading">
            <h4 className="book_appointent_head">
              <b>Book an Appointment</b>
            </h4>
          </div>
        </div>
        <div className="row d-flex mt-2">
          <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
            <span className="location">
              <img
                className="Icon_location"
                src={locationIcon}
                alt="here is an img"
              />
              &nbsp;
              <span className="location_user">{location.address_1}</span>
            </span>
          </div>
          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex btn_book_box_cleaner justify-content-end align-items-center">
            <button className="btn_book_calendar" onClick={handleBookClick}>
              Book
            </button>
          </div>
        </div>
        <hr className="first_hr"></hr>
        <div className="row d-flex">
          <CustomCalendar
            setBookingDate={setBookingDate}
            bookingDate={bookingDate}
            setSelectedCleaners={setSelectedCleaners}
            isFutureDate={isFutureDate}
            refetchCleaners={refetch}
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h4 className="availability_heading_cleaner">
            <b>Available Cleaners</b>
          </h4>
        </div>

        <div className="row available_cleaners ">
          <div
            ref={containerRef}
            className="scrollable-row"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {isLoading ? (
              <div className="row d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center">
                  <Spinner />
                </div>
              </div>
            ) : availableCleaners ? (
              availableCleaners.map((cleaner) => (
                <div key={cleaner._id} className="col-5 box">
                  <AvailableCleaners
                    cleaner={cleaner}
                    bookingDate={bookingDate}
                    setSelectedCleaners={setSelectedCleaners}
                    selectedCleaners={selectedCleaners}
                  />
                </div>
              ))
            ) : (
              <p>No Cleaners Found!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCalendar;
