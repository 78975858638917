export const auth = {
  accessToken: "accessToken",
  userRole: "userRole",
  profileStatus: "profileStatus",
  userEmail: "userEmail",
  userPhone: "userPhone",
  userImage: "userImage",
};

export const authFields = {
  signUpContact: "signUpContact",
  isValidOtp: "isValidOtp",
};
export const authStage = {
  role: "inputRole",
  contact: "inputContact",
  updateContact: "inputUpdatedContact",
  otp: "inputOtp",
  password: "inputPassword",
  login: "inputLogin",
};

export const servicesConst = {
  selectedService: "selectedService",
};

export const createBookingFields = {
  coordinates: "coordinates",
  address_1: "address_1",
  address_2: "address_2",
  postalCode: "postalCode",
  totalCleaners: "totalCleaners",
  cleaningProducts: "cleaningProducts",
};
export const notificationCount = "notificationCount";

export const loginRoute = "/login";
export const loginHomeRoute = "/loginHome";
export const SignUpHomeRoute = "/SignUpHome";
export const landingPageRoute = "/";
export const signUpRoute = "/signup";
export const servicesRoute = "/services";
export const createBookingRoute = "/createBooking";
export const bookingRoute = "/booking";
export const featureRoute = "/feature";
export const aboutUsRoute = "/about";
export const contactUsRoute = "/contact";
export const profileRoute = "/profile";
export const settingRoute = "/settings";
export const resetPasswordRoute = "/resetPassword";
export const removeEmailRoute = "/removeEmail";
export const removePhoneRoute = "/removePhoneNumber";
export const deleteAccountRoute = "/deleteAccount";
export const enterAddressRoute = "/enterAddress";
export const selectCleanersRoute = "/selectCleaners";
export const cleaningProductsRoute = "/cleaningProducts";
export const findCleanersRoute = "/findCleaners";
export const notificationRoute = "/notifications";
export const customerNotificationRoute = "/customerNotifications";
export const pastBookingsRoute = "/pastBookings";
export const myBookingsRoute = "/myBookings";
export const homeCleanerRoute = "/homeCleaner";
export const bookingOptionsRoute = "/bookingOptions";
export const allowLocationRoute = "/location";
export const bookingSummaryRoute = "/bookingSummary";
export const adminDashboardRoute = "/admin";
export const adminActivitiesRoute = "/activities";
export const homeRoute = "/home";
export const upComingBookingRoute = "/upComingBooking";
export const addNewCleanersRoute = "/addNewCleaners";
export const addedCleanersRoute = "/addCleaners";
export const adminNotificationsRoute = "/adminNotifications";
export const adminManageCleanersRoute = "/manageCleaners";
export const adminManageServicesRoute = "/manageServices";
export const adminAppointCleanerRoute = "/appointCleaner";
export const NewPasswordRoute = "/newPassword";
export const adminLoginRoute = "/adminLoginCleanAppLtd";
export const pendingAccountRoute = "/pendingAccount";

export const apiRoutes = {
  forgetPassword: "users/forgotPassword",
  sendOtp: "users/sendOtp",
  verifyOtp: "users/verifyOtp",
  signup: "users/register",
  login: "users/login",
  updateEmailPhone: "users/updateEmailPhone",
  removeEmailPhone: "users/deleteEmailPhone",
  deleteAccount: "users/deleteMe",
  getUser: "users/getUser",
  getAllCleaners: "users/getCleanersWithin",
  updateMe: "users/updateMe",
  getAllUsers: "users/getAllUsers",
  createCleanerAdmin: "users/createCleanerAdmin",
  updateUser: "users/updateUser",
  deleteUser: "users/deleteUser",
  contactUs: "users/contactUs",
  approveCleaner: "users/approveCleaner",
  checkResetPasswordToken: "users/checkResetPasswordToken",
  resetPassword: "users/resetPassword",

  getAllServices: "services/getAllServices",
  updateService: "services/updateService",
  getService: "services/getService",
  deleteService: "services/deleteService",
  createService: "services/createService",

  createBooking: "bookings/createBooking",
  getBookingSummary: "bookings/getBookingSummary",
  getCheckoutSession: "bookings/getCheckoutSession",
  getUserBookings: "bookings/getUserBookings",
  deleteBooking: "bookings/deleteBooking",
  getBooking: "bookings/getBooking",
  updateBooking: "bookings/updateBooking",
  getSpecificBookings: "bookings/getSpecificBookings",
  updateBookingConfirmation: "bookings/bookingConfirmation",
  updateArrivedAtLocation: "bookings/arrivedAtLocation",
  updateCompleteBooking: "bookings/completeBooking",
  getTotalBookingsInPeriod: "bookings/getTotalBookingInPeriod",
  getAllBookings: "bookings/getAllBookings",
  getBookingsChartData: "bookings/getBookingsChartData",
  appointCleaner: "bookings/assignNewCleaner",
  confirmClientPayment: "bookings/confirmClientPayment",

  getAllNotifications: "notifications/getAllNotifications",
  updateNotification: "notifications/updateNotification",
  getNotification: "notifications/getNotification",
  getUnreadNotificationsCount: "notifications/getUnreadNotificationsCount",

  geoCodingApi: "https://maps.googleapis.com/maps/api/geocode/json",
};

export const presetTaskOptions = ["Bathroom", "Linen", "General", "Kitchen"];
