import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { useGetBookingQuery } from "../../pages/Booking/bookingApiSlice";
import CleanerBookingCard from "../CleanerBookingCard";

import "./style.css";

const AppointCleanerDetail = ({
  triggerRefetch,
  setBookingConfirmation,
  bookingConfirmation,
}) => {
  const { bookingId } = useParams();
  const { data, refetch, isLoading } = useGetBookingQuery(bookingId);
  useEffect(() => {
    refetch();
  }, [triggerRefetch, refetch]);

  useEffect(() => {
    if (data?.booking)
      if (data.booking.bookingConfirmation === "rejected") {
        setBookingConfirmation({
          prevCleanerId: data.booking.cleaner._id,
          status: data.booking.bookingConfirmation,
        });
      } else {
        setBookingConfirmation({
          cleanerId: data.booking.cleaner._id,
          status: data.booking.bookingConfirmation,
        });
      }
  }, [data, setBookingConfirmation]);
  return (
    <div>
      <div className="row d-flex admin_appoint_details mt-4 mx-3">
        {isLoading ? (
          <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
        ) : data?.booking ? (
          <div className="col-12">
            <CleanerBookingCard booking={data.booking} />
            {data.booking.bookingConfirmation === "rejected" ? (
              <div className="d-flex justify-content-center">
                <p className="appointing_cleaner">Please Appoint a Cleaner!</p>
              </div>
            ) : data.booking.bookingConfirmation === "pending" ? (
              <div className="d-flex justify-content-center">
                <p className="appointing_cleaner">Cleaner Appointed!</p>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <p>No booking Selected!</p>
        )}
      </div>
    </div>
  );
};

export default AppointCleanerDetail;
