import { createSlice } from "@reduxjs/toolkit";
import { notificationCount } from "../../constants/constants";

const initialState = {
  notificationCount: localStorage.getItem(notificationCount),
};

export const notificationSlice = createSlice({
  name: "Notification",
  initialState: initialState,
  reducers: {
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
      localStorage.setItem(notificationCount, action.payload);
    },
  },
});

export const selectNotificationCount = (state) =>
  state.notification.notificationCount;
export const { setNotificationCount } = notificationSlice.actions;
export default notificationSlice.reducer;
