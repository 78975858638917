import React, { useState } from "react";
import "./style.css";

const PopUpTerms = ({ show, onClose, onConfirm, isTermAndCondition }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCloseButtonClick = () => {
    if (isChecked) {
      onClose();
      if (!isTermAndCondition) onConfirm();
    }
  };
  const handleCrossButtonClick = () => {
    onClose();
  };

  return (
    <div className={`popup-overlay ${show ? "show" : ""}`}>
      <div
        className="popup-content wider_popup"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="d-flex justify-content-around">
          <h3 className="terms_heading">
            <b>Terms and Conditions</b>
          </h3>
          <button className="cross_button" onClick={handleCrossButtonClick}>
            X
          </button>
        </div>
        <div className="scroll-popup">
          <div>
            <ul>
              <li>
                All cleaners available shall be vetted using all reasonable
                endeavours to an extent that CleanApp considers fit in its sole
                discretion.
              </li>
            </ul>
            <ul>
              <li>
                The amounts payable by the Customer to Cleanapp should be done
                where possible through online payment or, in all other
                instances, by cheque, card or bank transfer.
              </li>
            </ul>
            <ul>
              <li>
                When the Customer cancels a scheduled appointment with less than
                3 hours' notice, the Customer shall pay a flat rate cancellation
                charge of £7.50. Less than two hours' notice will be 80% of the
                scheduled booking amount (maximum of £25). And cancellation
                after the booking time will result in a charging the customer
                the full booking amount (maximum of £40)
              </li>
            </ul>
            <ul>
              <li>
                All cleaners whose services are used by the Customer will be
                covered primarily by the Customer's own insurance policies and
                as a contingency, covered by CleanApp Public Liability policy
                including damage or loss to the Customer on the express
                condition that such loss and/or damage is caused by negligence
                of the cleaner which was introduced by CleanApp. The policy
                covers damage or loss on an indemnity basis provided that the
                damage or loss in each event is greater than £100 and the policy
                does not cover possession or property theft. The policy covers
                damage that the cleaner may cause when carrying out his or her
                duties & is subject to other terms which are available upon
                request.
              </li>
            </ul>
            <ul>
              <li>
                The Customer must give reasonable time to allow CleanApp to put
                right any breach. The ownership and responsibility for the
                return of Customer keys remains between the Customer and the
                cleaner. CleanApp will carry out Reference and ID check
                cleaners. Check that the cleaner is eligible to work legally in
                the UK. Provide the most suitable cleaner to the Customer. When
                requested, arrange for a replacement cleaner if the usual
                cleaner is on holiday, poorly or if the Customer is unhappy with
                the cleaner. We will reasonable endeavour to provide a prompt
                reply service to issues or questions raised by the Customer when
                required. Comply within the terms of the law at all times.
                Provide insurance cover as detailed elsewhere.
              </li>
            </ul>
            <ul>
              <li>
                The Customer Shall: Notify CleanApp of any amendments to the
                times or daily schedules that the introduced cleaner attends.
              </li>
            </ul>
            <ul>
              <li>
                Inform CleanApp regarding the intention to employ a cleanapp
                introduced cleaner or dismiss a cleaner introduced.
              </li>
            </ul>
            <ul>
              <li>
                The Customer agrees not to recommend any CleanApp introduced
                cleaner to anyone else unless the CleanApp service is used.. For
                a period of 18 months following termination, the Customer agrees
                not to use or employ any current or past cleaner introduced by
                CleanApp. Should the client be in breach of this termination
                clause CleanApp shall be entitled to charge introduction fee of
                £1000.
              </li>
            </ul>
          </div>
        </div>
        <label className="mt-3 mb-1">
          <input
            className="mx-1 "
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <b className="terms_checkbox_required">
            I agree to the terms and conditions
          </b>
        </label>
        <div className="d-flex justify-content-center">
          <button
            className="btn_accept_and_continue"
            onClick={handleCloseButtonClick}
          >
            Accept & Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpTerms;
