import React from "react";
import { Link } from "react-router-dom";

import { formatDate, formatTime } from "../../constants/utils";
import { upComingBookingRoute } from "../../constants/constants";
import RecurringDialog from "../RecurringDialog";

import profile from "../../assets/vice-3.png";
import "./style.css";

const CustomerCard = ({ booking }) => {
  return (
    <div className="container-fluid card_main mt-4">
      <div className="row d-flex px-2 pt-2">
        <div className="col-2 d-flex justify-content-center align-items-center">
          <img
            className="display_profile"
            src={
              booking.customer?.image?.url
                ? booking.customer?.image.url
                : profile
            }
            alt="here is an img"
          />
        </div>
        <div className="col-8 d-flex justuify-content-start align-items-center">
          <div>
            <h5 className="card_title">
              <b>{booking.customer.name} </b>
            </h5>
            <p className="card_title">{booking.service.title}</p>
          </div>
        </div>
        <div className="col-2 ">
          <div className=" d-flex justify-content-center">
            <p className="price_tag">
              <b>&pound; {booking.totalPrice}</b>
            </p>
          </div>
          <div className=" d-flex justify-content-center">
            <Link
              className="btn_select_customer"
              to={`${upComingBookingRoute}/${booking._id}`}
            >
              Select
            </Link>
          </div>
        </div>
      </div>
      <div className="conteiner-fluid">
        <div className="row d-flex mt-2">
          <div className="col-8">
            <p className="tasks_heading">Included tasks</p>
            <div className="d-flex justify-content-between">
              {booking.service.tasks.map((task, i) => (
                <div key={i}>
                  <div className="d-flex justify-content-center">
                    <img
                      className="service_images"
                      src={task.image.url}
                      alt={task.image.public_id}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="icon_title">{task.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-4 d-flex justify-content-end align-items-center">
            <div>
              <p className="min_hours">Minimum hours</p>
              <div className="d-flex justify-content-center">
                <p className="hours_detail">{booking.service.hours} Hours</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row d-flex justify-content-center ">
        <div className="col-12 d-flex justify-content-center">
          <span className="anything01">{booking.location.address_1}</span>
        </div>
        <div className="col-6 d-flex">
          {booking.recurring?.isRecurring && (
            <RecurringDialog recurring={booking.recurring} />
          )}
        </div>
      </div>

      <div className="row d-flex justify-content-between mb-1 pt-1">
        <div className="col-6">
          <span className="anything udrl01">
            <b className="start_end_time_cleaner">
              {" "}
              {formatTime(booking.startTime)} - {formatTime(booking.endTime)}{" "}
            </b>
          </span>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end">
            <span className="anything udrl01">
              <b className="start_end_time_cleaner">
                {formatDate(booking.startTime)}
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCard;
