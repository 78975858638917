import React, { useEffect, useState } from "react";

import { useLazyGetBookingQuery } from "../../pages/Booking/bookingApiSlice";
import { formatDate, formatTime } from "../../constants/utils";
import { useDeleteUserMutation } from "../../pages/AdminDashboard/adminDashboardApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";

import userProfile from "../../assets/profile_user.png";
import calendar from "../../assets/icons/calendar.png";
import clock from "../../assets/icons/clock.png";
import locationImg from "../../assets/icons/Path-181.png";
import phone from "../../assets/icons/path-23.png";
import email from "../../assets/icons/mail.png";
import "./style.css";

const CleanerAvailabilityCard = ({
  isShowButton,
  cleanerValues,
  onHandleToggleClick,
}) => {
  const [booking, setBooking] = useState(null);
  const [getBooking, { isLoading }] = useLazyGetBookingQuery();
  const [deleteUser, { isLoading: isLoadingDeleteUser }] =
    useDeleteUserMutation();
  const [isContentDisabled, setIsContentDisabled] = useState(false);
  const [deleteAction, setDeleteAction] = useState("remove");

  const handleButtonClick = () => {
    setIsContentDisabled((prevState) => !prevState);
  };
  useEffect(() => {
    setBooking(null);
    setDeleteAction("remove");
    const fetchData = async (bookingId) => {
      const response = await getBooking(bookingId);
      if (response.data?.status === "success")
        setBooking(response.data.booking);
    };

    if (cleanerValues?.currentBooking) {
      fetchData(cleanerValues.currentBooking);
    }
  }, [cleanerValues]);
  const handleRemoveCleanerClick = async (cleanerId) => {
    try {
      const response = await deleteUser(cleanerId).unwrap();
      if (response.status === "success") {
        successToast("This User is Deleted Successfully!");
        setDeleteAction("deleted");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <div className="container-fluid cleaner_availablity_card_main  p-2 mt-3">
      {!cleanerValues ? (
        <p>Please Select a booking!</p>
      ) : (
        <div>
          <div className="cleaner_availablity_card_sub_main mx-1  px-4 ">
            <div
              className="row d-flex pt-3 "
              style={{
                opacity: cleanerValues.availability === "unAvailable" ? 0.4 : 1,
              }}
            >
              <div className=" col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 d-flex justify-content-center">
                <img
                  className="cleaner_admin_profile_dashboard"
                  src={
                    cleanerValues?.image?.url
                      ? cleanerValues?.image?.url
                      : userProfile
                  }
                  alt="here is am img"
                />
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 d-flex admin_head_cleaner_box align-items-center">
                <div>
                  <h5 className="admin_head_cleaner">
                    <b>{cleanerValues.name}</b>
                  </h5>
                  {cleanerValues.availability === "available" ||
                  cleanerValues.availability === "onSite" ? (
                    <p className="availability_cleaner_dashbaord">Available</p>
                  ) : cleanerValues.availability === "unAvailable" ? (
                    <p className="availability_cleaner_dashbaord01">
                      Unavailable
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                {isShowButton && cleanerValues.availability !== "onSite" ? (
                  <label className="toogle_disable">
                    <input
                      onChange={(e) => {
                        onHandleToggleClick(e, cleanerValues._id);
                      }}
                      type="checkbox"
                      className="toggle-checkbox"
                      checked={cleanerValues.availability === "unAvailable"}
                    />
                    <div className="toggle-switch"></div>
                  </label>
                ) : (
                  ""
                )}
              </div>
              {booking ? (
                <div className="row d-flex mx-1 mt-4">
                  <div className="col-12">
                    <p className="admin_service_title">
                      <b>{booking.service.title}</b>
                    </p>
                    <div className="d-flex justify-content-between flx_block mt-1">
                      <div>
                        <span className="details_cleaner_icon">
                          <img
                            className="details_icon"
                            src={calendar}
                            alt="here is an img"
                          />
                          &nbsp;&nbsp; {formatDate(booking.startTime)}
                        </span>
                      </div>
                      <div>
                        <span className="details_cleaner_icon ">
                          <img
                            className="details_icon"
                            src={clock}
                            alt="here is an img"
                          />
                          &nbsp;{" "}
                          <b className="bold">
                            {" "}
                            {formatTime(booking.startTime)}
                          </b>
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="mt-2">
                        <span className="details_cleaner_icon ">
                          <img
                            className="details_icon01"
                            src={locationImg}
                            alt="here is an icon"
                          />
                          &nbsp; {booking.location.address_1}
                        </span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <span className="onSite_admin">On Site</span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <hr className="hr_card mt-3" />
              <p className="contact_info_admin mx-3">
                <b>Contact Info</b>
              </p>
              {cleanerValues.phone && (
                <div className="mx-4">
                  <span className="phone_mail">
                    <img
                      className="phone_number_admin"
                      src={phone}
                      alt="here is an icon"
                    />
                    &nbsp;&nbsp; {cleanerValues.phone}
                  </span>
                </div>
              )}
              {cleanerValues.email && (
                <div className="mx-4">
                  <span className="phone_mail">
                    <img
                      className="phone_number_admin"
                      src={email}
                      alt="here is an icon"
                    />
                    &nbsp;&nbsp; {cleanerValues.email}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className=" row d-flex justify-content-center">
            {cleanerValues.availability !== "onSite" && (
              <>
                {deleteAction === "confirm" && (
                  <>
                    <div className="col-md-6">
                      <button
                        onClick={() =>
                          handleRemoveCleanerClick(cleanerValues._id)
                        }
                        className="remove_cleaner"
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        onClick={() => {
                          setDeleteAction("remove");
                        }}
                        className="remove_cleaner"
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
                {deleteAction === "remove" && (
                  <button
                    onClick={() => {
                      setDeleteAction("confirm");
                    }}
                    className="remove_cleaner col-12"
                  >
                    Remove cleaner
                  </button>
                )}
                {deleteAction === "deleted" && <p>This Cleaner is Deleted</p>}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CleanerAvailabilityCard;
