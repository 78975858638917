import React from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCleanersRoute } from "../../../../constants/constants";
import addressSchema from "../../../../validation/addressSchema";
import { selectLocation, setLocation } from "../createBookingSlice";
import { errorToast } from "../../../../toastNotification/toast";
import { useLazyGetLatLngQuery } from "../../../../state/api/thirdPartyApiSlice";

import "./style.css";

const Address = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getLatLng] = useLazyGetLatLngQuery();
  const location = useSelector(selectLocation);

  const initialValues = {
    coordinates: location?.coordinates || [0, 0],
    address_1: location?.address_1 || "",
    address_2: location?.address_2 || "",
    postalCode: location?.postalCode || "",
  };
  const handleSubmit = async (values) => {
    try {
      const response = await getLatLng(values.address_2);

      if (response.data.status === "ZERO_RESULTS") {
        errorToast("Please Enter Correct Address");
        return;
      }
      if (response.data.status === "REQUEST_DENIED") {
        errorToast("Google Maps API Error");
        return;
      }
      if (response.data.status === "OK") {
        const { lat, lng } = response.data.results[0].geometry.location;
        values.coordinates = [lng, lat];
      }
      values.address_1 = `${values.address_1}, ${values.address_2}`;
      dispatch(setLocation(values));
      navigate(selectCleanersRoute);
    } catch (error) {
      errorToast("Error in Entering Address");
    }
  };
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 d-flex justify-content-center">
      <div className="your_services_main03 main_box_address "
      style={{backgroundColor : "white"}}
      >
        <div className="container-fluid ">
          <div className="container ">
            <div className="row">
              <div className="col-12">
                <h3 className="enter_address my-3 fs-5">Enter Address</h3>
              </div>
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={addressSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mb-2">
                        <Form.Group controlId="formBasicAddress_1">
                          <Form.Control
                            type="text"
                            name="address_1"
                            placeholder="House No, Building or Apartment Floor"
                            value={values.address_1}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address_1 && !!errors.address_1}
                            className="address_input address_input_1  mt-3  px-3"
                          />
                          {touched.address_1 && errors.address_1 && (
                            <Form.Control.Feedback type="invalid">
                              {errors.address_1}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group controlId="formBasicAddress_1">
                          <Form.Control
                            type="text"
                            name="address_2"
                            placeholder="Area"
                            value={values.address_2}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address_2 && !!errors.address_2}
                            className="address_input address_input_1  mt-3  px-3"
                          />
                          {touched.address_2 && errors.address_2 && (
                            <Form.Control.Feedback type="invalid">
                              {errors.address_2}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-start">
                        <Form.Group controlId="formBasicAddress_1">
                          <Form.Control
                            type="text"
                            name="postalCode"
                            placeholder="Postal Code"
                            value={values.postalCode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={
                              touched.postalCode && !!errors.postalCode
                            }
                            className="address_input address_input_2  mt-3  px-3"
                          />
                          {touched.postalCode && errors.postalCode && (
                            <Form.Control.Feedback type="invalid">
                              {errors.postalCode}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="col-12  d-flex justify-content-center">
                      <div className="col-xs-0 col-sm-0 col-md-0 col-lg-8 col-xl-8"></div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4  login_btn_next_1 d-flex justify-content-center">
                        <button
                          className="login_btn_next px-3 py-1 "
                          type="submit"
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
