import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../pages/Auth/authSlice";
import servicesReducer from "../pages/Services/OurServices/servicesSlice";
import createBookingDetailsReducer from "../pages/Booking/CreateBooking/createBookingSlice";
import { apiSlice } from "./api/apiSlice";
import { thirdPartyApiSlice } from "./api/thirdPartyApiSlice";
import notificationReducer from "../pages/Notification/notificationSlice";
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [thirdPartyApiSlice.reducerPath]: thirdPartyApiSlice.reducer,
    auth: authReducer,
    services: servicesReducer,
    createBooking: createBookingDetailsReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      thirdPartyApiSlice.middleware
    ),
});

export default store;
