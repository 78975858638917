import React, { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import Activity from "../Activity";
import { useGetAllBookingsQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";

const AdminUpcomingBookingCard = () => {
  const { data, isLoading } = useGetAllBookingsQuery({
    body: {
      jobStatus: "pending",
      paid: true,
      bookingConfirmation: "approved",
      recurringPaid: true,
      upcomingBookings: "startTime",
    },
  });
  const upcomingRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div
      ref={upcomingRef}
      onMouseDown={(e) => handleMouseDown(e, upcomingRef)}
      onMouseMove={(e) => handleMouseMove(e, upcomingRef)}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      className="row d-flex upcoming_booking_dashboard"
    >
      <div className="col-12">
        <div className="d-flex justify-content-between pt-2">
          <h3 className="upcoming_booking_heading">
            <b>Upcoming bookings</b>
          </h3>
        </div>
        {!isLoading ? (
          data?.bookings ? (
            data.bookings.map((booking) => (
              <Activity
                booking={booking}
                service={true}
                menuButton={true}
                location={true}
                key={booking._id}
              />
            ))
          ) : (
            <p>No Booking Found!</p>
          )
        ) : (
          <div className="row d-flex justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default AdminUpcomingBookingCard;
