import { apiSlice } from "../../state/api/apiSlice";
import { apiRoutes } from "../../constants/constants";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendOtp: builder.mutation({
      query: (body) => ({
        url: apiRoutes.sendOtp,
        method: "POST",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        message: response.message,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (body) => ({
        url: apiRoutes.verifyOtp,
        method: "POST",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        message: response.message,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    signup: builder.mutation({
      query: (body) => ({
        url: apiRoutes.signup,
        method: "POST",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        token: response.data.token,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    login: builder.mutation({
      query: (body) => ({
        url: apiRoutes.login,
        method: "POST",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        accessToken: response.data.token,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    forgetPassword: builder.mutation({
      query: (body) => ({
        url: apiRoutes.forgetPassword,
        method: "POST",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        message: response.message,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    checkResetPasswordToken: builder.query({
      query: (token) => ({
        url: `${apiRoutes.checkResetPasswordToken}/${token}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        status: response.status,
        message: response.message,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: apiRoutes.resetPassword,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        accessToken: response.data.token,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
  }),
});

export const {
  useSendOtpMutation,
  useVerifyOtpMutation,
  useSignupMutation,
  useLoginMutation,
  useForgetPasswordMutation,
  useCheckResetPasswordTokenQuery,
  useResetPasswordMutation,
} = authApiSlice;
