import * as yup from "yup";
import { isPhone } from "./contactValidation";

const contactSchema = yup.object().shape({
  contact: yup
    .string()
    .required("Required")
    .test(
      "is-valid-contact",
      "Invalid email or phone number",
      (value) => {
        return (
          yup.string().email().isValidSync(value) ||
          isPhone(value)
        );
      }
    ),
});

const otpSchema = yup.object().shape({
  otp: yup
    .string()
    .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
    .required("Required"),
});

const confirmPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Passwords must match"
    )
    .required("Required"),
});
const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
});

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .required("Required")
    .email("Invalid email address"),
});
const phoneSchema = yup.object().shape({
  phone: yup
    .string()
    .required("Required")
    .test(
      "is-valid-contact",
      "Invalid Phone Number",
      (value) => {
        return isPhone(value);
      }
    ),
});

export {
  contactSchema,
  otpSchema,
  confirmPasswordSchema,
  emailSchema,
  passwordSchema,
  phoneSchema,
};
