import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLocation, setLocation } from "../createBookingSlice";
import { useLazyGetAddressFromLatLngQuery } from "../../../../state/api/thirdPartyApiSlice";
import { errorToast, successToast } from "../../../../toastNotification/toast";
import { isLogin, userID } from "../../../../constants/utils";
import { useLazyGetUserQuery } from "../../../YourProfile/profileApiSlice";
import {
  enterAddressRoute,
  selectCleanersRoute,
} from "../../../../constants/constants";

import locationIcon from "../../../../assets/icons/Path 181.svg";
import "./style.css";

const AllowLocation = () => {
  const dispatch = useDispatch();
  const [getUser, { isLoading }] = useLazyGetUserQuery();
  const [userLocation, setUserLocation] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [getAddressFromLatLng] = useLazyGetAddressFromLatLngQuery();
  const prevLocation = useSelector(selectLocation);
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (isLogin()) {
        try {
          const response = await getUser(userID()).unwrap();
          if (response) {
            setUserLocation(response.location);
          }
        } catch (error) {
          errorToast(error.message);
        }
      }
    };
    fetchData();
  }, [getUser]);
  const handleSavedLocation = (address_1) => {
    if (!address_1) {
      return;
    }
    setSelectedLocation(address_1);
    dispatch(setLocation(userLocation));
    setShowNextButton(true);
    successToast(`Location:${address_1} has been Selected`);
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (!("geolocation" in navigator)) {
        reject(new Error("Geolocation is not supported by this browser."));
      }

      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };
  const handleLocationClick = async () => {
    try {
      const position = await getLocation();
      const lng = position.coords.longitude;
      const lat = position.coords.latitude;
      const response = await getAddressFromLatLng({
        lat,
        lng,
      });
      const address_1 = response.data.results[0].formatted_address;
      const location = {
        ...prevLocation,
        coordinates: [lng, lat],
        address_1: address_1,
      };
      dispatch(setLocation(location));
      successToast(`Location:${address_1} has been Selected`);
      setShowNextButton(true);
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 section_select  ">
      <div
        className="your_services_main h-auto"
        style={{ backgroundColor: "white" }}
      >
        <div className="container-fluid ">
          <div className="row d-flex justify-content-center">
            {isLogin() ? (
              <>
                <h3 className="enter_address my-3 fs-5">Choose a location</h3>

                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className=" signup_label_input">
                      <select
                        className="input_signup mb-5  "
                        value={selectedLocation}
                        onChange={(e) => {
                          handleSavedLocation(e.target.value);
                        }}
                      >
                        {selectedLocation === "" && (
                          <option value="" disabled hidden>
                            {!isLoading && userLocation?.address_1
                              ? "Saved Location"
                              : "No Saved Location"}
                          </option>
                        )}
                        {!isLoading && userLocation?.address_1 && (
                          <option value={userLocation.address_1}>
                            {userLocation.address_1}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  d-flex justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="enter_manually"
                        onClick={handleLocationClick}
                      >
                        Allow maps to access location
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12 d-flex justify-content-center align-items-center mt-4">
                <div>
                  <div className="d-flex justify-content-center">
                    <img
                      className="allow_location_icon"
                      src={locationIcon}
                      alt="here is an icon"
                      onClick={handleLocationClick}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="btn_allow_access_location mt-3"
                    onClick={handleLocationClick}
                    >
                      Allow to access location
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="col-12 d-flex justify-content-center pt-5 mt-2 mb-5">
              <Link className="enter_manually" to={enterAddressRoute}>
                Enter address manually
              </Link>
            </div>
            <div className="col-12  d-flex justify-content-center">
              <div className="col-xs-0 col-sm-0 col-md-0 col-lg-8 col-xl-8"></div>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4  login_btn_next_1 d-flex justify-content-center">
                {showNextButton && (
                  <Link
                    className="login_btn_next px-3 py-1 "
                    to={selectCleanersRoute}
                  >
                    Next
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowLocation;
