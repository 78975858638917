import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";

import { useLazyGetBookingsChartDataQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";
import { errorToast } from "../../toastNotification/toast";

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
  interaction: {
    mode: "nearest",
    intersect: false,
  },
  scales: {
    x: {
      type: "category",
      title: {
        display: false,
        text: "Months",
      },
      grid: {
        drawOnChartArea: false,
        color: "#F6F9F8",
      },
      ticks: {
        display: true,
      },
      barPercentage: 0.5,
    },
    y: {
      title: {
        display: false,
        text: "Values",
      },
      ticks: {
        display: false,
      },
      grid: {
        drawOnChartArea: false,
        color: "#F6F9F8",
      },
      borderColor: "transparent",
    },
  },
  intersection: {
    mode: "nearest",
    intersect: false,
  },
  legend: {
    display: false,
  },
  hover: {
    mode: "nearest",
    animationDuration: 0,
  },
};

const MonthlyChart = () => {
  const [selectedValue, setSelectedValue] = useState("week");
  // const { data, isLoading } = useGetBookingsChartDataQuery(selectedValue);
  const [chartData, setChartData] = useState(null);
  const [getBookingChartData, { isLoading, data }] =
    useLazyGetBookingsChartDataQuery();
  const handleSelectChange = async (event) => {
    setSelectedValue(event.target.value);
    try {
      const response = await getBookingChartData(event.target.value);
      setChartDataValues(response.data);
    } catch (error) {
      errorToast(error.message);
    }
  };
  const setChartDataValues = (data) => {
    const dataValues = data.chartData.map((entry) => entry.bookings);

    setChartData({
      labels: data.labels,
      datasets: [
        {
          label: "No of Bookings",
          data: dataValues,
          backgroundColor: [
            "#A080E1",
            "#93449E",
            "#A080E1",
            "#93449E",
            "#A080E1",
            "#93449E",
          ],
          borderColor: "none",
          borderWidth: 0,
        },
      ],
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBookingChartData("week");
        setChartDataValues(response.data);
      } catch (error) {
        errorToast(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const canvasId = "myChart";
    const canvas = document.getElementById(canvasId);
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const chartInstance = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: chartOptions,
      });
      return () => {
        chartInstance.destroy();
      };
    }
  }, [chartData]);

  return (
    <div className="chart_bar">
      <div className=" mx-2 pt-1">
        <div>
          <span>
            <span className="sort_by_heading">Sort by: &nbsp;</span>
            <select
              className="sort_by"
              value={selectedValue}
              onChange={handleSelectChange}
            >
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </span>
        </div>
      </div>
      <div className="pt-4">
        {!isLoading && <canvas id="myChart" width="200" height="170" />}
      </div>
    </div>
  );
};

export default MonthlyChart;
