import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { formatDate, formatTime } from "../../constants/utils";
import { adminAppointCleanerRoute } from "../../constants/constants";
import { useLazyGetBookingQuery } from "../../pages/Booking/bookingApiSlice";
import AdminApproveCleanerCard from "../AdminApproveCleanerCard";

import profileImg from "../../assets/profile_user.png";
import locationImg from "../../assets/icons/Path-181.png";
import calendarImg from "../../assets/icons/calendar.png";
import clockIcon from "../../assets/icons/clock.png";

import { errorToast } from "../../toastNotification/toast";
import { useLazyGetUserQuery } from "../../pages/YourProfile/profileApiSlice";
import { useGetNotificationQuery } from "../../pages/Notification/notificationApiSlice";

import "./style.css";

const CustomerCardNotification = () => {
  const { notificationId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [booking, setBooking] = useState(null);
  const [cleaner, setCleaner] = useState(null);
  const { data, isLoading: isLoadingNotification } =
    useGetNotificationQuery(notificationId);
  const [getBooking] = useLazyGetBookingQuery();
  const [getUser, { isLoading: isLoadingUser }] = useLazyGetUserQuery();
  useEffect(() => {
    if (data?.notification) {
      setNotification(data.notification);
    }
  }, [data]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setBooking(null);
      setCleaner(null);
      try {
        if (notification?.booking) {
          const response = await getBooking(notification.booking).unwrap();
          if (response.status === "success") {
            setBooking(response.booking);
          }
        } else if (notification?.cleaner) {
          const response = await getUser(notification.cleaner).unwrap();
          if (response) {
            setCleaner(response);
          }
        }
        setIsLoading(false);
      } catch (error) {
        errorToast(error.message);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [notification, getBooking, getUser]);
  return (
    <>
      {isLoading ? (
        <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
      ) : booking && notification?.booking ? (
        <div className="container-fluid">
          <div className="row d-flex pt-4">
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex  justify-content-center">
              <img
                className="prof_img"
                src={
                  booking.customer?.image?.url
                    ? booking.customer?.image?.url
                    : profileImg
                }
                alt="here is an img"
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 img_box_customer_detail d-flex align-items-end">
              <div>
                <h3 className="customer_name">
                  <b>{booking.customer.name}</b>
                </h3>
                <p className="title_service_admin">
                  <b>{booking.service.title}</b>
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 d-flex justify-content-center align-items-center">
              <p className="price_admin">
                <b>&pound;{booking.totalPrice}</b>
              </p>
            </div>
          </div>

          <div className="mt-4 mx-3 customer_detail_admin">
            <span className="loction_details">
              <img className="img_loc" src={locationImg} alt="here is an img" />
              &nbsp;&nbsp;{booking.location.address_1}
            </span>
          </div>
          <div className="mt-2 mx-3 customer_detail_admin">
            <span className="loction_details">
              <img className="img_loc" src={calendarImg} alt="here is an img" />
              &nbsp;&nbsp;{formatDate(booking.startTime)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <div className="mt-2 mx-3 customer_detail_admin">
              <span className="loction_details">
                <img
                  className="img_clock"
                  src={clockIcon}
                  alt="here is an img"
                />
                &nbsp;&nbsp;{formatTime(booking.startTime)}
              </span>
            </div>
            <div className="d-flex align-items-end">
              <span className="cleaning_products">
                {booking.cleaningProducts
                  ? "Cleaning products Included"
                  : "Cleaning products not Included"}
              </span>
            </div>
          </div>
          {booking.bookingConfirmation === "rejected" ? (
            <>
              <div className="d-flex justify-content-center button_box">
                <button className="btn_cancel_cleaner" disabled={true}>
                  <b>{booking.cleaner.name} cancelled</b>
                </button>
              </div>

              <div className="d-flex justify-content-center mt-2">
                <Link
                  className="btn_appoint_cleaner"
                  to={`${adminAppointCleanerRoute}/${booking._id}`}
                >
                  Appoint a cleaner
                </Link>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center mt-4 pb-3">
              <p className="btn_appoint_cleaner">
                Cleaner {booking.cleaner.name} Appointed!
              </p>
            </div>
          )}
        </div>
      ) : cleaner && notification.cleaner ? (
        <AdminApproveCleanerCard cleaner={cleaner} setCleaner={setCleaner} />
      ) : (
        <p>Please Select a Notification!</p>
      )}
    </>
  );
};

export default CustomerCardNotification;
