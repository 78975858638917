import * as yup from "yup";
import { isPhone } from "./contactValidation";

const loginSchema = yup.object().shape({
  contact: yup
    .string()
    .required("Required")
    .test("is-valid-contact", "Invalid email or phone number", (value) => {
      return yup.string().email().isValidSync(value) || isPhone(value);
    }),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
});

export default loginSchema;
