import React from "react";
import { useNavigate } from "react-router-dom";

import { formatDate, formatTime } from "../../constants/utils";
import RecurringDialog from "../RecurringDialog";

import calendar from "../../assets/icons/calendar.png";
import location from "../../assets/icons/Path-181.png";
import clock from "../../assets/icons/clock.png";
import "./style.css";

const UpcomingBookingCard = ({ booking, selectedBookingId, navigateRoute }) => {
  const navigate = useNavigate();
  const handleSelectBooking = (bookingId) => {
    navigate(`${navigateRoute}/${bookingId}`);
  };
  return (
    <div className="cleaner_detail">
      <div
        className={`btm_brd my-4 ${
          selectedBookingId === booking._id && "bookingCard-selected"
        }`}
        onClick={() => {
          handleSelectBooking(booking._id);
        }}
      >
        <div className="d-flex justify-content-between align-items-center mx-2 mt-3">
          <div>
            <h3 className=" customer_name_heading">
              <b>{booking.customer.name}</b>
            </h3>
            <p className="title_card">{booking.service.title}</p>
          </div>
          <div>
            <img
              className="location_icon_1"
              src={calendar}
              alt="here is location-img"
            />{" "}
            <span className="anything udrl">
              <b>{formatDate(booking.startTime)}</b>
            </span>
            <br />
            <img
              className="location_icon_1"
              src={clock}
              alt="here is location-img"
            />{" "}
            <span className="anything udrl">
              <b>
                {" "}
                {formatTime(booking.startTime)} - {formatTime(booking.endTime)}{" "}
              </b>
            </span>
          </div>
        </div>

        <div className="loc_detail">
          <img
            className="location_icon"
            src={location}
            alt="here is location-img"
          />{" "}
          <span className="anything01"> {booking.location.address_1} </span>
        </div>
        <div>
          {booking.recurring?.isRecurring && (
            <RecurringDialog recurring={booking.recurring} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingBookingCard;
