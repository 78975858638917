import React from "react";

import location from "../../assets/icons/Path-181.png";
import calendar from "../../assets/icons/calendar.png";
import clock from "../../assets/icons/clock.png";
import displayimg from "../../assets/vice-3.png";
import products from "../../assets/icons/path-960.png";
import cleaner from "../../assets/icons/Path-231.png";
import {
  formatDate,
  formatTime,
} from "../../constants/utils";
import "./style.css";
import RecurringDialog from "../RecurringDialog";

const CleanerBookingCard = ({ booking }) => {
  return (
    <div>
      <div className="container-fluid summary_box ">
        <div className="row d-flex">
          <div className=" col-12 col-md-3 col-lg-2 img_box ">
            <div>
              <img
                className="profile_img03"
                src={
                  booking.customer.image?.url
                    ? booking.customer.image.url
                    : displayimg
                }
                alt="here is am img"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-9  cleaner_name_service  ">
            <div>
            <div>
            <span className="cleaner_name03">
                {booking.customer.name}
                </span>
                </div>
              <div className="service_title_uc_booking">
              <span className="cleaning_title03">
                <b>{booking.service.title}</b>
              </span>
              </div>
            </div>
          </div>
          <div className=" col-12  col-md-3 col-lg-1 d-flex justify-content-center align-items-center">
            <p className="price_tag03">
              <b>&pound;{booking.totalPrice}</b>
            </p>
          </div>
        </div>

        <div className="">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12  pt-3">
              <div>
                <div className="row d-flex  flex-wrap justify-content-between service_icons_main">
                  <div className=" col-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center">
                    <span className="included_tasks_booking_ntf">Included tasks</span>
                  </div>
                  <div className=" col-12 col-md-8 col-lg-8 col-xl-8 d-flex justify-content-around">
                    {booking.service.tasks.map(
                      (task, i) => (
                        <div
                          className="icon_box icon_box_2 "
                          key={i}
                        >
                          <div className="d-flex  justify-content-center">
                            <img
                              className="service_icons"
                              src={task.image.url}
                              alt={task.image.public_id}
                            />
                          </div>
                          <div
                            className="d-flex
                            justify-content-center"
                          >
                            <p className="service_icons_title">
                              {task.description}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-12  pt-3"></div>
            </div>
            <div className="py-2 location_calendar_box">
              <span className="location_detail">
                <img
                  className="location_icon03"
                  src={location}
                  alt="here is location-img"
                />{" "}
                <span className="anything03">
                  {" "}
                  {booking.location.address_1}
                </span>
              </span>
              <br />
            </div>
            <span className="location_detail d-flex justify-content-between">
              <div>
                <img
                  className="location_icon_1"
                  src={clock}
                  alt="here is location-img"
                />{" "}
                <span className="anything udrl">
                  <b className="date_format_ucbooking03">
                    
                    {formatTime(booking.startTime)} -{" "}
                    {formatTime(booking.endTime)}{" "}
                  </b>
                </span>
                <div>
                  <img
                    className="location_icon_1"
                    src={products}
                    alt="here is location-img"
                  />
                  <span className="anything03 sm_font03">
                    {" "}
                    {booking.cleaningProducts
                      ? "Cleaning Products are Included"
                      : "Cleaning Products are not needed"}
                  </span>
                </div>
              </div>

              <div>
                <img
                  className="location_icon_3"
                  src={calendar}
                  alt="here is location-img"
                />{" "}
                <span className="anything udrl">
                  <b className="date_format_ucbooking03">{formatDate(booking.startTime)}</b>
                </span>
                <div>
                  <img
                    className="location_icon_3"
                    src={cleaner}
                    alt="here is location-img"
                  />{" "}
                  <span className="anything sm_font">
                    {" "}
                    {booking.totalCleaners
                      ? booking.totalCleaners
                      : "1 cleaner only"}
                  </span>
                </div>
                <div>
                  {booking.recurring?.isRecurring && (
                    <RecurringDialog
                      recurring={booking.recurring}
                    />
                  )}
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CleanerBookingCard;
