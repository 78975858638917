import React from "react";

import LatestActivityCard from "../../components/LatestActivityCard";
import MonthlyChart from "../../components/Chart";
import TotalBookings from "../../components/TotalNoOfBookings";
import AdminUpcomingBookingCard from "../../components/AdminUpcomingBookingCard";

import "./style.css";

const AdminDashboard = () => {
  return (
    <div className="main_dashboard container-fluid">
      <div className="row d-flex justify-content-between">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <LatestActivityCard />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
          <div className="row d-flex">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <TotalBookings />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <MonthlyChart />
            </div>
          </div>
          <AdminUpcomingBookingCard />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
