import React, { useState, useRef } from "react";
import { Spinner } from "react-bootstrap";

import Activity from "../Activity";
import { useGetAllBookingsQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";

const LatestActivityCard = () => {
  const { data, isLoading } = useGetAllBookingsQuery({
    body: {
      jobStatus: "onSite",
      paid: true,
      bookingConfirmation: "approved",
      recurringPaid: true,
    },
  });
  const activityRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div
      className="activity_main"
      ref={activityRef}
      onMouseDown={(e) => handleMouseDown(e, activityRef)}
      onMouseMove={(e) => handleMouseMove(e, activityRef)}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className="d-flex justify-content-center">
        <h3 className="pt-2 activity_heading">Latest Activity</h3>
      </div>
      {!isLoading ? (
        data?.bookings ? (
          data.bookings.map((booking) => (
            <Activity
              service={true}
              menuButton={false}
              location={false}
              onSite={true}
              booking={booking}
              key={booking._id}
            />
          ))
        ) : (
          <p>No Booking Found!</p>
        )
      ) : (
        <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
      )}
    </div>
  );
};

export default LatestActivityCard;
