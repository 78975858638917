import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useGetUserQuery, useUpdateMeMutation } from "./profileApiSlice";
import { handleImageUpload, userID } from "../../constants/utils";
import { removeEmailRoute, removePhoneRoute } from "../../constants/constants";
import addressSchema from "../../validation/addressSchema";
import { useLazyGetLatLngQuery } from "../../state/api/thirdPartyApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";
import { useDispatch } from "react-redux";
import { setUserImage } from "../Auth/authSlice";

import img from "../../assets/profile_user.png";
import edit from "../../assets/icons/Path-226.png";
import phone from "../../assets/icons/path-23.png";
import email from "../../assets/icons/mail.png";
import address from "../../assets/icons/Path-181.png";
import "./style.css";

const YourProfile = () => {
  const nameSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const dispatch = useDispatch();
  const profileSchema = nameSchema.concat(addressSchema);
  const { data, isLoading } = useGetUserQuery(userID());
  const [getLatLng] = useLazyGetLatLngQuery();
  const profileImageRef = useRef();
  const [isEditName, setIsEditName] = useState(false);
  const [updateMe, { isLoading: isLoadingUpdateUser }] = useUpdateMeMutation();
  const [initialValues, setInitialValues] = useState({
    address_1: "",
    address_2: "",
    postalCode: "",
    name: "",
    email: "",
    phone: "",
    image: "",
  });
  useEffect(() => {
    if (!isLoading && data) {
      setInitialValues({
        address_1: data.location?.address_1 || "",
        address_2: data.location?.address_2 || "",
        name: data.name || "",
        email: data.email || "",
        phone: data.phone || "",
        postalCode: data.location?.postalCode || "",
        image: data.image?.url || "",
      });
    }
  }, [isLoading, data]);

  const findCoordinates = async (address) => {
    const response = await getLatLng(address);

    if (response.data.status === "ZERO_RESULTS") {
      throw new Error("Please Enter Correct Area or City");
    }
    if (response.data.status === "REQUEST_DENIED") {
      throw new Error("Google Maps API Error");
    }
    if (response.data.status === "OK") {
      const { lat, lng } = response.data.results[0].geometry.location;
      return [lng, lat];
    }
  };
  const handleNameButton = (values) => {
    if (!values.name) return;
    setIsEditName(() => !isEditName);
  };

  const handleSubmit = async (values) => {
    try {
      setIsEditName(false);
      const coordinates = await findCoordinates(values.address_2);
      values.location = {
        coordinates: coordinates,
        address_1: values.address_1,
        address_2: values.address_2,
        postalCode: values.postalCode,
      };
      const response = await updateMe({
        userId: userID(),
        body: values,
      }).unwrap();
      if (response.status === "success") {
        successToast("User profile has been Updated!");
        dispatch(setUserImage(response.user?.image?.url));
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div>
      <div className="container-fluid main_container">
        <div className="main">
          <h2 className="heading_setup">
            <b>Your Profile</b>
          </h2>
          <div className="">
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={profileSchema}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row   sub_main">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <Form.Group controlId="formBasicImage">
                        <div className="d-flex justify-content-center">
                          <img
                            className="prf_img mt-4"
                            src={values.image ? values.image : img}
                            alt="here is an img"
                          />
                        </div>
                        <Form.Group controlId="formFile" className="mb-2">
                          <Form.Control
                            type="file"
                            name="image"
                            ref={profileImageRef}
                            onChange={(e) =>
                              handleImageUpload(e, setFieldValue, "image")
                            }
                            hidden={true}
                          />
                        </Form.Group>
                        <p
                          className="text-center profile_heading"
                          onClick={() => {
                            profileImageRef.current.click();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Edit Photo
                        </p>
                      </Form.Group>
                      <Form.Group controlId="formBasicName">
                        <div className=" edit d-flex justify-content-center">
                          {!isEditName ? (
                            <h2 className="px-3  pb-2 name_heading ">
                              {values.name}
                            </h2>
                          ) : (
                            <div>
                              <Form.Control
                                type="text"
                                className="input-field"
                                name="name"
                                placeholder="Enter Name"
                                value={values.name}
                                onBlur={handleBlur}
                                maxLength="40"
                                onChange={handleChange}
                                isInvalid={touched.name && !!errors.name}
                                disabled={isLoading || isLoadingUpdateUser}
                              />
                              {touched.name && errors.name && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                </Form.Control.Feedback>
                              )}
                            </div>
                          )}
                          <div
                            className="pt-2 edit_btn_icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleNameButton(values)}
                          >
                            <img
                              className="edit_icon"
                              src={edit}
                              alt="here is am img"
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 ">
                      <div className="row  f_row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row_phone_nmbr ">
                          <Form.Group controlId="formBasicPhone">
                            <div>
                              <div className="d-flex justify-content-between">
                                <Form.Label className="mt-1 d-flex justify-content-center align-items-end ">
                                  Phone number
                                </Form.Label>
                                <Link
                                  className="btn_edit"
                                  to={removePhoneRoute}
                                >
                                  Edit
                                </Link>
                              </div>
                              <div className="input-container">
                                <div className="icon">
                                  <img
                                    className="prf_img_1"
                                    src={phone}
                                    alt="here is an img"
                                  />
                                </div>
                                <Form.Control
                                  type="text"
                                  className="input-field nmbr_field"
                                  placeholder="Add Phone (E.g. +447123123123)"
                                  value={values.phone}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row_phone_nmbr ">
                          <Form.Group controlId="formBasicEmail">
                            <div>
                              <div className="d-flex justify-content-between">
                                <Form.Label className="mt-1">Email</Form.Label>
                                <Link
                                  className="btn_edit"
                                  to={removeEmailRoute}
                                >
                                  Edit
                                </Link>
                              </div>
                              <div className="input-container">
                                <div className="icon">
                                  <img
                                    className="prf_img_1"
                                    src={email}
                                    alt="here is an img"
                                  />
                                </div>
                                <Form.Control
                                  type="text"
                                  className="input-field nmbr_field"
                                  placeholder="Add Email"
                                  value={values.email}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row d-flex">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="pt-4">
                              Default Address
                            </Form.Label>
                            <div className="input-container mt-1 ">
                              <div className="icon">
                                <img
                                  className="prf_img_2"
                                  src={address}
                                  alt="here is an img"
                                />
                              </div>
                              <Form.Control
                                type="text"
                                className="input-field"
                                name="address_1"
                                placeholder="House No, Apartment, Building "
                                value={values.address_1}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength="40"
                                isInvalid={
                                  touched.address_1 && !!errors.address_1
                                }
                                disabled={isLoading || isLoadingUpdateUser}
                              />
                              {touched.address_1 && errors.address_1 && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.address_1}
                                </Form.Control.Feedback>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row d-flex">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                          <Form.Group controlId="formBasicEmail">
                            <div className="input-container mt-2">
                              <div className="icon">
                                <img
                                  className="prf_img_2"
                                  src={address}
                                  alt="here is an img"
                                />
                              </div>
                              <Form.Control
                                type="text"
                                className="input-field"
                                placeholder="Area,City"
                                value={values.address_2}
                                name="address_2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength="40"
                                isInvalid={
                                  touched.address_2 && !!errors.address_2
                                }
                                disabled={isLoading || isLoadingUpdateUser}
                              />
                              {touched.address_2 && errors.address_2 && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.address_2}
                                </Form.Control.Feedback>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-16 col-lg-3 col-xl-3 d-flex justify-content-center">
                          <Form.Group controlId="formBasicEmail">
                            <div className="input-container mt-2">
                              <Form.Control
                                type="text"
                                name="postalCode"
                                className="input-field-1"
                                placeholder="Post code"
                                value={values.postalCode}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength="40"
                                isInvalid={
                                  touched.postalCode && !!errors.postalCode
                                }
                                disabled={isLoading || isLoadingUpdateUser}
                              />
                              {touched.postalCode && errors.postalCode && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.postalCode}
                                </Form.Control.Feedback>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid ">
                      <div className="row profile_submit_btn_box ">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center">
                          <div className="d-flex justify-content-center">
                            <button className="save_btn" type="submit">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourProfile;
