import React from "react";
import { Outlet } from "react-router-dom";
import ServiceCard from "../../../components/ServiceCard";
import { useSelector } from "react-redux";
import { selectSelectedService } from "../../Services/OurServices/servicesSlice";
import "./style.css";

const CreateBooking = () => {
  const service = useSelector(selectSelectedService);

  return (
    <div 
    style={{minHeight : '90vh'}}
    >
      <div
        className="container-fluid  your_service_main01 mt-3 choose_location_main min-height-100vh"
        style={{ backgroundColor: "#F8F9FA",  }}
      >
        <div className="row d-flex">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 service_card_loged_in ">
            <div>
              <h1 className="text-center mt-5 mb-4  your_service_heading">
                <b>Your Service</b>
              </h1>
            </div>
            <div className="service-card-center">
              {service && <ServiceCard service={service} showButton={false} />}
            </div>
          </div>

          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CreateBooking;
