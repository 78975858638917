import React from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import loginSchema from "../../validation/LoginSchema";
import { useLoginMutation } from "./authApiSlice";
import { setLogin, setUserImage } from "./authSlice";
import { adminDashboardRoute } from "../../constants/constants";
import { errorToast, successToast } from "../../toastNotification/toast.jsx";
import { isEmail, isPhone } from "../../validation/contactValidation";
import "./style.css";

const LogIn = () => {
  const initialValues = {
    contact: "",
    password: "",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const handleSubmit = async (values) => {
    let requestParams;
    if (isEmail(values.contact)) {
      requestParams = { email: values.contact };
    } else if (isPhone(values.contact)) {
      requestParams = { phone: values.contact };
    } else {
      errorToast("Invalid Email or Phone!");
      return;
    }

    try {
      const response = await login({
        ...requestParams,
        password: values.password,
        role: "admin",
      }).unwrap();
      if (response.user) {
        dispatch(setLogin(response));
        dispatch(setUserImage(response.user?.image?.url));
      }
      successToast("Logged In Successfully!");
      navigate(adminDashboardRoute);
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="signup_main">
      <div className="col-12 colmd-12 col-lg-12 d-flex justify-content-center align-items-center signup_sub_main ">
        <div className=" py-5 px-5">
          <h3 className="register_heading text-center mt-3">
            <b className = "admin_login_heading">Admin Login</b>
          </h3>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={loginSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  controlId="formBasicContact"
                  className=" signup_label_input"
                >
                  <Form.Label>Enter email\phone number</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact"
                    value={values.contact}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.contact && !!errors.contact}
                    disabled={isLoading}
                    className="input_signup mt-2"
                  />
                  {touched.contact && errors.contact && (
                    <Form.Control.Feedback type="invalid">
                      {errors.contact}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group
                  controlId="formBasicPassword"
                  className="mb-5 signup_label_input"
                >
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                    disabled={isLoading}
                    className="input_signup mt-2"
                  />
                  {touched.password && errors.password && (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <div className="d-flex justify-content-center">
                  <button
                    className="signup_btn_next px-3 mt-4 mb-5"
                    type="submit"
                    disabled={isLoading}
                  >
                    Login
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
