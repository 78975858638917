import { apiSlice } from "../../state/api/apiSlice";
import { apiRoutes } from "../../constants/constants";

export const bookingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBookingSummary: builder.query({
      query: () => ({
        url: apiRoutes.getBookingSummary,
        method: "GET",
      }),
      transformResponse: (response) => response.data.bookings,
    }),
    getCheckoutSession: builder.query({
      query: (bookingId) => ({
        url: `${apiRoutes.getCheckoutSession}/${bookingId}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.session.url,
    }),
    getUserBooking: builder.query({
      query: (body) => ({
        url: apiRoutes.getUserBookings,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => response.data.bookings,
    }),
    deleteBooking: builder.query({
      query: (bookingId) => ({
        url: `${apiRoutes.deleteBooking}/${bookingId}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response,
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),

    getBooking: builder.query({
      query: (bookingId) => ({
        url: `${apiRoutes.getBooking}/${bookingId}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        status: response.status,
        booking: response.data.booking,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),

    updateBooking: builder.mutation({
      query: ({ bookingId, body }) => ({
        url: `${apiRoutes.updateBooking}/${bookingId}`,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        booking: response.data,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    updateBookingConfirmation: builder.mutation({
      query: (body) => ({
        url: apiRoutes.updateBookingConfirmation,
        method: "PATCH",
        body: { ...body },
      }),

      transformResponse: (response) => ({
        status: response.status,
        booking: response.data.booking,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    updateArrivedAtLocation: builder.mutation({
      query: (body) => ({
        url: apiRoutes.updateArrivedAtLocation,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        booking: response.data.booking,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    updateCompleteBooking: builder.mutation({
      query: (body) => ({
        url: apiRoutes.updateCompleteBooking,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        booking: response.data.booking,
      }),

      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
  }),
});

export const {
  useGetBookingSummaryQuery,
  useLazyGetCheckoutSessionQuery,
  useGetUserBookingQuery,
  useLazyDeleteBookingQuery,
  useGetBookingQuery,
  useUpdateBookingMutation,
  useUpdateBookingConfirmationMutation,
  useUpdateArrivedAtLocationMutation,
  useUpdateCompleteBookingMutation,
  useLazyGetBookingQuery,
} = bookingApiSlice;
