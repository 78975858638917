import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { adminNotificationsRoute } from "../../constants/constants";

import "./style.css";

const NotificationDashboard = ({ onHandleViewNotification, notification }) => {
  const { notificationId } = useParams();

  const navigate = useNavigate();
  const isChecked = (currentNotification) => {
    return notificationId === currentNotification;
  };

  return (
    <div>
      <div className="notifications-container">
        <label
          className={`custom-checkbox my-4 ${
            isChecked(notification._id) ? "checked" : ""
          }`}
          onClick={() => onHandleViewNotification(notification._id)}
        >
          <input
            type="checkbox"
            checked={isChecked(notification._id)}
            onChange={() => {
              navigate(`${adminNotificationsRoute}/${notification._id}`);
            }}
          />
          <span className="checkbox-square"></span>
          <div className="checkbox-label-main">
            <div className="d-flex justify-content-between notf_box py-2 ">
              <p className="notf_cancel notification_parg_unread">
                {notification.description}
              </p>
              {notification.title === "bookingRejected" && (
                <p className="appoint_cleaner">Appoint Another Cleaner</p>
              )}
              {notification.title === "approveCleaner" && (
                <p className="appoint_cleaner">Review Cleaner Account!</p>
              )}
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default NotificationDashboard;
