import * as yup from "yup";

const servicesSchema = yup.object().shape({
  title: yup.string().required("Title Required!"),
  price: yup.string().required("Price Required!"),
  hours: yup
    .string()
    .required("Hours Required!")
    .matches(/^[0-9]+$/, "Hours must be a Number"),
  tasks: yup.array().of(
    yup.object({
      description: yup.string().required("Task description is required"),
      image: yup.mixed().required("Task Image is required"),
    })
  ),
});

export default servicesSchema;
