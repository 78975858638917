import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { useLazyGetBookingQuery } from "../Booking/bookingApiSlice";
import { useConfirmClientPaymentMutation } from "./customerNotification";
import { errorToast, successToast } from "../../toastNotification/toast";

import CleanerBookingCard from "../../components/CleanerBookingCard";
import DisplayNotification from "../../components/DisplayNotification";
import { useGetNotificationQuery } from "../Notification/notificationApiSlice";

import "./style.css";

const Notifications = () => {
  const { notificationId } = useParams();

  return (
    <div>
      <div className=" container-fluid main">
        <div className="row section_main d-flex ">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 ">
            <div className="section_sub_main">
              <DisplayNotification seeButton={false} />
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-6 col-xl-6 ">
            <div className="section_sub_main">
              {notificationId ? (
                <BookingConfirmationCard notificationId={notificationId} />
              ) : (
                <p>Please Select a Booking!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;

const BookingConfirmationCard = ({ notificationId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [booking, setBooking] = useState(null);
  const { data, isLoading: isLoadingNotification } =
    useGetNotificationQuery(notificationId);
  const [triggerRefetchBooking, setTriggerRefetchBooking] = useState(false);
  const [getBooking] = useLazyGetBookingQuery();
  const [confirmClientPayment, { isLoading: isLoadingConfirmPayment }] =
    useConfirmClientPaymentMutation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  useEffect(() => {
    if (data?.notification) {
      setNotification(data.notification);
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setBooking(null);
      try {
        if (notification?.booking) {
          const response = await getBooking(notification.booking).unwrap();
          if (response.status === "success") {
            setBooking(response.booking);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorToast(error.message);
      }
    };
    fetchData();
  }, [notification, getBooking, triggerRefetchBooking]);
  const handlePaymentClick = async (bookingId, paymentAction) => {
    try {
      const response = await confirmClientPayment({
        bookingId,
        paymentAction,
      }).unwrap();
      if (response.status === "success") {
        setTriggerRefetchBooking((prevState) => !prevState);
        if (paymentAction === "confirm")
          successToast("Booking Successfully Paid!");
        else successToast("Booking Payment Canceled");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };
  const handleInitialClick = (action) => {
    setShowConfirmation(true);
    setSelectedAction(action);
  };
  return (
    <>
      {isLoading ? (
        <div className="row d-flex justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      ) : (
        <>
          {booking?.jobStatus === "completed" ? (
            <div className="d-flex justify-content-center mt-4 mb-4 cancel_pop ">
              <p>Booking has been Completed!</p>
            </div>
          ) : (
            <>
              {booking?.jobStatus === "onSite" && (
                <div className="d-flex justify-content-center mt-4 mb-4 cancel_pop ">
                  <p>Cleaner has been arrived at the location!</p>
                </div>
              )}
            </>
          )}

          {booking ? (
            <>
              <CleanerBookingCard booking={booking} />
              {!booking.recurringPaid && booking.paymentIntentId ? (
                <>
                  {!showConfirmation && (
                    <div className="btn_box mt-4">
                      <div className="d-flex justify-content-center">
                        <button
                          disabled={isLoadingConfirmPayment}
                          onClick={() => {
                            handleInitialClick("cancel");
                          }}
                          className="btn_ntf_cancel"
                        >
                          Cancel
                        </button>
                      </div>

                      <div className="d-flex justify-content-center">
                        <button
                          disabled={isLoadingConfirmPayment}
                          onClick={() => {
                            handleInitialClick("confirm");
                          }}
                          className="btn_ntf_confirm"
                        >
                          Pay Now
                        </button>
                      </div>
                    </div>
                  )}
                  {showConfirmation && (
                    <ConfirmationModal
                      onConfirm={() =>
                        handlePaymentClick(booking._id, selectedAction)
                      }
                      onCancel={() => setShowConfirmation(false)}
                    />
                  )}
                </>
              ) : booking.paid && booking.recurringPaid ? (
                <div className="d-flex justify-content-center">
                  <button disabled={true} className="btn_ntf_confirm">
                    Paid!
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <button disabled={true} className="btn_ntf_confirm">
                    UnPaid!
                  </button>
                </div>
              )}
            </>
          ) : (
            <p>No booking Found!</p>
          )}
        </>
      )}
    </>
  );
};

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="confirmation-modal">
      <p>Are you sure?</p>
      <button onClick={onConfirm} className="btn_ntf_confirm">
        Confirm
      </button>
      <button onClick={onCancel} className="btn_ntf_confirm">
        Cancel
      </button>
    </div>
  );
};
