import { apiSlice } from "../../../state/api/apiSlice";
import { apiRoutes } from "../../../constants/constants";

export const emailPhoneApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    removeEmailPhone: builder.mutation({
      query: (body) => ({
        url: apiRoutes.removeEmailPhone,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    updateEmailPhone: builder.mutation({
      query: (body) => ({
        url: apiRoutes.updateEmailPhone,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
  }),
});

export const {
  useRemoveEmailPhoneMutation,
  useUpdateEmailPhoneMutation,
} = emailPhoneApiSlice;
