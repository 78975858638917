import React, { useState } from "react";

import { formatDate, formatTime } from "../../constants/utils";
import displayImg from "../../assets/profile_user.png";
import locationImg from "../../assets/icons/Path-181.png";
import calendarImg from "../../assets/icons/calendar.png";
import prfCleaners from "../../assets/icons/path-562.png";

import "./style.css";

const TodayBooking = ({
  booking,
  showServiceTitle,
  showMenuButton,
  showLocation,
  showBookingTime,
  showOnSiteTag,
}) => {
  return (
    <div className="activity_heading1">
      <div className="db_cleaner_card1 ">
        <div className="row d-flex justify-content-center px-4">
          <div className="col-md-2 col-lg-2 col-sm-2 col-xs-12 d-flex justify-content-center img_box1">
            <img
              className="db_display_img1"
              src={
                booking.cleaner?.image?.url
                  ? booking.cleaner?.image?.url
                  : displayImg
              }
              alt="there is an img"
            />
          </div>

          <div className="today-booking__timestamp col-md-7 col-lg-7 col-xl-7 col-sm-7 col-xs-12 col d-flex justify-content-start align-items-end">
            <div>
              <h6 className="db_cleaner_heading1">
                <b>{booking.cleaner.name}</b>
              </h6>
              {showServiceTitle && (
                <p className="db_service1">{booking.service.title}</p>
              )}
              {showLocation && (
                <span className="location_dashboard1">
                  <img
                    className="imgaes_location_calendar"
                    src={locationImg}
                    alt="here is an img"
                  />
                  {booking.location.address_1}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;
                  <img
                    className="imgaes_location_calendar"
                    src={calendarImg}
                    alt="here is an img"
                  />{" "}
                  {formatDate(booking.startTime)}
                </span>
              )}
              {showBookingTime && (
                <div className="main_check d-flex justify-content-between">
                  {booking.arrivedAt && (
                    <>
                      <div>
                        <div className=" d-flex toogle_time  ">
                          <div>
                            <label className="checkbox_container">
                              <input
                                type="checkbox"
                                checked={true}
                                disabled={true}
                              />
                              <span className="checkmark1"></span>
                              <span className="arrived_location">
                                {" "}
                                Arrived at Location
                              </span>
                            </label>
                          </div>
                          {booking.arrivedAt && (
                            <div>
                              <span className=" show_time1">
                                {formatTime(booking.arrivedAt)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {!booking.jobCompletedAt && booking.arrivedAt && (
                    <div className="d-flex justify-content-center p-2">
                      <img
                        className="prfCleaner"
                        src={prfCleaners}
                        alt="here is an img"
                      />
                    </div>
                  )}

                  {booking.jobCompletedAt && (
                    <div className="d-flex justify-content-center time_finished_style">
                      <div className=" d-flex  toogle_time  ">
                        <div className="time_box_db">
                          <label className="checkbox_container">
                            <input type="checkbox" />
                            <span className="checkmark1"></span>
                            <span className="arrived_location">
                              {" "}
                              Job finished
                            </span>
                          </label>
                        </div>
                        {booking.jobCompletedAt && (
                          <div>
                            <span className=" show_time1">
                              {formatTime(booking.jobCompletedAt)}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center">
            <div>
              <div className=" d-flex justify-content-center">
                {showOnSiteTag && booking.jobStatus === "onSite" && (
                  <div>
                    <button className="btn_site1">On Site</button>
                  </div>
                )}
              </div>
              {showMenuButton && (
                <p className="display_time_dashboard1">
                  {formatTime(booking.startTime)}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayBooking;
