import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectUserImage } from "../../pages/Auth/authSlice";
import { getUserRole } from "../../constants/utils";
import {
  adminNotificationsRoute,
  customerNotificationRoute,
  landingPageRoute,
  notificationRoute,
} from "../../constants/constants";
import { useGetUnreadNotificationsCountQuery } from "../../pages/Notification/notificationApiSlice";
import {
  selectNotificationCount,
  setNotificationCount,
} from "../../pages/Notification/notificationSlice";
import { useSocket } from "../../webSocket/socket";

import logo from "./../../assets/2024LogoCleanAppLtd.svg";
import display from "./../../assets/profile-img.png";
import notificationIcon from "./../../assets/icons/notification.png";
import "./style.css";

const SearchBar = () => {
  const dispatch = useDispatch();
  const socket = useSocket();
  const [notification, setNotification] = useState(null);
  socket.on("bookingNotification", (notification) => {
    setNotification(notification);
  });
  const notificationCount = useSelector(selectNotificationCount);

  const { data, refetch, isLoading } = useGetUnreadNotificationsCountQuery(
    getUserRole() === "admin" ? "admin" : "customer"
  );

  useEffect(() => {
    if (data) dispatch(setNotificationCount(data.unreadNotificationsCount));
  }, [data, dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch, notification]);
  const userImage = useSelector(selectUserImage);
  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-3 col-md-2 col-lg-2 d-flex justify-content-center align-items-center">
          <Link to={landingPageRoute}>
            <img className="logo_image04 " src={logo} alt="here is a logo img" />
          </Link>
        </div>
        <div className="col-6 col-md-8 col-lg-8  d-flex justify-content-start align-items-center">
          <input
            className="nav_search_type py-3 px-3"
            placeholder="Search"
            type="text"
          />
        </div>

        <div className="col-1 col-md-1 col-lg-1 d-flex justify-content-center">
          <div className=" d-flex justify-content-center align-items-center">
            <Link
              to={
                getUserRole() === "admin"
                  ? adminNotificationsRoute
                  : getUserRole() === "cleaner"
                  ? notificationRoute
                  : customerNotificationRoute
              }
              className="notification-link"
            >
              <img
                className="notification_icon"
                src={notificationIcon}
                alt="here is a logo img"
              />
              {notificationCount && notificationCount > 0 ? (
                <p className="notification-count">{notificationCount}</p>
              ) : (
                ""
              )}
            </Link>
          </div>
        </div>

        <div className="col-2 col-md-1 col-lg-1 d-flex justify-content-start">
          <div className=" d-flex justify-content-center align-items-center">
            <img
              className="profile_image"
              src={userImage ? userImage : display}
              alt="here is a logo img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
