import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
  hideProgressBar: false,
  autoClose: 4000,
};

export const toaster = (value) =>
  toast(value, toastOptions);

export const successToast = (value) =>
  toast.success(value, toastOptions);

export const errorToast = (value) =>
  toast.error(value, toastOptions);

export const infoToast = (value) =>
  toast.info(value, toastOptions);

export const warningToast = (value) =>
  toast.warn(value, toastOptions);

export const promiseToast = (promise, value) =>
  toast.promise(promise, value, toastOptions);

export default toaster;
