import { apiSlice } from "../../state/api/apiSlice";
import { apiRoutes } from "../../constants/constants";

export const customerNotificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    confirmClientPayment: builder.mutation({
      query: (body) => ({
        url: apiRoutes.confirmClientPayment,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        booking: response.data.booking,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
  }),
});

export const { useConfirmClientPaymentMutation } = customerNotificationApiSlice;
