import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import additional from "../../../../assets/additional.png";
import locationImg from "../../../../assets/icons/Path-181.png";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCleaningProducts,
  selectLocation,
  setCleaningProducts,
} from "../createBookingSlice";
import { findCleanersRoute } from "../../../../constants/constants";

const CleaningProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const [currentCleaningProducts, setCurrentCleaningProducts] = useState(
    Boolean(useSelector(selectCleaningProducts))
  );

  const handleCleaningProductClick = async () => {
    dispatch(setCleaningProducts(currentCleaningProducts));
    navigate(findCleanersRoute);
  };
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 additional_2 ">
      <span className="location">
        <img className="Icon_location" src={locationImg} alt="here is an img" />
        &nbsp;
        {location.address_1}
      </span>
      <div
        className="your_services_main "
        style={{ backgroundColor: "white", Height: "65vh" }}
      >
        <div className="container-fluid ">
          <div className=" ">
            <div className="row">
              <div className="col-12 mb-3 ">
                <h3 className="enter_address    fs-5">
                  Should we bring cleaning products?
                </h3>
              </div>

              <div className="row select_user_box ">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center">
                  <div className="radio py-1 d-flex justify-content-between ">
                    <div>
                      <input
                        className="radio_button"
                        type="radio"
                        name="cleaningProduct"
                        id="option1"
                        checked={currentCleaningProducts}
                        onChange={() => setCurrentCleaningProducts(true)}
                      />
                      <label htmlFor="option1" className="options_yon">
                        Yes
                      </label>
                    </div>
                    <div className="px-1">
                      <p className="cleaning-products-extra">
                        Additional&nbsp;&#163;5
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center ">
                  <div className="radio py-1">
                    <input
                      className="radio_button"
                      type="radio"
                      name="cleaningProduct"
                      id="option2"
                      checked={currentCleaningProducts === false}
                      onChange={() => setCurrentCleaningProducts(false)}
                    />
                    <label htmlFor="option2" className="options_yon">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-5 d-flex justify-content-center">
                <div className="col-xs-0 col-sm-0 col-md-0 col-lg-8 col-xl-8"></div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4  login_btn_next_1 ">
                  <button
                    className="login_btn_next px-4  mb-3"
                    onClick={handleCleaningProductClick}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CleaningProduct;
