import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useDeleteAccountMutation } from "./deleteAccountApiSlice";
import { loginRoute } from "../../../constants/constants";
import { errorToast, successToast } from "../../../toastNotification/toast";
import { setLogout } from "../../Auth/authSlice";

import "./style.css";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();
  const [confirmation, setConfirmation] = useState(false);

  const onHandleDeleteClick = async () => {
    setConfirmation(true);
  };
  const onHandleConfirmationClick = async () => {
    try {
      const response = await deleteAccount({
        password,
      }).unwrap();
      dispatch(setLogout());
      navigate(loginRoute);

      successToast("account deleted Successfully");
    } catch (error) {
      errorToast(error.message);
    }
  };

  const onHandleCancelClick = async () => {
    setConfirmation(false);
  };
  return (
    <>
      {!confirmation && (
        <div className="container-fluid">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 ">
                <div>
                  <div>
                    <p className="remove_heading">
                      <b>Delete account</b>
                    </p>
                  </div>

                  <div className=" mt-3 d-flex justify-content-center">
                    <div>
                      <Form.Label>
                        <span className="confirm_deletion_setting">
                          {" "}
                          To confirm Deletion, enter password{" "}
                        </span>
                      </Form.Label>
                      <br />
                      <Form.Control
                        className="remove_email"
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className=" btn_confirm_div d-flex justify-content-center">
                    <button
                      className="btn_confirm"
                      onClick={onHandleDeleteClick}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmation && (
        <div className="container-fluid">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-5 ">
                <div>
                  <div className=" mt-5 d-flex justify-content-center">
                    <h5>
                      <b>Are you sure you want to proceed?</b>
                    </h5>
                  </div>

                  <div className=" mt-4 btn_confirm_div d-flex justify-content-between">
                    <button
                      className="px-4 btn_confirm"
                      onClick={onHandleConfirmationClick}
                    >
                      Yes
                    </button>
                    <button
                      className="px-4 btn_confirm"
                      onClick={onHandleCancelClick}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteAccount;
