import React, { useState, useEffect, useRef } from "react";
import TodayBooking from "../TodayBooking";
import { Spinner } from "react-bootstrap";
import { useLazyGetAllBookingsQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";

const AdminBookingList = () => {
  const [fetchQuery, setFetchQuery] = useState("today=true&");
  const [bookings, setBookings] = useState([]);
  const [getAllBookings, { isLoading }] = useLazyGetAllBookingsQuery();
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllBookings({
          query: `${fetchQuery}sort=-startTime`,
          body: {
            paid: true,
            bookingConfirmation: "approved",
            recurringPaid: true,
          },
        }).unwrap();
        if (response.status === "success") setBookings(response.bookings);
      } catch (error) {
        setBookings(null);
      }
    };
    fetchData();
  }, [fetchQuery, getAllBookings]);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div className="todays_booking_box mt-4">
      <div className="d-flex justify-content-between mx-4 mt-3 mb-1">
        <div className="d-flex align-items-center">
          <h3 className="t_b_heading_db">
            <b>
              {fetchQuery === "today=true&"
                ? "Today's bookings"
                : "All bookings"}
            </b>
          </h3>
        </div>
        <div>
          <button
            className="btn_activities_seeAll"
            onClick={() => {
              if (fetchQuery === "today=true&") setFetchQuery("");
              else setFetchQuery("today=true&");
            }}
          >
            {fetchQuery === "today=true&" ? "See All" : "See Today's Bookings"}
          </button>
        </div>
      </div>
      <div
        className="today_booking_main"
        ref={todayBookingsRef}
        onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
        onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {!isLoading ? (
          bookings ? (
            bookings.map((booking) => (
              <TodayBooking
                booking={booking}
                showServiceTitle={true}
                showMenuButton={true}
                showLocation={true}
                showBookingTime={true}
                showOnSiteTag={true}
                key={booking._id}
              />
            ))
          ) : (
            <p>No bookings Found!</p>
          )
        ) : (
          <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
        )}
      </div>
    </div>
  );
};

export default AdminBookingList;
