import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import AdminCleanerCard from "../AdminCleanerCard";
import { useGetAllUsersQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";

import "./style.css";

const AppointCleanerList = ({
  onHandleAppointCleaner,
  bookingConfirmation,
}) => {
  const { data, refetch, isLoading } = useGetAllUsersQuery({
    role: "cleaner",
    availability: "available",
    approval: "approved",
  });
  const [cleaners, setCleaners] = useState([]);
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (data) {
      const dataFiltered = data.filter(
        (item) => item._id !== bookingConfirmation.prevCleanerId
      );
      setCleaners(dataFiltered);
    }
  }, [data, bookingConfirmation]);
  return (
    <div className="appoint_cleaner_main mt-4">
      <div className="col-12">
        <h5 className="py-3 mx-3">
          <b>Appoint Cleaner</b>
        </h5>
        <div
          className="today_cleaners_main"
          ref={todayBookingsRef}
          onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
          onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {isLoading ? (
            <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
          ) : cleaners ? (
            cleaners.map((cleaner) => (
              <AdminCleanerCard
                isShowToggleButton={false}
                cleaner={cleaner}
                isShowAppointButton={true}
                bookingConfirmation={bookingConfirmation}
                onHandleAppointCleaner={onHandleAppointCleaner}
                key={cleaner._id}
              />
            ))
          ) : (
            <p>No Cleaners Found!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointCleanerList;
