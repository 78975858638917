import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  useGetAllNotificationsQuery,
  useUpdateNotificationMutation,
} from "../../pages/Notification/notificationApiSlice";
import { useSocket } from "../../webSocket/socket";
import {
  customerNotificationRoute,
  notificationRoute,
} from "../../constants/constants";
import { errorToast } from "../../toastNotification/toast";
import { countUnreadNotifications, getUserRole } from "../../constants/utils";
import { setNotificationCount } from "../../pages/Notification/notificationSlice";

import "./style.css";

const DisplayNotification = (props) => {
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();
  const [activeNotification, setActiveNotification] = useState(
    useParams().notificationId
  );
  const { data, refetch, isLoading } = useGetAllNotificationsQuery("user");
  const [updateNotification, { isLoading: isUpdateNotificationLoading }] =
    useUpdateNotificationMutation();
  const [notification, setNotification] = useState(null);
  socket.on("bookingNotification", (notification) => {
    setNotification(notification);
  });

  useEffect(() => {
    if (data?.notifications) {
      const unReadNotifications = countUnreadNotifications(data.notifications);
      dispatch(setNotificationCount(unReadNotifications));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [notification, refetch, dispatch]);

  const handleViewNotification = async (notification) => {
    try {
      setActiveNotification(notification._id);
      const response = await updateNotification({
        notificationId: notification._id,
        body: { isRead: true },
      });
      refetch();
      if (getUserRole() === "cleaner")
        navigate(`${notificationRoute}/${notification._id}`);
      else if (getUserRole() === "customer")
        navigate(`${customerNotificationRoute}/${notification._id}`);
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="notification_heading">
            <b className="notification_bookings_list_head">Notifications</b>
          </h2>
        </div>
        {props.seeButton && (
          <div>
            <Link className="btn_see_notification" to={notificationRoute}>
              See all
            </Link>
          </div>
        )}
      </div>

      <hr className="notification_hr " />
      <div
        className="customer_notification_hp"
        style={{ height: props.height }}
        ref={todayBookingsRef}
        onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
        onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {!isLoading &&
          data?.notifications &&
          data.notifications.map((notification, index) => (
            <div
              key={index}
              className={`${
                activeNotification === notification._id && "notification-active"
              } d-flex justify-content-between align-items-center mx-2 mt-3 services_time_location`}
              onClick={() => {
                handleViewNotification(notification);
              }}
            >
              <div>
                <p
                  className={`
                ${
                  activeNotification === notification._id &&
                  "notification-active"
                } ${
                    notification.isRead
                      ? "notification_parg_read"
                      : "notification_parg_unread"
                  }`}
                >
                  <b className="notifiation_cleaner_parg">
                    {notification.description}
                  </b>
                </p>
              </div>
              <div>
                <button className="btn_view_cleaner">View</button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DisplayNotification;
