import { Outlet, Navigate } from "react-router-dom";
import { landingPageRoute } from "../constants/constants";
import { isApproved } from "../constants/utils";
const RestrictLayout = () => {
  return (
    <>
      {isApproved() ? (
        <Outlet />
      ) : (
        <Navigate to={landingPageRoute} />
      )}
    </>
  );
};

export default RestrictLayout;
