import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const UCBookingCmpt = ({ seeAllButton, route, pageHeading }) => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="notification_heading">
            <b className="lists_heading_cleaner">{pageHeading}</b>
          </h2>
        </div>
        {seeAllButton && (
          <div className="btn_seeall_cleaner_head">
            <Link className="btn_ucbooking_see" to={route}>
              See all
            </Link>
          </div>
        )}
      </div>
      <hr className="notification_hr" />
    </div>
  );
};

export default UCBookingCmpt;
