import { apiSlice } from "../../../../state/api/apiSlice";
import { apiRoutes } from "../../../../constants/constants";

export const findCleanersApiSlice =
  apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getAllCleaners: builder.query({
        query: (body) => ({
          url: apiRoutes.getAllCleaners,
          method: "PATCH",
          body: { ...body },
        }),
        transformResponse: (response) => response.data.user,
      }),
    }),
  });

export const { useGetAllCleanersQuery } =
  findCleanersApiSlice;
