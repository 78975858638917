import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { useLazyGetTotalBookingsInPeriodQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";
import { errorToast } from "../../toastNotification/toast";

const TotalBookings = () => {
  const [getTotalBookingsInPeriodQuery, { isLoading }] =
    useLazyGetTotalBookingsInPeriodQuery();
  const [totalBookings, setTotalBookings] = useState(0);

  const [selectedValue, setSelectedValue] = useState("today");

  const handleSelectChange = async (event) => {
    try {
      setSelectedValue(event.target.value);
      const response = await getTotalBookingsInPeriodQuery(event.target.value);
      setTotalBookings(response.data);
    } catch (error) {
      errorToast(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTotalBookingsInPeriodQuery("today");
        setTotalBookings(response.data);
      } catch (error) {
        errorToast(error.message);
      }
    };
    fetchData();
  }, [getTotalBookingsInPeriodQuery]);

  return (
    <div className="total_booking_today">
      <div className="d-flex justify-content-between mx-2 pt-1">
        <div>
          <button className="see_all_dashboard">See all</button>
        </div>
        <div>
          <span>
            <span className="sort_by_heading">Sort by: &nbsp;</span>
            <select
              className="sort_by"
              value={selectedValue}
              onChange={handleSelectChange}
            >
              <option value="today">Today</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-center ">
        <p className="total_booking_heading mt-4">Total bookings today</p>
      </div>
      <div className="d-flex justify-content-center">
        {!isLoading ? (
          <h1 className="total_bookings">
            {totalBookings ? totalBookings : 0}
          </h1>
        ) : (
          <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
        )}
      </div>
    </div>
  );
};

export default TotalBookings;
