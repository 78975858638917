import { Outlet, Navigate } from "react-router-dom";
import { getUserRole } from "../constants/utils";
import { landingPageRoute } from "../constants/constants";

const CleanerLayout = () => {
  return (
    <>
      {getUserRole() !== "cleaner" ? (
        <Navigate to={landingPageRoute} />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default CleanerLayout;
