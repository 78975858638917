import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import {
  useLazyGetBookingQuery,
  useUpdateBookingConfirmationMutation,
} from "../Booking/bookingApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";
import CleanerBookingCard from "../../components/CleanerBookingCard";
import DisplayNotification from "../../components/DisplayNotification";

import "./style.css";
import { useGetNotificationQuery } from "./notificationApiSlice";

const Notifications = () => {
  const { notificationId } = useParams();

  return (
    <div>
      <div className=" container-fluid main_upcoming_booking_cleaner_box05">
        <div className="row upcoming_booking_cleaner_section_main d-flex ">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 ">
            <div className="upcoming_booking_cleaner_section_sub_main">
              <DisplayNotification height="82vh" seeButton={false} />
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-6 col-xl-6 ">
            <div className="upcoming_booking_cleaner_section_sub_main01">
              {notificationId ? (
                <BookingConfirmationCard notificationId={notificationId} />
              ) : (
                <p>Please Select a Notification!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;

const BookingConfirmationCard = ({ notificationId }) => {
  const [notification, setNotification] = useState(null);
  const [booking, setBooking] = useState(null);
  const [status, setStatus] = useState("");

  const { data, isLoading: isLoadingNotification } =
    useGetNotificationQuery(notificationId);
  const [getBooking, { isLoading }] = useLazyGetBookingQuery();
  useEffect(() => {
    if (data?.notification) {
      setNotification(data.notification);
    }
  }, [data]);
  useEffect(() => {
    const fetchData = async () => {
      setBooking(null);
      try {
        if (notification?.booking) {
          const response = await getBooking(notification.booking).unwrap();
          if (response.status === "success") {
            setBooking(response.booking);
            setStatus(response.booking.bookingConfirmation);
          }
        }
      } catch (error) {
        errorToast(error.message);
      }
    };
    fetchData();
  }, [notification, getBooking]);
  const [
    updateBookingConfirmation,
    { isLoading: isLoadingBookingConfirmation },
  ] = useUpdateBookingConfirmationMutation();

  const handleRejectClick = async (bookingId) => {
    try {
      const response = await updateBookingConfirmation({
        bookingId: bookingId,
        bookingConfirmation: "rejected",
      }).unwrap();
      if (response.status === "success") {
        successToast("Booking Rejected!");
        setStatus("rejected");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const handleConfirmClick = async (bookingId) => {
    try {
      const response = await updateBookingConfirmation({
        bookingId: bookingId,
        bookingConfirmation: "approved",
      }).unwrap();
      if (response.status === "success") {
        successToast("Booking Approved!");
        setStatus("approved");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <>
      {booking && (
        <>
          {booking?.jobStatus === "completed" &&
          booking.cleaner._id === notification.user ? (
            <div className="d-flex justify-content-center mt-4 mb-4 cancel_pop ">
              <p className="completed_booking_notification">
                Booking has been completed!
              </p>
            </div>
          ) : (
            <>
              {status === "rejected" ||
              booking.cleaner._id !== notification.user ? (
                <div className="d-flex justify-content-center mt-4 mb-4 cancel_pop ">
                  <p>You rejected this one!</p>
                </div>
              ) : (
                ""
              )}

              {status === "approved" &&
                booking.cleaner._id === notification.user && (
                  <div className="d-flex justify-content-center mt-4 mb-4 cancel_pop ">
                    <p>Added to upcoming bookings!</p>
                  </div>
                )}
            </>
          )}
        </>
      )}

      {isLoading ? (
        <div className="row d-flex justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      ) : booking ? (
        <>
          <CleanerBookingCard booking={booking} />

          {status === "pending" &&
            booking.cleaner._id === notification.user && (
              <div className="btn_box mt-4">
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => {
                      handleRejectClick(booking._id);
                    }}
                    className="btn_ntf_cancel"
                  >
                    Reject
                  </button>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => {
                      handleConfirmClick(booking._id);
                    }}
                    className="btn_ntf_confirm"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
        </>
      ) : (
        <p>No booking Found!</p>
      )}
    </>
  );
};
