import { apiSlice } from "../../state/api/apiSlice";
import { apiRoutes } from "../../constants/constants";

export const AdminDashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTotalBookingsInPeriod: builder.query({
      query: (period) => ({
        url: `${apiRoutes.getTotalBookingsInPeriod}/${period}`,
        method: "GET",
      }),
      transformResponse: (response) => response.totalBookings,
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    getAllBookings: builder.query({
      query: ({ query, body }) => {
        const queryParams = query ? `?${query}` : "";
        return {
          url: `${apiRoutes.getAllBookings}${queryParams}`,
          method: "PATCH",
          body: { ...body },
        };
      },
      transformResponse: (response) => ({
        status: response.status,
        bookings: response.data.bookings,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    getBookingsChartData: builder.query({
      query: (period) => ({
        url: `${apiRoutes.getBookingsChartData}/${period}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        status: response.status,
        labels: response.data.labels,
        chartData: response.data.chartData,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    getAllUsers: builder.query({
      query: (body) => ({
        url: apiRoutes.getAllUsers,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => response.data.users,
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    createCleanerAdmin: builder.mutation({
      query: (body) => ({
        url: apiRoutes.createCleanerAdmin,
        method: "POST",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ query, body }) => ({
        url: `${apiRoutes.updateUser}/${query}`,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    deleteUser: builder.mutation({
      query: (query) => ({
        url: `${apiRoutes.deleteUser}/${query}`,
        method: "DELETE",
      }),
      transformResponse: (response) => ({
        status: response.status,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    appointCleaner: builder.mutation({
      query: (body) => ({
        url: apiRoutes.appointCleaner,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        booking: response.data.booking,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    approveCleaner: builder.mutation({
      query: (body) => ({
        url: apiRoutes.approveCleaner,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        user: response.data.user,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
  }),
});

export const {
  useLazyGetBookingsChartDataQuery,
  useLazyGetTotalBookingsInPeriodQuery,
  useGetAllBookingsQuery,
  useLazyGetAllBookingsQuery,
  useGetAllUsersQuery,
  useCreateCleanerAdminMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useAppointCleanerMutation,
  useApproveCleanerMutation,
} = AdminDashboardApiSlice;
