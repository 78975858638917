import React from "react";
import { useNavigate } from "react-router-dom";

import displayImg from "../../assets/profile_user.png";
import "./style.css";

const AdminCleanerCard = ({
  customStyle,
  cleaner,
  isShowToggleButton,
  cleanerValues,
  setCleanerValues,
  onHandleToggleClick,
  isShowAppointButton,
  onHandleAppointCleaner,
  bookingConfirmation,
}) => {
  const navigate = useNavigate();
  const cleanerAvailability =
    cleanerValues?._id === cleaner._id
      ? cleanerValues.availability
      : cleaner.availability;

  const handleCleanerClick = () => {
    setCleanerValues(cleaner);
  };

  return (
    <div className="activity_heading1">
      <div className="db_cleaner_card1 ">
        <div
          className="row d-flex justify-content-center px-4"
          style={{
            opacity:
              cleanerAvailability === "unAvailable" && isShowToggleButton
                ? 0.4
                : 1,
          }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex justify-content-center img_box1">
            <img
              className="db_display_img1"
              src={cleaner?.image?.url ? cleaner?.image?.url : displayImg}
              alt="there is an img"
            />
          </div>

          <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 d-flex justify-content-start align-items-end adjustment">
            <div
              style={{ cursor: isShowToggleButton ? "pointer" : "default" }}
              onClick={() => {
                if (!isShowToggleButton) return;
                handleCleanerClick();
              }}
            >
              <h6 className="db_cleaner_heading1">
                <b>{cleaner.name}</b>
              </h6>

              {cleanerAvailability === "available" ||
              cleanerAvailability === "onSite" ? (
                <div className="available_cleaner">
                  <p className="db_service2">Available</p>
                </div>
              ) : cleanerAvailability === "unAvailable" ? (
                <p className="unavailable_cleaner">Unavailable</p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center">
            <div>
              <div className=" d-flex justify-content-center">
                {cleanerAvailability === "onSite" && (
                  <div>
                    <span className="btn_site1">On Site</span>
                  </div>
                )}
                {isShowToggleButton && cleanerAvailability !== "onSite" && (
                  <label>
                    <input
                      onChange={(e) => {
                        onHandleToggleClick(e, cleaner._id);
                      }}
                      type="checkbox"
                      className="toggle-checkbox"
                      checked={cleanerAvailability === "unAvailable"}
                    />
                    <div className="toggle-switch"></div>
                  </label>
                )}
                {isShowAppointButton && (
                  <div>
                    <button
                      onClick={() => {
                        onHandleAppointCleaner(cleaner._id);
                      }}
                      className="appoint_this_cleaner"
                      disabled={bookingConfirmation.status === "pending"}
                    >
                      {bookingConfirmation.cleanerId !== cleaner._id
                        ? "Appoint This Cleaner"
                        : "Appointed"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCleanerCard;
