import React, { createContext, useContext } from "react";
import io from "socket.io-client";

import { isLogin, userID } from "../constants/utils";
import toaster from "../toastNotification/toast";
import { getUserRole } from "../constants/utils";

const SocketContext = createContext();
const serverUrl = process.env.REACT_APP_SOCKET_CONNECTION_URL;
const socket = io(serverUrl);

if (isLogin()) {
  socket.on("connect", () => {
    socket.emit("userId", { id: userID() });
    if (getUserRole() === "admin")
      socket.emit("loginAsAdmin", { id: userID() });
    socket.emit("message", "Hello server, I am connected!");
  });
  socket.on("bookingNotification", (notification) => {
    toaster(notification.description);
  });
  socket.on("disconnect", () => {});
}

export const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export const useSocket = () => {
  return useContext(SocketContext);
};
