import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "./../../assets/CleanApp logo.png";
import { Link, NavLink } from "react-router-dom";
import {
  landingPageRoute,
  loginRoute,
  signUpRoute,
} from "../../constants/constants";
import "./style.css";

const NavBar = () => {
  const [login, setlogin] = useState(false);

  return (
    <div className="wrapper">
      <Navbar expand="lg" className="navbar_top">
        {login && (
          <Navbar.Brand href="#home">
            <img className="logo_image" src={logo} alt="here is a logo img" />
          </Navbar.Brand>
        )}
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className=" col-10 col-md-10 col-lg-10">
            <Nav className="me-auto">
              <NavLink className="anchers" as={Link} to="/">
                <span className="home_link">Home</span>
              </NavLink>

              <NavLink className="anchers" as={Link} to="/Services">
                <span className="home_link">Services</span>
              </NavLink>

              <NavLink className="anchers" as={Link} to="/about">
                <span className="home_link">About Us</span>
              </NavLink>

              <NavLink className="anchers" as={Link} to="/contact">
                <span className="home_link">Contact Us</span>
              </NavLink>
            </Nav>
          </div>

          <div className="col-2">
            <Nav className="d-flex justify-content-end">
              <NavLink
                className="anchers"
                as={Link}
                to={`${landingPageRoute}?form=login`}
              >
                <span className="home_link">Login</span>
              </NavLink>
              <NavLink
                className="anchers_signup_navbar"
                as={Link}
                to={`${landingPageRoute}?form=signup`}
              >
                <span className="home_link">Sign Up</span>
              </NavLink>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
