import React, { useState } from "react";

import AdminShowCleaners from "../../components/AdminShowCleaners";
import CleanerAvailabilityCard from "../../components/CleanerAvailabilityCard";
import { useUpdateUserMutation } from "../AdminDashboard/adminDashboardApiSlice";
import { errorToast } from "../../toastNotification/toast";

import "./style.css";

const AdminManageCleaners = () => {
  const [cleanerValues, setCleanerValues] = useState(null);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const handleToggleClick = async (e, cleanerId) => {
    const availability = e.target.checked ? "unAvailable" : "available";
    try {
      const response = await updateUser({
        query: cleanerId,
        body: { availability },
      }).unwrap();
      setTriggerRefetch((prevState) => !prevState);
      if (cleanerId === cleanerValues?._id)
        setCleanerValues({ ...response.user });
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <AdminShowCleaners
            isShowToggleButton={true}
            isShowAddCleanerButton={true}
            isShowSelector={true}
            triggerRefetch={triggerRefetch}
            cleanerValues={cleanerValues}
            setCleanerValues={setCleanerValues}
            onHandleToggleClick={handleToggleClick}
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 cleaner_availability_card_box  px-4">
          <CleanerAvailabilityCard
            jobStatusCleaner="available"
            isShowButton={true}
            bookingDetails="true"
            cleanerValues={cleanerValues}
            onHandleToggleClick={handleToggleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminManageCleaners;
