import { apiSlice } from "../../../state/api/apiSlice";
import { apiRoutes } from "../../../constants/constants";

export const serviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllServices: builder.query({
      query: () => ({
        url: apiRoutes.getAllServices,
        method: "GET",
      }),
      transformResponse: (response) => response.services,
    }),
    getService: builder.query({
      query: (query) => ({
        url: `${apiRoutes.getService}/${query}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        status: response.status,
        service: response.data.service,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    updateService: builder.mutation({
      query: ({ query, body }) => ({
        url: `${apiRoutes.updateService}/${query}`,
        method: "PATCH",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        service: response.data.service,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    createService: builder.mutation({
      query: (body) => ({
        url: apiRoutes.createService,
        method: "POST",
        body: { ...body },
      }),
      transformResponse: (response) => ({
        status: response.status,
        service: response.data.service,
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
    deleteService: builder.mutation({
      query: (query) => ({
        url: `${apiRoutes.deleteService}/${query}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response) => ({
        status: response.data.status,
        message: response.data.message,
      }),
    }),
  }),
});

export const {
  useGetAllServicesQuery,
  useGetServiceQuery,
  useLazyGetServiceQuery,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useCreateServiceMutation,
} = serviceApiSlice;
