import React, { useState } from "react";
import AddServices from "../../components/AddServices";
import AdminServicesList from "../../components/AdminServicesList";

import "./style.css";

const AdminManageServices = () => {
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 pt-3 px-4">
            <AddServices setTriggerRefetch={setTriggerRefetch} />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pt-3 px-4">
            <AdminServicesList triggerRefetch={triggerRefetch} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminManageServices;
