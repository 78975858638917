import { Navigate, Outlet } from "react-router-dom";
import { loginRoute, pendingAccountRoute } from "../constants/constants";
import { isLogin, isApproved } from "../constants/utils";

const LoginRequired = () => {
  return (
    <>
      {isApproved() ? (
        isLogin() ? (
          <Outlet />
        ) : (
          <Navigate to={loginRoute} />
        )
      ) : (
        <Navigate to={pendingAccountRoute} />
      )}
    </>
  );
};

export default LoginRequired;
