import React, { useRef, useEffect, useState } from "react";
import { FieldArray, Formik } from "formik";
import { Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import servicesSchema from "../../validation/serviceSchema";
import { handleImageUpload } from "../../constants/utils";
import {
  useCreateServiceMutation,
  useLazyGetServiceQuery,
  useUpdateServiceMutation,
} from "../../pages/Services/OurServices/servicesApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";
import {
  adminManageServicesRoute,
  presetTaskOptions,
} from "../../constants/constants";

import "./style.css";

const AddServices = ({ setTriggerRefetch }) => {
  const { serviceId } = useParams();
  const [initialTasks, setInitialTasks] = useState(null);
  const [getService, { isLoading: isLoadinGetService }] =
    useLazyGetServiceQuery();
  const [createService, { isLoading }] = useCreateServiceMutation();
  const [updateService, { isLoadingUpdateService }] =
    useUpdateServiceMutation();

  const [initialValues, setInitialValues] = useState({
    title: "",
    hours: "",
    price: "",
    presetTasks: [],
    tasks: [],
  });
  const imageRef = useRef([]);

  const handleSubmit = async (values, actions) => {
    try {
      if (values.presetTasks.length === 0 && values.tasks.length === 0) {
        errorToast("Please Select a Task!");
        return;
      }
      if (serviceId) {
        const updatedPresetTasks = values.presetTasks.filter(
          (presetTask) =>
            !initialTasks.some((task) => task.description === presetTask)
        );
        const updatedTasks = values.tasks.concat(
          initialTasks.filter((task) =>
            values.presetTasks.includes(task.description)
          )
        );
        const response = await updateService({
          query: serviceId,
          body: {
            ...values,
            tasks: updatedTasks,
            presetTasks: updatedPresetTasks,
          },
        }).unwrap();
        if (response.status === "success") {
          setTriggerRefetch((prevState) => !prevState);
          successToast("Service Updated");
        }
      } else {
        const response = await createService(values).unwrap();
        if (response.status === "success") {
          actions.resetForm();
          setTriggerRefetch((prevState) => !prevState);
          successToast("Service Created");
        }
      }
    } catch (error) {
      errorToast(error.message);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (serviceId) {
        try {
          const response = await getService(serviceId);
          if (response.data?.status === "success") {
            const serviceData = response.data.service;
            const presetTaskData = serviceData.tasks
              .filter((task) => presetTaskOptions.includes(task.description))
              .map((task) => task);
            setInitialTasks(presetTaskData);
            const presetTasks = serviceData.tasks
              .filter((task) => presetTaskOptions.includes(task.description))
              .map((task) => task.description);
            const customTasks = serviceData.tasks.filter(
              (task) => !presetTaskOptions.includes(task.description)
            );
            setInitialValues({
              title: serviceData.title,
              hours: serviceData.hours,
              price: serviceData.price,
              presetTasks,
              tasks: customTasks,
            });
          }
        } catch (error) {
          errorToast(error.message);
        }
      } else {
        setInitialValues({
          title: "",
          hours: "",
          price: "",
          presetTasks: [],
          tasks: [],
        });
      }
    };
    fetchData();
  }, [serviceId, getService]);
  return (
    <div>
      <div className="row d-flex addCleaner_main ">
        <div className="col-12">
          <div className="d-flex justify-content-end">
            {serviceId && (
              <Link className="add_new" to={adminManageServicesRoute}>
                {" "}
                +Add New
              </Link>
            )}
          </div>
          <div className="d-flex justify-content-center my-2">
            <h2 className="add_services_heading">
              {serviceId ? "Update Service" : "Add Services"}
            </h2>
          </div>
          <div className=" d-flex form_box ">
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={servicesSchema}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row d-flex ">
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                      <Form.Group className="mt-2">
                        <label>Title</label>
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Enter Title"
                          maxLength="100"
                          value={values.title}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.title && !!errors.title}
                          disabled={isLoading}
                        />
                        {touched.title && errors.title && (
                          <Form.Control.Feedback type="invalid">
                            {errors.title}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <div className="row d-flex mt-4">
                        <div className="col-6">
                          <Form.Group>
                            <label>Min. Hours</label>
                            <Form.Control
                              type="number"
                              name="hours"
                              placeholder="Enter Hours"
                              value={values.hours}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={touched.hours && !!errors.hours}
                              disabled={isLoading}
                            />
                            {touched.hours && errors.hours && (
                              <Form.Control.Feedback type="invalid">
                                {errors.hours}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <Form.Group>
                            <label>Price</label>
                            <Form.Control
                              type="number"
                              name="price"
                              placeholder="Enter Price"
                              value={values.price}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={touched.price && !!errors.price}
                              disabled={isLoading}
                            />
                            {touched.price && errors.price && (
                              <Form.Control.Feedback type="invalid">
                                {errors.price}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row d-flex  mt-4">
                        <label>Default tasks</label>
                        <div className="col-6 pt-3 ">
                          <div className="main_check d-flex justify-content-between ">
                            <div>
                              <div className=" d-flex justify-content-center toogle_time  ">
                                <div>
                                  <label className="checkbox_container1">
                                    <Form.Control
                                      type="checkbox"
                                      name="presetTasks"
                                      value="Kitchen"
                                      checked={values.presetTasks.includes(
                                        "Kitchen"
                                      )}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      disabled={isLoading}
                                    />

                                    <span className="checkmark2"></span>
                                    <span className="arrived_location01">
                                      Kitchen
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="main_check d-flex justify-content-between ">
                            <div>
                              <div className=" d-flex justify-content-center toogle_time  ">
                                <div>
                                  <label className="checkbox_container1">
                                    <Form.Control
                                      type="checkbox"
                                      value="General"
                                      name="presetTasks"
                                      checked={values.presetTasks.includes(
                                        "General"
                                      )}
                                      onChange={handleChange}
                                    />
                                    <span className="checkmark2"></span>
                                    <span className="arrived_location01">
                                      General
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 pt-3">
                          <div className="main_check d-flex justify-content-between ">
                            <div>
                              <div className=" d-flex justify-content-center toogle_time  ">
                                <div>
                                  <label className="checkbox_container1">
                                    <Form.Control
                                      type="checkbox"
                                      name="presetTasks"
                                      value="Linen"
                                      checked={values.presetTasks.includes(
                                        "Linen"
                                      )}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      disabled={isLoading}
                                    />
                                    <span className="checkmark2"></span>
                                    <span className="arrived_location01">
                                      Linen
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="main_check d-flex justify-content-between ">
                            <div>
                              <div className=" d-flex justify-content-center toogle_time  ">
                                <div>
                                  <label className="checkbox_container1">
                                    <Form.Control
                                      type="checkbox"
                                      name="presetTasks"
                                      value="Bathroom"
                                      onBlur={handleBlur}
                                      checked={values.presetTasks.includes(
                                        "Bathroom"
                                      )}
                                      onChange={handleChange}
                                      disabled={isLoading}
                                    />
                                    <span className="checkmark2"></span>
                                    <span className="arrived_location01">
                                      Bathroom
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                      <FieldArray name="tasks">
                        {({ remove, push }) => (
                          <>
                            {values.tasks.map((task, index) => (
                              <div key={index}>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <Form.Group className="mt-2">
                                      <label>Custom Tasks</label>
                                      <Form.Control
                                        type="text"
                                        className="custom_tasks"
                                        name={`tasks[${index}].description`}
                                        value={values.tasks[index].description}
                                        placeholder="Enter Task Name"
                                        maxLength="30"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={
                                          touched.tasks &&
                                          touched.tasks[index] &&
                                          errors.tasks &&
                                          errors.tasks[index]?.description
                                        }
                                      />
                                      {touched.tasks &&
                                        touched.tasks[index] &&
                                        errors.tasks &&
                                        errors.tasks[index]?.description && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.tasks &&
                                              errors.tasks[index]?.description}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                      controlId="formFile"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="file"
                                        name={`tasks[${index}].image`}
                                        onBlur={handleBlur}
                                        ref={(ref) =>
                                          (imageRef.current[index] = ref)
                                        }
                                        onChange={(e) =>
                                          handleImageUpload(
                                            e,
                                            setFieldValue,
                                            `tasks[${index}].image`
                                          )
                                        }
                                        hidden={true}
                                        disabled={isLoading}
                                        isInvalid={
                                          touched.tasks &&
                                          touched.tasks[index] &&
                                          errors.tasks &&
                                          errors.tasks[index]?.image
                                        }
                                      />
                                      {touched.tasks &&
                                        touched.tasks[index] &&
                                        errors.tasks &&
                                        errors.tasks[index]?.image && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.tasks &&
                                              errors.tasks[index]?.image}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                  </div>
                                  <div className="d-flex  align-items-end">
                                    {values.tasks[index]?.image && (
                                      <img
                                        className="img_task"
                                        src={
                                          values.tasks[index].image?.url
                                            ? values.tasks[index].image.url
                                            : values.tasks[index].image
                                        }
                                        alt="here is an img"
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="d-flex justify-content-center pt-3 gap-2">
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="btn_add_img"
                                  >
                                    Remove Task
                                  </button>
                                  <button
                                    type="button"
                                    className="btn_add_img"
                                    onClick={() => {
                                      imageRef.current[index].click();
                                    }}
                                  >
                                    Add Task Image
                                  </button>
                                </div>
                              </div>
                            ))}
                            <div className="d-flex justify-content-center pt-3">
                              {values.tasks.length < 4 && (
                                <button
                                  type="button"
                                  disabled={isLoading}
                                  onClick={() =>
                                    push({ description: "", image: "" })
                                  }
                                  className="btn_add_tasks"
                                >
                                  {values.tasks.length > 0
                                    ? "Add More!"
                                    : "Add Custom Tasks"}
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </FieldArray>
                    </div> */}
                  </div>

                  <div className="add-services__add-button d-flex justify-content-center ">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn_add_services"
                    >
                      {serviceId ? "Update Service" : "Add Service"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddServices;
