import React from "react";

import { formatDate, formatTime } from "../../constants/utils";

import displayImg from "../../assets/profile_user.png";
import menu from "../../assets/icons/menu.png";
import "./style.css";

const Activity = ({ booking, service, onSite, menuButton, location }) => {
  return (
    <div>
      <div className="db_cleaner_card">
        <div className="row d-flex justify-content-center px-4">
          <div className="col-2 d-flex justify-content-center img_box">
            <img
              className="db_display_img"
              src={
                booking.cleaner?.image?.url
                  ? booking.cleaner?.image?.url
                  : displayImg
              }
              alt="there is an img"
            />
          </div>

          <div className="col-7 d-flex justify-content-start align-items-end">
            <div>
              <h6 className="db_cleaner_heading ">
                <b>{booking.cleaner.name}</b>
              </h6>
              {service && <p className="db_service">{booking.service.title}</p>}
              {location && (
                <div className="row d-flex">
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <span className="location_dashboard">
                      {booking.location.address_1}
                    </span>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <span className="location_dashboard">
                      {formatDate(booking.startTime)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {onSite && booking.jobStatus === "onSite" && (
            <div className="col-3 d-flex justify-content-center">
              <div>
                <button className="btn_site">onSite</button>
              </div>
            </div>
          )}

          {menuButton && (
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3  ">
              <div className="d-flex justify-content-center">
                <button className="menu_btn_dashboard">
                  <img className="img_menu" src={menu} alt="" />
                </button>
              </div>
              <div className="col-12 d-flex justify-content-center ">
                <p className="display_time_dashboard">
                  <b>{formatTime(booking.startTime)}</b>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Activity;
