import React from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { useContactUsMutation } from "./contactUsApiSlice";
import { errorToast, successToast } from "../../toastNotification/toast";
import contactUsSchema from "../../validation/contactUsSchema";

import fb from "../../assets/icons/24.png";
import logo from "../../assets/2024LogoCleanAppLtd.svg";
import insta from "../../assets/icons/Path-37.png";
import tweeter from "../../assets/icons/logo.png";
import linkd from "../../assets/icons/Path-39.png";
import "./style.css";

const ContactUs = () => {
  const initialValues = {
    email: "",
    message: "",
  };
  const [contactUs, { isLoading }] = useContactUsMutation();

  const handleSubmit = async (values, actions) => {
    try {
      const response = await contactUs(values).unwrap();
      if (response.status === "success") {
        actions.resetForm();
        successToast("Message Sent!");
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="services_main d-flex flex-column min-vh-100">
      <div></div>
      <div className="container-fluid about_us flex-grow-1">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center">
              <div>
                <div className="d-flex justify-content-center">
                  <img
                    className="app_logo_img "
                    src={logo}
                    alt="here is am img"
                  />
                </div>
                <div className="social_media_links_boxes">
                  <a href="https://www.facebook.com/cleanapp/"
                  target="_blank"
                rel="noopener noreferrer">
                    <img className="social_media_logo_01" src={fb} alt="img" />
                  </a>
                  <a href="https://www.instagram.com/cleanapp.ltd/" 
                  target="_blank"
                rel="noopener noreferrer">
                    <img
                      className="social_media_logo_01"
                      src={insta}
                      alt="img"
                    />
                  </a>
                  <a href="https://twitter.com/cleanappltd"
                  target="_blank"
                rel="noopener noreferrer">
                    <img
                      className="social_media_logo_01"
                      src={tweeter}
                      alt="img"
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/cleanapp-ltd"
                  target="_blank"
                rel="noopener noreferrer">
                    <img
                      className="social_media_logo_01"
                      src={linkd}
                      alt="img"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 contact_us_box">
              <div className="d-flex justify-content-center">
                <div className="form_heading_box d-flex justify-content-center ">
                  <h1 className="form_heading">
                    <b>Contact Us</b>
                  </h1>
                </div>
              </div>
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={contactUsSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-center">
                      <div className="form_box_contact">
                        <div className="d-flex justify-content-center  pt-5">
                          <Form.Group controlId="formBasicEmail" className="email_input_box_contact">
                            <Form.Control
                              className="email_input"
                              type="email"
                              name="email"
                              value={values.email}
                              placeholder="email"
                              onBlur={handleBlur}
                              maxLength="50"
                              onChange={handleChange}
                              isInvalid={touched.email && !!errors.email}
                              disabled={isLoading}
                            />
                            {touched.email && errors.email && (
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>

                        <div className="d-flex justify-content-center pt-4">
                          <Form.Group controlId="formBasicMessage" className="email_input_box_contact">
                            <Form.Control
                              as="textarea"
                              className="message_input"
                              placeholder="Send us message..."
                              name="message"
                              value={values.message}
                              rows={10}
                              maxLength="1500"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={touched.message && !!errors.message}
                              disabled={isLoading}
                            />
                            {touched.message && errors.message && (
                              <Form.Control.Feedback type="invalid">
                                {errors.message}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>

                        <div className="d-flex justify-content-center  my-4 ">
                          <button
                            className="submit_input"
                            type="submit"
                            disabled={isLoading}
                          >
                            <b>Send</b>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-auto"></div>
    </div>
  );
};

export default ContactUs;
