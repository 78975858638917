import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import {
  useGetBookingQuery,
  useGetUserBookingQuery,
} from "../Booking/bookingApiSlice";
import { pastBookingsRoute } from "../../constants/constants";
import { formatTime } from "../../constants/utils";
import CleanerBookingCard from "../../components/CleanerBookingCard";
import UCBookingCmpt from "../../components/UCBookingCmpt";
import UpcomingBookingCard from "../../components/UpcomingBookingCard";

import "./style.css";

const PastBookings = () => {
  
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const { bookingId } = useParams();
  const { data: pastBookings, isPastBookingsLoading } = useGetUserBookingQuery({
    role: "cleaner",
    jobStatus: "completed",
    bookingConfirmation: "approved",
  });

  return (
    <div>
      <div className=" container-fluid main_upcoming_booking_cleaner_box">
        <div className="row upcoming_booking_cleaner_section_main d-flex ">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 padding_style ">
            <div className="upcoming_booking_cleaner_section_sub_main">
              <UCBookingCmpt
                seeAllButton={false}
                route={pastBookingsRoute}
                pageHeading={"Past Bookings"}
              />

              {!isPastBookingsLoading &&
              pastBookings &&
              pastBookings.length > 0 ? (
                <div
                className="upcoming_booking_list_cleaner_main"
                ref={todayBookingsRef}
                onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
                onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                >
                  {pastBookings.map((booking) => (
                    <UpcomingBookingCard
                      booking={booking}
                      key={booking._id}
                      selectedBookingId={bookingId}
                      navigateRoute={pastBookingsRoute}
                    />
                  ))}
                </div>
              ) : (
                <div>No bookings found</div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-6 col-xl-6 padding_style ">
            <div className="upcoming_booking_cleaner_section_sub_main01">
              {bookingId ? (
                <PastBookingCard bookingId={bookingId} />
              ) : (
                <p>Please Select a Booking!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastBookings;

const PastBookingCard = ({ bookingId }) => {
  const {
    data: selectedBooking,
    refetch,
    isLoading: isSelectedBookingLoading,
  } = useGetBookingQuery(bookingId);
  useEffect(() => {
    refetch();
  }, [bookingId, refetch]);
  return (
    <>
      {" "}
      {!isSelectedBookingLoading && selectedBooking?.status === "success" ? (
        <>
          <CleanerBookingCard booking={selectedBooking.booking} />
          <div className=" d-flex toogle_time mx-4 pt-4">
            <div>
              <label className="checkbox-container01">
                <input type="checkbox" checked={true} disabled={true} />
                <span className="checkmark01"></span>
                Arrived on Location
              </label>
            </div>

            <div>
              <span className=" show_time01">
                {formatTime(selectedBooking.booking.arrivedAt)}
              </span>
            </div>
          </div>

          <div className=" d-flex toogle_time  mx-4">
            <div>
              <label className="checkbox-container01">
                <input type="checkbox" checked={true} disabled={true} />
                <span className="checkmark01"></span>
                Job finished
              </label>
            </div>

            <div>
              <span className=" show_time01">
                {formatTime(selectedBooking.booking.jobCompletedAt)}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
      )}
    </>
  );
};
