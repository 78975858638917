import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import AddedSuccessfully from "../../components/AddedSuccessfully";
import cleanerSchema from "../../validation/cleanerSchema";
import { handleImageUpload } from "../../constants/utils";
import { errorToast, successToast } from "../../toastNotification/toast";
import { useCreateCleanerAdminMutation } from "../AdminDashboard/adminDashboardApiSlice";
import AdminShowCleaners from "../../components/AdminShowCleaners";
import { adminManageCleanersRoute } from "../../constants/constants";

import cleanerAdmin from "../../assets/profile_user.png";
import number from "../../assets/icons/path-23.png";
import email from "../../assets/icons/mail.png";
import "./style.css";

const AddCleaners = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  };
  const [createCleaner, { isLoading }] = useCreateCleanerAdminMutation();
  const profileImageRef = useRef();
  const [cleaner, setCleaner] = useState(null);
  const [addCleanerAdminDashboard, setAddCleanerAdminDashboard] =
    useState(true);

  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleSubmit = async (values, actions) => {
    try {
      const response = await createCleaner(values).unwrap();
      if (response.status === "success") {
        successToast("Cleaner Created Successfully");
        setAddCleanerAdminDashboard(false);
        actions.resetForm();
        setCleaner(response.user);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
          {addCleanerAdminDashboard && (
            <div className="add_cleaner_main mx-4 my-4">
              <h5 className="mx-4 pt-4 add_cleaner_link">
                <b>Add Cleaner</b>
              </h5>
              <div className="detail_box_admin mx-4 pt-4">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={cleanerSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="d-flex justify-content-center">
                        <img
                          className="cleaner_img_admin"
                          src={values.image ? values.image : cleanerAdmin}
                          alt="here is an img"
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Control
                            type="file"
                            name="profileImage"
                            ref={profileImageRef}
                            onChange={(e) =>
                              handleImageUpload(e, setFieldValue, "image")
                            }
                            hidden={true}
                            disabled={isLoading}
                          />
                        </Form.Group>
                        <p
                          className="img_title"
                          onClick={() => {
                            profileImageRef.current.click();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Add Profile Photo
                        </p>
                      </div>
                      <div className=" d-flex justify-content-between add_cleaners_name mx-4 pt-5">
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="firstName"
                            className="name"
                            placeholder="First name"
                            value={values.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={isLoading}
                            isInvalid={touched.firstName && !!errors.firstName}
                          />
                          {touched.firstName && errors.firstName && (
                            <Form.Control.Feedback type="invalid">
                              {errors.firstName}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            className="name"
                            name="lastName"
                            placeholder="Last Name"
                            value={values.lastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={isLoading}
                            isInvalid={touched.lastName && !!errors.lastName}
                          />
                          {touched.lastName && errors.lastName && (
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>

                      <div className="input-container mx-4 mt-4">
                        <div className="icon">
                          <img
                            className="prf_img_1"
                            src={number}
                            alt="here is an img"
                          />
                        </div>
                        <Form.Control
                          type="text"
                          name="phone"
                          className="input-field1 nmbr_field"
                          placeholder="Phone number"
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={isLoading}
                          isInvalid={touched.phone && !!errors.phone}
                        />
                        {touched.phone && errors.phone && (
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        )}
                      </div>

                      <div className="input-container mx-4 mt-3">
                        <div className="icon">
                          <img
                            className="prf_img_1"
                            src={email}
                            alt="here is an img"
                          />
                        </div>
                        <Form.Control
                          type="text"
                          name="email"
                          className="input-field1 "
                          placeholder="Email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={isLoading}
                          isInvalid={touched.email && !!errors.email}
                        />
                        {touched.email && errors.email && (
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        )}
                      </div>

                      <div className="input-container mx-4 mt-3">
                        <Form.Control
                          type="password"
                          name="password"
                          className="input-field2 "
                          placeholder="Password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={isLoading}
                          isInvalid={touched.password && !!errors.password}
                        />
                        {touched.password && errors.password && (
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        )}
                      </div>

                      <div className="d-flex justify-content-center btn_add_admin">
                        <button
                          type="submit"
                          className="add_admin_cleaner"
                          disabled={isLoading}
                        >
                          Add
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}

          {!addCleanerAdminDashboard && (
            <div className="add_cleaner_main2 mx-4 my-4">
              <h3 className="mx-4 pt-4">
                <Link
                  className="add_cleaner_link"
                  to={adminManageCleanersRoute}
                >
                  Add Cleaners
                </Link>
              </h3>
              <div className="detail_box_admin mx-4 pt-4">
                {cleaner && <AddedSuccessfully cleaner={cleaner} />}
              </div>
            </div>
          )}
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center">
          <AdminShowCleaners
            isShowAddCleanerButton={false}
            isShowToggleButton={false}
            isShowSelector={false}
            triggerRefetch={cleaner?._id}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCleaners;
