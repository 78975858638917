import React from "react";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";

import {
  resetPasswordRoute,
  removeEmailRoute,
  removePhoneRoute,
  deleteAccountRoute,
} from "./../../constants/constants";

import "./style.css";

const DefaultSetting = () => {
  return (
    <div className="container-fluid main_box_setting my-4">
      <div className="sub_main_setting">
        <h2 className="heading_settings_main">
          <b>Settings</b>
        </h2>
        <div className="d-flex justify-content-center">
          <div className="row main_content_setting  ">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 links_boxes ">
              <div className="f_half">
                <div className="setting_links_main">
                  <div className="nothing">
                    <NavLink
                      className="setting_links"
                      to={resetPasswordRoute}
                      activeclassname="activeLink"
                    >
                      Password
                    </NavLink>
                  </div>
                  <div className="nothing">
                    <NavLink
                      className="setting_links"
                      to={removeEmailRoute}
                      activeclassname="activeLink"
                    >
                      <span>Remove Email</span>
                    </NavLink>
                  </div>
                  <div className="nothing">
                    <NavLink
                      className="setting_links"
                      to={removePhoneRoute}
                      activeclassname="activeLink"
                    >
                      Remove Phone number
                    </NavLink>
                  </div>
                  <div className="nothing">
                    <NavLink
                      className="setting_links"
                      to={deleteAccountRoute}
                      activeclassname="activeLink"
                    >
                      Delete Account
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultSetting;
