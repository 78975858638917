import { jwtDecode } from "jwt-decode";
import {
  auth,
  authFields,
  createBookingFields,
  servicesConst,
} from "./constants";
import { errorToast } from "../toastNotification/toast";
import { setIsValidOtp } from "../pages/Auth/authSlice";

export const TokenDecoder = (Token) => {
  const decodedToken = jwtDecode(Token);
  return decodedToken.exp;
};

export const isLogin = () => {
  return localStorage.getItem(auth.accessToken);
};

export const isApproved = () => {
  return !(
    localStorage.getItem(auth.profileStatus) === "pending" &&
    localStorage.getItem(auth.userRole) === "cleaner"
  );
};
export const userID = () => {
  const decodedToken = jwtDecode(isLogin());
  return decodedToken.id;
};
export const getUserRole = () => {
  return localStorage.getItem(auth.userRole);
};

export const isSelectedService = () => {
  return localStorage.getItem(servicesConst.selectedService);
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate.replace(/(\d),\s/g, "$1, ");
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amPm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedTime = `${hours}:${minutes} ${amPm}`;
  return formattedTime;
};

export const transformFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    } else {
      resolve("");
    }
  });
};

export const handleImageUpload = (event, setFieldValue, fieldName) => {
  const file = event.currentTarget.files[0];
  if (file instanceof File) {
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (validImageTypes.includes(file.type)) {
      transformFile(file)
        .then((imageUrl) => {
          setFieldValue(fieldName, imageUrl);
        })
        .catch((error) => {
          errorToast(error.message);
        });
    } else {
      errorToast("The selected file is not a valid image type.");
    }
  }
};

export const isContinueBooking = () => {
  const selectedAddress = localStorage.getItem(createBookingFields.address_1);
  const cleaningProducts = localStorage.getItem(
    createBookingFields.cleaningProducts
  );
  const totalCleaners = localStorage.getItem(createBookingFields.totalCleaners);
  const selectedService =
    JSON.parse(localStorage.getItem(servicesConst.selectedService)) || null;
  if (
    selectedAddress &&
    cleaningProducts === true &&
    cleaningProducts === false &&
    totalCleaners &&
    selectedService
  )
    return true;
  else return false;
};

export const countUnreadNotifications = (notifications) => {
  const unreadNotificationsCount = notifications.filter(
    (notification) => !notification.isRead
  ).length;
  return unreadNotificationsCount;
};

export const isSignUpContact = () => {
  return localStorage.getItem(authFields.signUpContact);
};
export const isValidOtp = () => {
  return localStorage.getItem(authFields.isValidOtp) === "valid";
};
