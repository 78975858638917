import React from "react";

import AdminBookingList from "../../components/AdminBookingsList/index.jsx";
import AdminUpcomingBookingList from "../../components/AdminUpcommingBookingList/index.jsx";

import "./style.css";

const AdminActivities = () => {
  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 d-flex justify-content-center">
          <AdminBookingList />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center">
          <AdminUpcomingBookingList />
        </div>
      </div>
    </div>
  );
};

export default AdminActivities;
