import * as yup from "yup";

const addressSchema = yup.object().shape({
  postalCode: yup
    .string()
    .required("Postal code is required")
    .matches(
      /^[0-9A-Za-z\s]*$/,
      "Postal code must only contain numbers, letters, and spaces"
    ),

  address_1: yup.string().required("Address is required"),

  address_2: yup.string().required("Area Address is required"),
});

export default addressSchema;
