import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

import "./style.css";

const ErrorLayout = () => {
  return (
    <div className="">
      <NavBar />
      <div className="d-flex flex-column justify-content-center align-items-center error_box_main ">
        <h1 className="oops">Oops!</h1>
        <p></p>
        <p className="error_content main_content_error">
          It looks like we hit a snag. Our team has been alerted, and they're on
          it. Meanwhile, you can try the following:
        </p>
        <ul className="pt-3">
          <li className="error_content">Check your internet connection.</li>
          <li className="error_content">
            Refresh the page and give it another shot.
          </li>
          <li className="error_content">Come back in a moment.</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorLayout;
