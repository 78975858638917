import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiRoutes } from "../../constants/constants";

const baseQuery = fetchBaseQuery({});
export const thirdPartyApiSlice = createApi({
  reducerPath: "thirdPartyApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getLatLng: builder.query({
      query: (address) => {
        const queryAddress = address.replace(" ", "%20");
        return {
          url: `${apiRoutes.geoCodingApi}?address=${queryAddress}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
          method: "GET",
        };
      },
    }),
    getAddressFromLatLng: builder.query({
      query: ({ lat, lng }) => ({
        url: `${apiRoutes.geoCodingApi}?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetLatLngQuery, useLazyGetAddressFromLatLngQuery } =
  thirdPartyApiSlice;
