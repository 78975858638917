import { Outlet, Navigate } from "react-router-dom";
import { getUserRole } from "../constants/utils";
import { landingPageRoute } from "../constants/constants";

const CustomerLayout = () => {
  return (
    <div className="d-flex flex-column min-vh-100 position-relative">
      {getUserRole() !== "customer" ? (
        <Navigate to={landingPageRoute} />
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default CustomerLayout;
