import React, { useState, useRef } from "react";
import TodayBooking from "../TodayBooking";
import { useGetAllBookingsQuery } from "../../pages/AdminDashboard/adminDashboardApiSlice";
import { Spinner } from "react-bootstrap";
import { current } from "@reduxjs/toolkit";

const AdminUpcomingBookingList = () => {
  const { data, isLoading } = useGetAllBookingsQuery({
    body: {
      paid: true,
      bookingConfirmation: "approved",
      recurringPaid: true,
      upcomingBookings: "startTime",
    },
  });
  const todayBookingsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e, ref) => {
    setIsDragging(true);
    setStartY(e.pageY - ref.current.offsetTop);
    setScrollTop(ref.current.scrollTop);
  };

  const handleMouseMove = (e, ref) => {
    if (!isDragging) return;
    const y = e.pageY - ref.current.offsetTop;
    const walk = (y - startY) * 2;
    ref.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div className="todays_booking_box1 my-4 ">
      <div className="d-flex justify-content-between mx-4 mt-3 mb-1">
        <div className="d-flex align-items-center">
          <h3 className="t_b_heading_db1 ">
            <b>Upcoming bookings</b>
          </h3>
        </div>
      </div>
      <div
        className="today_booking_main"
        ref={todayBookingsRef}
        onMouseDown={(e) => handleMouseDown(e, todayBookingsRef)}
        onMouseMove={(e) => handleMouseMove(e, todayBookingsRef)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {!isLoading ? (
          data?.bookings ? (
            data.bookings.map((booking) => (
              <TodayBooking
                booking={booking}
                showServiceTitle={true}
                showMenuButton={false}
                showLocation={true}
                showBookingTime={false}
                showOnSiteTag={false}
                key={booking._id}
              />
            ))
          ) : (
            <p>No bookings Found!</p>
          )
        ) : (
          <div className="row d-flex justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <Spinner />
                  </div>
                </div>
        )}
      </div>
    </div>
  );
};

export default AdminUpcomingBookingList;
