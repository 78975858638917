import React, { useState } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { confirmPasswordSchema } from "../../../validation/SignUpSchema";
import {
  useCheckResetPasswordTokenQuery,
  useResetPasswordMutation,
} from "../../../pages/Auth/authApiSlice";
import { successToast, errorToast } from "../../../toastNotification/toast";
import { setLogin } from "../../Auth/authSlice";

import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordRoute } from "../../../constants/constants";

const NewPassword = () => {
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const { data, isLoading } = useCheckResetPasswordTokenQuery(resetToken);
  const [resetPassword, { isLoading: isLoadingResetPassword }] =
    useResetPasswordMutation();
  const handleSubmit = async (values) => {
    try {
      const response = await resetPassword({
        token: resetToken,
        password: values.password,
      }).unwrap();
      if (response.status === "success") {
        successToast("Password Reset Successfully!");
        dispatch(setLogin(response));
        navigate(resetPasswordRoute);
      }
    } catch (error) {
      errorToast(error.message);
    }
  };
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-5 ">
            <div>
              <div>
                <p className="remove_heading">
                  <b>New Password</b>
                </p>
              </div>
              {!isLoading && data?.status !== "success" ? (
                <p>Invalid Token or expired Token!</p>
              ) : (
                <>
                  {data?.status === "success" && (
                    <Formik
                      onSubmit={handleSubmit}
                      initialValues={initialValues}
                      validationSchema={confirmPasswordSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="mt-3 d-flex justify-content-center">
                            <div>
                              <Form.Group controlId="formBasicCurrentPassword">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  className="remove_email"
                                  placeholder="Password"
                                  value={values.password}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.password && !!errors.password
                                  }
                                  disabled={isLoadingResetPassword}
                                />
                                {touched.password && errors.password && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </div>
                          </div>

                          <div className="mt-3 d-flex justify-content-center">
                            <div>
                              <Form.Group controlId="formBasicContact">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="confirmPassword"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.contact}
                                  className="remove_email"
                                  placeholder="Confirm Password"
                                  isInvalid={
                                    touched.confirmPassword &&
                                    !!errors.confirmPassword
                                  }
                                  disabled={isLoadingResetPassword}
                                />
                                {touched.confirmPassword &&
                                  errors.confirmPassword && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                  )}
                              </Form.Group>
                            </div>
                          </div>

                          <div className="btn_confirm_div d-flex justify-content-center">
                            <button
                              className="btn_confirm"
                              type="submit"
                              disabled={isLoadingResetPassword}
                            >
                              Save
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
